import { observable, action, computed, toJS, runInAction, override, makeObservable } from 'mobx'
import { get, intersection } from 'utils/lodash.utils'

import { isEmpty, isValidVatNo } from 'utils/validatorsShared.utils'
import { CURRENT_TIMEZONE } from '@elo-kit/constants/dateTime.constants'
import {
  handleP24Transaction,
  handleIdealTransaction,
  handleKlarnaTransaction,
  handleSofortTransaction,
  handleStripeCardTransaction,
  handleTransactionResponse,
  convertPriceForStripe,
  handleSepaError,
} from '@elo-kit/utils/stripe.utils'
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator'

import { handleStripeDigitalTransaction } from 'utils/stripe.utils'

import { getCookies, userSessionCookieKey } from 'libs/common/cookies'

import { embedRedirectionMessage, listenEmbedMessage, listenPageHeightChanged } from 'utils/embedWidget.utils'
import { snakeCaseToCamelCase } from 'utils/nameStyle.utils'
import { areObjectsEqual, debounceEvent } from 'utils/helpersShared.utils'
import { getCurrencyIdFromProduct } from 'utils/product.utils'
import { apiClient, getMP3dsBrowserMetaData } from 'utils/requests.utils'
import { groupPlanLists, isPossibleToGroupPlanLists } from 'utils/order.utils'
import { getSearchParams } from 'utils/queryString.utils'
import { isWindowEnv } from 'utils/env.utils'
import { LocalStorageService } from 'utils/local-storage.utils'
import { convertFieldsObject } from 'utils/payerForm.utils'

import { notify } from 'libs/common/notify'

import { PRODUCT_TYPE_IDS, UPSELLS_TYPES, TICKET_VIEW } from 'constants/productsShared.constants'

import {
  CARD_NUMBER,
  PAYMENT_FORMS,
  PAYMENT_PROVIDERS,
  TWO_STEP_PAYMENT_STEPS,
  DEFAULT_PAYMETHODS_ORDER,
} from 'constants/paymentSettingShared.constants'

import { DEFAULT_CURRENCY } from 'constants/currencies.constants'
import { CUSTOM_TEMPLATE_TYPES } from 'constants/optIns.constants'
import { COUPON_PRODUCTS } from 'constants/coupons.constants'
import { VAT_TYPE } from 'constants/ordersShared.constants'
import { GREECE_CODES, SWITZERLAND_COUNTRY_CODE } from 'constants/countries.constants'

import SharedStore from 'shared/stores/shared.store'
import { ShopRootStore } from 'shop/stores/shopRoot.store'

import { Nullable } from 'types/helpers'
import { KlarnaFormPayload, StripeClient, StripeElement, StripeElementsParams } from 'shop/types/stripe'
import { createFingerPrint } from 'utils/fingerprint.utils'
import { OrdersApi, createOrdersApi } from 'shop/api/orders.api'
import { ProductsApi, createProductsApi } from 'shop/api/products.api'

import { fetchItem as checkCompanyByVatId } from 'shared/api/vatChecker.api'
import { TicketDate } from 'shop/types/ticket'
import { createKibanaApi, KibanaApi } from 'shop/api/kibana.api'
import { createGeoapifyApi, GeoapifyApi } from 'shop/api/geoapify.api'
import { TRANSFER_PROVIDERS_BASE } from 'constants/transfers.constants'

// TODO: change Record<string, never>
interface StoreDetails {
  validateOnSubmit: boolean
  withNextPayment: boolean
  twoStepActiveStep: number
  embedOrigin: string
  affiliateToken: string
  optInsData: {
    [key: number]: Record<string, never>
  }
  product: {
    planId: string | number
    count: number
  }
  upsell: Upsell[]
  event: Event
  attendees: Attendees
  paymethods: Record<string, never>
  coupon: Coupon
  prices: Record<string, never>
  activePlan: ActivePlan
  props?: StoreProps
  free?: boolean
  terms?: boolean
  firstPaymentIsFree?: boolean
}

interface Event {
  tickets: {
    [ticketId: string]: {
      pricingPlanId: string | number
      ticketDates: {
        [id: number]: {
          count: number
        }
      }
    }
  }
}

interface Coupon {
  form: boolean
  code: string
  data: {
    additionalFees?: boolean
    applyForm?: string
    couponsProducts?: {
      pricingPlanId: Nullable<number>
      ticketId: Nullable<number>
      productId: Nullable<number>
    }[]
    maxCount?: Nullable<number>
    percents?: boolean
    recurring?: boolean
    upsells?: boolean
    value: number
  }
  applied?: boolean
}

interface StoreProps {
  coupon: string
  embedType: string
  displayedPlansId: string
  campaignId: number
  addId1: number
  addId2: number
  funnelId: number
  orderToken: string
  showCoupon: boolean
  upsellId: number
  bundleId: number
  paymentPageTemplateId: string
  tickets: {
    ticketId: string
    ticketDates: {
      ticketsCount: string
      ticketDateId: string
    }[]
    displayedPlanIds: string[]
    planId: string
  }[]
}

interface ActivePlan {
  id?: number
  enabledPayMethods: string[]
  currencyKey: string
  prefs?: {
    testPeriod: string
    customStartDay: string
    sofortSepa: boolean
    idealSepa: boolean
    testPeriodHideAmounts: boolean
    pastDue: string
    sepaImmediate: boolean
    absoluteDates: boolean
    paypalRestPayLaterEnabled: boolean
  }
  currencyId?: number
  testPeriodDescription?: string
  firstIntervalDate?: string
  nextIntervalDate?: string
  preferredPayMethods?: string[]
  otherPayMethods?: string[]
  visualSeparationEnabled?: boolean
}

interface Attendees {
  active: boolean
  attendeeEmail: boolean
  list: {
    [key: number]: {
      country: {
        code: string
      }
      ticketDateId: number
      salutation: string
      firstName: string
      lastName: string
      email: string
      countryCode: string
      zip: string
      street: string
      streetNumber: string
      city: string
      phone: string
      state: string
    }
  }
  valids: {
    [key: number]: Record<string, never>
  }
}

interface Upsell {
  id?: number
  additionalFee?: Record<string, never>
  ticketsCount?: number
  ticketDateId?: number
  pricingPlans?: PricingPlan[]
  ticket?: Ticket
  productUpsellId?: number
  productAddons?: ProductAddon[]
  planId?: string | number
  ticketDate?: TicketDate
  addons?: {
    ticket: Ticket
    ticketDate: TicketDate
  }[]
  free?: boolean
}

interface ProductAddon {
  addonId: number
  addonType: string
  ticketId: number
  ticketDateId: number
  ticketsCount: number
}

interface Ticket {
  id: number
  pricingPlans: PricingPlan[]
  ticketDates: TicketDate[]
  name: string
  additionalText: string
  online: boolean
  locationAddress: string
  countryCode: string
  locationShortName: string
  free: boolean
}

interface PricingPlan {
  id: number
  form: string
  currencyId: number
  useNetPrice: boolean
  prefs: {
    price: number
    oldPrice: number
    testPeriod?: string
  }
  activateCountdown: boolean
  validTill: string
}

interface InvoiceDetails {
  tickets: Ticket[]
  coupon: string
  token: string
  pricingPlans: PricingPlan[]
  state: string
  isPaid: boolean
}

interface PayerForms {
  showGift: boolean
  skipPrefill: boolean
  userData: {
    payer: Payer
    owner?: Payer
  }
  formType?: string
  validState?: {
    payer?: {
      vatNo: string
    }
  }
  giftComment?: string
}

interface Payer {
  country: {
    code: string
    eu?: boolean
    label?: string
  }
  vatNo?: string
  company?: string
  phone?: string
  city?: string
  street?: string
  streetNumber?: string
  zip?: string
  salutation?: string
  firstName?: string
  lastName?: string
  email?: string
  emailConfirmation?: string
  additionalAddress?: string
  state?: string
}

interface OptInQuestion {
  mandatory: boolean
  id: number
  fieldType: string
  label: string
}

interface Product {
  id: number
  slug: string
  activeTickets: boolean
  cancelLink: Nullable<string>
  cancellationTerm: {
    checkboxVisible: boolean
    label: string
  }
  checkoutHtml: Nullable<string>
  checkoutHtmlFooter: Nullable<string>
  form: string
  free: boolean
  isSoldOut: boolean
  link: boolean
  name: string
  pricingPlans: PricingPlan[]
  tickets: Ticket[]
  upsells: Upsell[]
  additionalFee?: Record<string, never>
  cancellationTermId?: number
  b2bCancellationTermId?: number
  optIn?: {
    optInQuestions: OptInQuestion[]
  }
  label?: string
  limitPerOrder?: number
  sellMultiple?: boolean
  showCover?: boolean
  shortDescription?: string
  hideB2bCancellationTerm?: boolean
  ticketView?: string
  sessionTimerSettings?: {
    isSessionTimerActive: boolean
    timerTime: string
    timerText: string
    timerBackgroundColor: string
    timerFontColor: string
    backgroundImageActive: boolean
    backgroundImage: {
      coverId: string
      coverUrl: string
    }
  }
  socialProofBubbleActivated?: boolean
  socialProofBubble?: Bubble
  limitForSale?: number
}

export interface Bubble {
  bubbleType: string
  createdAt: string
  updatedAt: string
  productId: number
  pauseTimeSeconds: number
  id: number
  displayTimeSeconds: number
  designSettings: {
    backgroundColor: string
    badgeStyle: string
    designType: string
    textColor: string
  }
  data:
    | {
        city: string
        countryCode: string
        firstName: string
        purchasedAt: string
      }[]
    | {
        totalPurchases?: number
        totalViews?: number
      }
}

interface Params extends Payer {
  countryCode: string
}

interface BuildedOrderDetails {
  orderRates?: {
    state: string
    number: number
    orderRatePrices: {
      data: {
        cfgs: {
          mustPayVat: boolean
          isBusiness: boolean
          privateVatRate: number
          transferVatRate: number
          withVatNo: boolean
          businessVatRate: number
          vatBaseCountry: string
        }
        discount: OrderRateSummary
        fees: OrderRateSummary
        rate: OrderRateSummary
      }
    }[]
  }[]
  periodType?: string
  paymentState?: string
  paymentForm?: string
  id?: number
  createdAt?: string
  token?: string
  payerData?: {
    userAttributes?: any
    userProfileAttributes?: any
  }
  sellables?: {
    categoryKey: string
  }[]
}

interface OrderRateSummary {
  gross: number
  net: number
  count: number
  vat: number
}

interface SelectedUpsell {
  id: number
  productUpsellId: number
  form: string
  ticketId: number
  ticketDateId: number
  ticketsCount: number
  ticket: Ticket
  pricingPlans: PricingPlan[]
}

interface SellableItemList {
  productId: number
  sellableId: string | number
  sellableType: string
  pricingPlanId: string | number
  priceCount: number
  productUpsellId?: number
  sellableItemsAttributes: {
    productId: number
    ticketsCount?: number
    ticketAttendeesAttributes?: {
      ticketDateId: number
      salutation: string
      firstName: string
      lastName: string
      email: string
      countryCode: string
      zip: string
      street: string
      streetNumber: string
      city: string
      phone: string
    }[]
  }[]
}

interface DigitalPaymentMethods {
  applePay: boolean
  googlePay: boolean
}

interface VatDetails {
  valid: boolean
  dirty: boolean
  name?: string
  validCountry: boolean
}

export class PaymentStore extends SharedStore<any> {
  storeName = 'PaymentStore'
  root: ShopRootStore
  declare childApi: OrdersApi
  productsApi: ProductsApi
  kibanaApi: KibanaApi
  geoapifyApi: GeoapifyApi

  constructor(root: ShopRootStore) {
    super()
    this.root = root
    this.childApi = createOrdersApi(root?.apiClient ?? apiClient)
    this.productsApi = createProductsApi(root?.apiClient ?? apiClient)
    this.kibanaApi = createKibanaApi(root?.apiClient ?? apiClient)
    this.geoapifyApi = createGeoapifyApi()

    makeObservable(this)

    this.loading = false
  }

  // Data from server for page
  @observable shouldResetIntent = false
  @observable product: Product = {
    id: 0,
    slug: '',
    activeTickets: false,
    cancelLink: null,
    cancellationTerm: {
      checkboxVisible: false,
      label: '',
    },
    checkoutHtml: null,
    checkoutHtmlFooter: null,
    form: 'download',
    free: false,
    isSoldOut: false,
    link: false,
    name: '',
    pricingPlans: [],
    tickets: [],
    upsells: [],
  }

  @observable isPurchaseLoading = false
  @observable invoice: InvoiceDetails
  @observable buildedOrderLoading = true
  @observable mainProductSelected = true
  @observable buildedOrderPms = {}
  @observable buildedOrder: BuildedOrderDetails = {}
  @observable selectedUpsell: SelectedUpsell[] = []
  @observable selectedGroupedPricingPlan = ''
  @observable fraudSessionIdentifier = null

  @observable store: StoreDetails = {
    validateOnSubmit: false,
    withNextPayment: false,
    twoStepActiveStep: TWO_STEP_PAYMENT_STEPS.step1,
    embedOrigin: '',
    affiliateToken: '',
    optInsData: {},
    // data when product selected
    product: {
      planId: '',
      count: 1,
    },
    // data when upsell selected
    upsell: [],
    // data when event selected
    event: {
      tickets: {},
    },
    attendees: {
      attendeeEmail: false,
      active: false,
      list: {},
      valids: {},
    },
    paymethods: {},
    coupon: {
      form: false,
      code: '',
      data: {
        value: 0,
      },
    },
    prices: {},
    activePlan: {
      enabledPayMethods: [],
      currencyKey: 'eur',
    },
  }

  @observable payerForms: PayerForms = {
    showGift: false,
    skipPrefill: false,
    userData: {
      payer: {
        country: {
          code: 'DE',
        },
      },
    },
  }

  @observable buyBtnDisabled = false
  @observable vatData: VatDetails = {
    valid: true,
    dirty: false,
    validCountry: false,
  }

  @observable stripeClient = null
  @observable stripeP24 = null
  @observable stripeIdeal = null
  @observable stripeElements = null
  @observable stripeCard = null
  @observable stripeDigitalMethod = null
  @observable stripeAuthentificationLoading = false
  @observable stripePaymentRequest = null
  @observable stripeDigitalPaymentMethods: DigitalPaymentMethods

  @observable isListeningEmbedMessage = false

  @action setStripeP24 = (value: StripeElement | string) => (this.stripeP24 = value)
  @action setStripeIdeal = (value: StripeElement | string) => (this.stripeIdeal = value)

  @action setStripeElements = (value: StripeElement | string) => (this.stripeElements = value)
  @action setStripeCard = (value) => (this.stripeCard = value)
  @action setStripeClient = (value: StripeClient) => (this.stripeClient = value)
  @action setStripeDigitalMethod = (value: StripeClient) => (this.stripeDigitalMethod = value)
  @action stripeAuthentificationLoadingToggle = (value) => (this.stripeAuthentificationLoading = value)
  @action setStripePaymentRequest = (value: StripeElement) => (this.stripePaymentRequest = value)
  @action setStripeDigitalPaymentMethods = (value) => (this.stripeDigitalPaymentMethods = value)

  @action setSelectedGroupedPricingPlan = (value = '') => {
    this.selectedGroupedPricingPlan = value
  }
  @action setFraudSessionIdentifier = (value) => {
    this.fraudSessionIdentifier = value
  }

  @action resetSelectedUpsell = () => (this.selectedUpsell = [])
  @action resetUpsell = () => this.setStore({ upsell: [] })

  /////////////////////
  // ONE TIME ACTION //
  // fetch init data //
  /////////////////////
  @action fetchPayment = async (
    username: string,
    productSlug: string,
    params?: { is_preview?: string; ip?: string; payment_page_template_id?: string },
    token?: string
  ) => {
    const searchParams = isWindowEnv() ? this.getAllUrlParams() : params
    const { payment_page_template_id: urlTemplateId } = isWindowEnv() ? getSearchParams() : params

    this.loading = true

    const sessionResp = await this.childApi.newOrder(username, productSlug, {
      ...searchParams,
      token,
    })

    if (sessionResp.success) {
      const paymentPageTemplateId = urlTemplateId
        ? Number(urlTemplateId) || Number(sessionResp.data.props.paymentPageTemplateId)
        : Number(sessionResp.data.props?.paymentPageTemplateId) || this.root.productsStore.product.paymentPageTemplateId

      if (paymentPageTemplateId) {
        await this.root.themeStore.fetchPaymentPageTemplate(username, paymentPageTemplateId)
      }
    }

    await this.setProps(this.root.productsStore.product, sessionResp.data, username, params)
  }

  @action setBuyBtnDisabling = (value) => (this.buyBtnDisabled = value)

  @action setProps = async (productData, sessionData, username, params) => {
    this.product = {
      ...this.product,
      ...productData,
    }
    const { accessPassword, protectionUrl } = productData
    this.root.sellerStore.setProtectByPass(accessPassword)
    await this.root.sellerStore.setLockingByToken(protectionUrl, params)

    const { invoice, props, order } = sessionData
    const { coupon, tickets, token, upsells } = invoice || {}
    this.invoice = invoice || {}
    this.buildedOrder = order || {}

    let preparedProps = {
      ...props,
      pricingPlanId: props?.planId,
    }
    if (token) {
      const { id, ticketsCount } = (((tickets || [])[0] || {}).ticketDates || [])[0] || {}

      preparedProps = {
        ...preparedProps,
        coupon,
        ticketsCount,
        upsellId: ((upsells || [])[0] || {}).id,
        ticketDateId: id,
      }
    }
    await this.applyInitData(preparedProps)

    if (productData.id) {
      await this.recalculatePrices()
    }

    this.loading = false
  }

  preselectTicket = async (ticket) => {
    const { tickets: invoiceTickets } = this.invoice || {}
    const { ticketDates = [], id, pricingPlans } = ticket
    const ticketsCount = ticketDates[0].minToBeBought

    this.selectTicketDate(id, ticketDates[0].id)
    await this.handleTicketsCountChange(id, ticketDates[0].id, ticketsCount, true)

    if (invoiceTickets) {
      this.handleSelectPlan(id, pricingPlans[0].id)
    }
  }

  preselectFirstTicket() {
    const { tickets = [] } = this.product

    const getFirstTicket = (tickets) => {
      for (const ticket of tickets) {
        const ticketDates = ticket.ticketDates.filter((date) => date.limit !== 0 && !date.soldOut)
        if (ticketDates.length) {
          return {
            ...ticket,
            ticketDates,
          }
        }
      }
    }

    const firstTicket = getFirstTicket(tickets)

    if (firstTicket && tickets.length === 1) {
      this.preselectTicket(firstTicket)
    }
  }

  applyInitData = async (props) => {
    const { coupon, token, tickets: invoiceTickets } = this.invoice || {}
    const { form } = this.product
    const {
      embedType,
      payerAttrs,
      pricingPlanId,
      ticketDateId,
      ticketsCount,
      ticketId: preselectedTicketId,
      count = 1,
      tickets,
    } = props

    const { attendeesForm, includeType } = this.root.themeStore.ppTemplate.buyerInformation

    const urlTicketDates = tickets?.reduce(
      (prevItem, item) => [...prevItem, ...(Object.values(item.ticketDates || []) || [])],
      []
    )

    const urlTicketDateIds = urlTicketDates?.map((item) => String(item.ticketDateId))

    const ticketsToShow = this.product?.tickets?.reduce((prevTicket, ticket) => {
      const filteredTicketDates = ticket.ticketDates.filter((item) => urlTicketDateIds?.includes(String(item.id)))
      const currentTicket = tickets?.find((item) => String(item.ticketId) === String(ticket.id))
      const urlTicketIds = tickets?.map((item) => String(item.ticketId))

      if (urlTicketIds?.includes(String(ticket.id))) {
        return [
          ...prevTicket,
          {
            ...ticket,
            planId: currentTicket?.planId,
            displayedPlanIds: currentTicket?.displayedPlanIds || [],
            ticketDates: !filteredTicketDates.length
              ? ticket.ticketDates
              : filteredTicketDates.reduce(
                  (prevDate, ticketDate) => [
                    ...prevDate,
                    {
                      ...ticketDate,
                      preselectCount:
                        urlTicketDates?.find((item) => String(item.ticketDateId) === String(ticketDate.id))
                          ?.ticketsCount || 0,
                    },
                  ],
                  []
                ),
          },
        ]
      }

      return prevTicket
    }, [])

    // put values from ruby monolith app to store
    const store = {
      product: {
        planId: this.preselectProductPlan(props),
        count,
      },
      attendees: {
        ...this.store.attendees,
        active: this.proposeAttendees && (attendeesForm || {}).opened === 'on',
      },
      coupon: {
        ...this.store.coupon,
        form: !token,
      },
      props: {
        ...props,
      },
    }

    this.setStore(store)

    const payerForms = {
      formType: (includeType.private === 'on' && !token && 'private') || 'business',
      validState: {
        payer: this.getInitValidStateForFields('private'),
        owner: this.getInitValidStateForFields('business'),
      },
      userData: {
        payer: this.getInitFields(payerAttrs),
        owner: this.getInitFields(),
      },
      giftComment: '',
    }

    this.setPayerForms(payerForms)

    await this.handleCancellationTerm()

    if (embedType) {
      // TODO: remove, avoid merge data from different resources
      this.root.shopThemeStore.shopTheme.prefs = {
        ...this.root.shopThemeStore.shopTheme.prefs,
        showHeader: false,
        showFooter: false,
      }

      if (isWindowEnv()) {
        listenEmbedMessage(this.setEmbedOrigin)
      }
    }

    // Dirty function for ticket date/plan preselection
    if (form === PRODUCT_TYPE_IDS.eventTickets) {
      if (urlTicketDates?.length) {
        ticketsToShow.forEach((ticket) => {
          ticket.ticketDates.forEach(async (date) => {
            this.selectTicketDate(ticket.id, date.id)
            await this.handleTicketsCountChange(ticket.id, date.id, date.preselectCount, true)
          })
          this.handleSelectPlan(ticket.id, ticket.planId ?? ticket.displayedPlanIds[0] ?? ticket.pricingPlans[0]?.id)
        })
      } else if (preselectedTicketId) {
        const ticket = this.getTicketById(preselectedTicketId)
        const ticketId = ticket && ticket.id
        if (ticketId) {
          const isTicketDateValid = this.getTicketByTicketDateId(ticketDateId)

          if (ticketDateId && isTicketDateValid) {
            this.selectTicketDate(ticketId, ticketDateId)
            if (ticketsCount) {
              await this.handleTicketsCountChange(ticketId, ticketDateId, ticketsCount, true)
            }
          } else {
            this.selectTicketDate(ticketId, ticket.ticketDates[0].id)
          }
          if (pricingPlanId) {
            this.handleSelectPlan(ticketId, pricingPlanId)
          }
        }
      } else if (invoiceTickets) {
        invoiceTickets.forEach((ticket) => {
          ticket.ticketDates.forEach(async (date) => {
            this.selectTicketDate(ticket.id, date.id)
            await this.handleTicketsCountChange(ticket.id, date.id, date.ticketsCount, true)
          })
          this.handleSelectPlan(ticket.id, ticket.pricingPlans[0].id)
        })
        await this.recalculatePrices()
      } else {
        if (this.product?.ticketView === TICKET_VIEW.list) {
          this.preselectFirstTicket()
        }
      }
    }

    // preselect coupon
    this.submitCoupon(coupon || this.store.props.coupon || '')
  }

  getProductPlansToShow = (
    props?: { displayedPlansId: string },
    ticketId?: number,
    ticket?: { displayedPlansId: string },
    iframePlans?: any
  ) => {
    const { pricingPlans: invoicePricingPlans, token, tickets = [] } = this.invoice || {}
    const { form } = this.product
    const { displayedPlansId } = props || this.store?.props || {}

    const productPricingPlans = iframePlans || this.product.pricingPlans
    const toDisplay = ticket?.displayedPlansId
      ? ticket?.displayedPlansId
      : displayedPlansId && displayedPlansId.split(',')

    const isEvent = form === PRODUCT_TYPE_IDS.eventTickets

    const pricingPlans = isEvent ? (this.getTicketById(ticketId) || {}).pricingPlans || [] : productPricingPlans

    const allInvoicePricingPlans = isEvent ? this.getTicketPricingPlans(tickets, ticketId) : invoicePricingPlans

    let filtered = pricingPlans.filter(({ id }) => !toDisplay || toDisplay.indexOf(id.toString()) >= 0)
    if (filtered.length === 0) filtered = pricingPlans

    return token
      ? allInvoicePricingPlans.reduce(
          (prevPlan, plan) => [
            ...prevPlan,
            {
              ...plan,
              testPeriodEnabled: parseInt(plan.prefs?.testPeriod, 10) > 0,
            },
          ],
          []
        )
      : filtered.reduce(
          (prevPlan, plan) => [
            ...prevPlan,
            {
              ...plan,
              testPeriodEnabled: parseInt(plan.prefs?.testPeriod, 10) > 0,
            },
          ],
          []
        )
  }

  @action preselectProductPlan(props) {
    const { pricingPlans, token } = this.invoice || {}
    const { pricingPlanId } = props

    const filteredPlans = this.getProductPlansToShow(props)

    return (
      (token && ((pricingPlans || [])[0] || {}).id) ||
      (pricingPlanId && (filteredPlans.filter(({ id }) => id === Number(pricingPlanId))[0] || {}).id) ||
      (filteredPlans[0] || {}).id
    )
  }

  @action setAffiliateToken(token) {
    this.store.affiliateToken = token
  }

  @action trackPaymentVisit = async () => {
    const currency = this.root.currenciesStore.getKey(getCurrencyIdFromProduct(this.product)) || DEFAULT_CURRENCY
    this.root.trackingCodesStore.setTrackEventData('payment_page', {
      productId: this.product.id,
      sellerId: this.root.sellerStore.item.id,
      username: this.root.sellerStore.item.username,
      consentForm: this.root.sellerStore.item.consentForm,

      productName: this.product.name,
      upsellIds: this.root.productUpsellsStore.list.map(({ upsell }) => upsell.id),
      upsellNames: this.root.productUpsellsStore.list.map(({ upsell }) => upsell.name),
      currency,
    })
    await this.root.trackingCodesStore.trackEvent()
  }
  /////////////////////////
  // END ONE TIME ACTION //
  /////////////////////////

  ////////////////////
  // STORE UPDATERS //
  ////////////////////
  // @action updateTicketsData = (data) => {
  //   this.store.event.tickets = data
  //   this.prepareAttendees()
  //   this.recalculatePrices()
  // }

  @action setEmbedOrigin = ({ url }) => {
    this.store.embedOrigin = url
    listenPageHeightChanged('*')
  }

  @action handleProductSelectPlan = async (planId, withoutRecalculate?: boolean) => {
    const { upsell, activePlan } = this.store
    const hasAnyUpsells = upsell && upsell.length && upsell.some((upsellItem) => upsellItem.id)
    const isBump = get(this.root.productSettingsStore, 'item.prefs.upsellType', '') === UPSELLS_TYPES.bump
    const isMainProductRequired = get(this.root.productSettingsStore, 'item.prefs.mainProductRequired', false)

    if (activePlan?.id !== planId) {
      this.root.trackingUserEventsStore.track({
        pageType: 'shop_checkout',
        eventType: 'pricing_plan_change',
        withExperiment: true,
        payload: this.pricingPlansForUserTracking,
      })
    }

    if (hasAnyUpsells && !isBump && !isMainProductRequired) {
      await this.updateUpsellData({})
    }
    this.store.product = {
      ...this.store.product,
      planId,
    }

    if (!withoutRecalculate) {
      // shitty way; setup some reactions on fields changing
      await this.recalculatePrices()
    }
  }

  @action handleProductCount = async (count) => {
    this.store.product = {
      ...this.store.product,
      count,
    }

    // shitty way; setup some reactions on fields changing
    await this.recalculatePrices()
  }

  updatePaymethodsData = (paymethods) => this.setStore({ paymethods })

  handleOptInChange = (id: number, value: string | boolean) => {
    this.setStore({
      optInsData: {
        ...this.store.optInsData,
        [id]: value,
      },
    })
  }

  submitTerms = (terms: boolean) => this.setStore({ terms })

  ////////////////////////
  // END STORE UPDATERS //
  ////////////////////////

  /////////////
  // GETTERS //
  /////////////
  @computed get getPayerCountryCode() {
    return this.payerForms.userData.payer.country.code
  }

  @action getOwnerCountryCode = () => {
    const { showGift, userData } = this.payerForms
    const { owner, payer } = userData

    return (showGift && owner && owner.country.code) || payer.country.code
  }

  @computed get payerFormTypeForVat() {
    const { formType, userData, validState: { payer } = {} } = this.payerForms
    const { country, vatNo } = userData.payer || {}

    const isBusiness = formType === 'business' && ((vatNo && payer?.vatNo) || !country.eu)

    return isBusiness ? 'business' : 'private'
  }

  @computed get hasAnyUpsells() {
    const { upsell } = this.store

    return upsell && upsell.length && upsell.some((upsellItem) => upsellItem.id)
  }

  getTicketPricingPlans = (tickets, ticketId) =>
    (tickets.find((ticket) => ticket.id === Number(ticketId)) || {}).pricingPlans || []

  @action getAdditionalFee = () => {
    const { upsell } = this.store
    const hasAnyUpsells = upsell && upsell.length && upsell.some((upsellItem) => upsellItem.id)
    const sortedUpsell = [...upsell].sort(this.sortUpsellByPosition)
    const isMainProductRequired = get(this.root.productSettingsStore, 'item.prefs.mainProductRequired', false)

    if (
      ((isMainProductRequired || this.isMainProductSelected) && !this.store.free && !!this.product.additionalFee) ||
      !hasAnyUpsells
    ) {
      return this.product.additionalFee
    }

    return sortedUpsell[0]?.additionalFee
  }

  @action resetTickets = () => {
    if (this.product.form === PRODUCT_TYPE_IDS.eventTickets) {
      this.store.event.tickets = {}
    }
  }

  @computed get displaySummary() {
    const { form, free } = this.product
    const isUpsellActive = get(this.root.productSettingsStore, 'item.prefs.upsell', false)

    return (
      (isUpsellActive && this.root.productUpsellsStore.list.length > 0) ||
      !free ||
      (this.store.free !== undefined && !this.store.free) ||
      form === PRODUCT_TYPE_IDS.eventTickets
    )
  }

  /////////////////
  // END GETTERS //
  /////////////////

  /////////////
  // TICKETS //
  /////////////
  @computed get proposeAttendees() {
    const { upsell } = this.store
    const hasAnyUpsellsWithTickets =
      upsell && upsell.length > 0 && upsell.some((upsellItem) => upsellItem.id && upsellItem.ticketsCount > 0)

    return (
      this.root.themeStore.ppTemplate.buyerInformation.includeType.attendee === 'on' &&
      (this.product.form === PRODUCT_TYPE_IDS.eventTickets || hasAnyUpsellsWithTickets)
    )
  }

  @action getTicketById(ticketId) {
    const tickets = this.invoice?.tickets || this.product.tickets
    return tickets.find(({ id }) => id === Number(ticketId))
  }

  @action getTicketByTicketDateId(ticketDateId) {
    const tickets = this.invoice?.tickets || this.product.tickets
    return tickets.find((ticket) => ticket.ticketDates.find(({ id }) => id === Number(ticketDateId)))
  }

  @action getTicketDate(ticketDateId) {
    return this.getTicketByTicketDateId(ticketDateId).ticketDates.find(({ id }) => id === Number(ticketDateId))
  }

  @action selectTicketDate = async (ticketId, ticketDateId) => {
    const ticket = this.getTicketById(ticketId)
    const ticketDate = this.getTicketDate(ticketDateId)
    const pricingPlanId =
      (this.store.event.tickets[ticketId] || {}).pricingPlanId || (ticket.pricingPlans[0] && ticket.pricingPlans[0].id)
    const isBump = get(this.root.productSettingsStore, 'item.prefs.upsellType', '') === UPSELLS_TYPES.bump

    this.store.event.tickets = {
      ...this.store.event.tickets,
      [ticketId]: {
        ...this.store.event.tickets[ticketId],
        pricingPlanId,
        ticketDates: {
          ...(this.store.event.tickets[ticketId] || {}).ticketDates,
          [ticketDateId]: {
            count: ticketDate.minToBeBought,
          },
        },
      },
    }

    if (!isBump) {
      await this.deselectUpsell()
    }
  }

  @action deselectTicketDate(ticketId, ticketDateId) {
    const copy = toJS(this.store.event.tickets)
    if (Object.values(copy[ticketId].ticketDates).length === 1) {
      delete copy[ticketId]
    } else {
      delete copy[ticketId].ticketDates[ticketDateId]
    }
    this.store.event.tickets = { ...copy }

    if (Object.values(this.store.event.tickets).length === 0) {
      this.mainProductSelected = false
    }
  }

  @action handleTicketDateChange = async (e, ticketId, ticketDateId) => {
    e.target.checked ? this.selectTicketDate(ticketId, ticketDateId) : this.deselectTicketDate(ticketId, ticketDateId)

    // TODO: shitty way; setup some reactions on fields changing
    this.prepareAttendees()
    await this.recalculatePrices()
  }

  @action handleTicketsCountChange = async (ticketId, ticketDateId, count, isSelected) => {
    if (!isSelected) {
      this.selectTicketDate(ticketId, ticketDateId)
    }
    const date = this.getTicketDate(ticketDateId)
    let correctedCount = Number(count) || 1
    if (date.minToBeBought && correctedCount < date.minToBeBought) {
      correctedCount = date.minToBeBought
    } else if (date.limit && correctedCount > date.limit) {
      correctedCount = date.limit
    }

    this.store.event.tickets = {
      ...this.store.event.tickets,
      [ticketId]: {
        ...this.store.event.tickets[ticketId],
        ticketDates: {
          ...(this.store.event.tickets[ticketId] || {}).ticketDates,
          [ticketDateId]: {
            count: correctedCount,
          },
        },
      },
    }

    // shitty way; setup some reactions on fields changing
    this.prepareAttendees()
    await this.recalculatePrices()
  }

  @action handleSelectPlan = async (ticketId, planId) => {
    const ticket = this.getTicketById(ticketId)
    const planIsValid = ticket.pricingPlans.filter(({ id }) => id === Number(planId))[0]

    if (!this.store.event.tickets[ticket.id]) {
      this.selectTicketDate(ticket.id, (ticket.ticketDates[0] || {}).id)
    }

    if (planIsValid) {
      this.store.event.tickets = {
        ...this.store.event.tickets,
        [ticketId]: {
          ...this.store.event.tickets[ticketId],
          pricingPlanId: planId,
        },
      }

      // shitty way; setup some reactions on fields changing
      await this.recalculatePrices()
    }
  }
  /////////////////
  // END TICKETS //
  /////////////////

  /////////////
  // UPSELLS //
  /////////////
  /**
   * Adds new upsell item to store / changes to payment plan
   *
   * @param {*} upsell Product Upsell
   * @param {*} planId Product Upsell ID
   * @param {*} productUpsellId Product Upsell ID
   * @param {*} position Product position
   * @param {*} ticketInfo Product ticket info
   */
  @action selectUpsell = async (upsell, planId, productUpsellId, position, ticketInfo = {}) => {
    const isUpsellActive = get(this.root.productSettingsStore, 'item.prefs.upsell', false)
    const isBump = get(this.root.productSettingsStore, 'item.prefs.upsellType', '') === UPSELLS_TYPES.bump
    const upsellIndex = isBump ? this.getSelectedUpsellIndex(this.store.upsell, productUpsellId, 'productUpsellId') : 0

    if (isUpsellActive) {
      /* In case multiple items allowed or it's first element - push into array */
      if ((isBump && upsell && upsellIndex === -1) || this.selectedUpsell.length === 0) {
        this.selectedUpsell.push({
          productUpsellId,
          ...ticketInfo,
          ...upsell,
          position,
        })
      } else if (upsellIndex > -1) {
        this.selectedUpsell[upsellIndex] = {
          productUpsellId,
          ...ticketInfo,
          ...upsell,
          position,
        }
      }

      /* Update Store Data*/
      if (upsell) {
        /* In case multiple selection - do not clear ticket object */
        if (!isBump) {
          this.store.event.tickets = {}
        }

        await this.updateUpsellData(
          {
            planId,
            productUpsellId,
            ...ticketInfo,
            ...upsell,
            position,
          },
          upsellIndex
        )
      }

      this.resetSelectedGroupredPricingPlan()
    }
  }

  @action deselectUpsell = async (id?: number) => {
    const isBump = get(this.root.productSettingsStore, 'item.prefs.upsellType', '') === UPSELLS_TYPES.bump
    const upsellIndex = isBump ? this.getSelectedUpsellIndex(this.selectedUpsell, id, 'productUpsellId') : 0

    if (id && isBump && upsellIndex !== -1) {
      this.selectedUpsell.splice(upsellIndex, 1)
      await this.updateUpsellData({}, upsellIndex, true)
    } else if (this.selectedUpsell[0]) {
      this.selectedUpsell[0] = null
      await this.updateUpsellData({}, upsellIndex, true)
    }

    this.resetSelectedGroupredPricingPlan()
  }

  @action getSelectedUpsellIndex = (data: Upsell[], id: number, upsellKey = 'id') =>
    data.findIndex((upsell) => upsell?.[upsellKey] === id)

  @action getSelectedUpsell = (data: Upsell[], id: number, upsellKey = 'id') =>
    data.find((upsell) => upsell?.[upsellKey] === id) || {}

  updateUpsellData = async (
    data: {
      planId?: number
      productUpsellId?: number
      position?: number
    } = {},
    upsellIndex?: number,
    remove = false,
    withoutRecalculate?: boolean
  ) => {
    const { upsell } = this.store
    const newUpsels = [...upsell]
    const isBump = get(this.root.productSettingsStore, 'item.prefs.upsellType', '') === UPSELLS_TYPES.bump
    const preparedData = {
      ...data,
      tickets: this.prepareUpsellTickets(data),
    }

    if (isBump && upsellIndex > -1) {
      if (remove) {
        newUpsels.splice(upsellIndex, 1)
      } else {
        newUpsels[upsellIndex] = preparedData
      }
    } else if (isBump && !remove) {
      newUpsels.push(preparedData)
    } else if (remove) {
      newUpsels[0] = {}
    } else {
      newUpsels[0] = preparedData
    }

    this.setStore({
      upsell: newUpsels,
    })

    // shitty way; setup some reactions on fields changing
    this.prepareAttendees()

    if (!withoutRecalculate) {
      await this.recalculatePrices()
    }
  }

  recalculateOnChange = async () => {
    this.prepareAttendees()
    await this.recalculatePrices()
  }

  @action prepareUpsellTickets = (data) => {
    const { addons, id } = data
    const res = {}
    if (id && addons) {
      for (let i = 0; i < addons.length; i += 1) {
        const {
          form,
          ticket: { id },
          ticketDate,
          ticketsCount,
        } = addons[i]

        if (form === PRODUCT_TYPE_IDS.eventTickets) {
          if (!res[id]) {
            res[id] = {
              ticketDates: {},
            }
          }
          res[id].ticketDates[ticketDate.id] = {
            count: ticketsCount || 1,
          }
        }
      }
    }
    return res
  }

  @action selectMainProduct = (value) => {
    const productSettings = this.root.productSettingsStore.item.prefs || {}
    const isBump = productSettings.upsellType === UPSELLS_TYPES.bump
    const isUpsellActive = productSettings.upsell
    const isMainProductRequired = productSettings.mainProductRequired

    if (!this.mainProductSelected) {
      this.setSelectedGroupedPricingPlan('')
    }

    if (!isUpsellActive || isMainProductRequired) {
      this.mainProductSelected = true
      return
    }

    if (isBump) {
      this.mainProductSelected = !this.mainProductSelected

      this.resetSelectedGroupredPricingPlan()

      return
    }

    this.mainProductSelected = value
  }

  @computed get isMainProductSelected() {
    const { upsell, event } = this.store
    const { form, tickets = [] } = this.product || {}

    const hasAnyUpsells = upsell && upsell.length && upsell.some((upsellItem) => upsellItem.id)
    const isBump = get(this.root.productSettingsStore, 'item.prefs.upsellType', '') === UPSELLS_TYPES.bump
    const isMainProductRequired = get(this.root.productSettingsStore, 'item.prefs.mainProductRequired', false)
    const isAnyTickets = event && event.tickets && Object.values(event.tickets).length > 0
    const isEvent = form === PRODUCT_TYPE_IDS.eventTickets
    const isTicketSelected = isEvent ? !(tickets.length > 1) : this.mainProductSelected

    /* If multiple selection is allowed - check for upsells if not remove selection of main product if upsells are checked */
    return isBump ? isTicketSelected || isMainProductRequired || isAnyTickets : !hasAnyUpsells || isAnyTickets
  }
  /////////////////
  // END UPSELLS //
  /////////////////

  ////////////////
  // USER FORMS //
  ////////////////
  @action getInitValidStateForFields(type) {
    let fields
    if (['attendee', 'business', 'free', 'gift', 'private', 'private250'].includes(type)) {
      fields = convertFieldsObject(this.root.themeStore.ppTemplate.buyerInformation[type])
    } else {
      fields = this.root.themeStore.ppTemplate.buyerInformation[type]
    }
    const initState = {
      company: false,
      salutation: false,
      firstName: false,
      lastName: false,
      street: false,
      streetNumber: false,
      email: false,
      emailConfirmation: false,
      zip: false,
      city: false,
      country: false,
      vatNo: false,
      phone: false,
    }

    const newFields = {}

    for (const name in fields) {
      if (fields.hasOwnProperty(name)) {
        const { required, visible } = fields[name]
        newFields[name] = !(required && required === 'on') && visible && visible === 'on'
      }
    }

    return Object.assign(initState, newFields)
  }

  getInitFields = (pms?: Params) => ({
    company: pms?.company || '',
    salutation: pms?.salutation || '',
    firstName: pms?.firstName || '',
    lastName: pms?.lastName || '',
    street: pms?.street || '',
    streetNumber: pms?.streetNumber || '',
    email: pms?.email || '',
    zip: pms?.zip || '',
    city: pms?.city || '',
    country: {
      code: pms?.countryCode || 'DE',
      eu: true,
      label: '',
    },
    vatNo: pms?.vatNo || '',
    phone: pms?.phone || '',
  })

  @computed get payerFromType() {
    return this.store?.free ? 'free' : this.payerForms?.formType
  }

  @action handleFormTypeSelector = async (type: string, withoutRecalculation?: boolean) => {
    this.payerForms = {
      ...this.payerForms,
      formType: type,
    }

    // TODO: shitty way; setup some reactions on fields changing
    await this.handleCancellationTerm()

    if (!withoutRecalculation) {
      await this.recalculatePrices()
    }
  }

  @action switchGiftMode = async () => {
    this.payerForms = {
      ...this.payerForms,
      showGift: !this.payerForms?.showGift,
    }

    // shitty way; setup some reactions on fields changing
    await this.recalculatePrices()
  }

  @action handleGiftCommentChange = (e) => {
    this.payerForms = {
      ...this.payerForms,
      giftComment: e.target.value,
    }
  }

  @action updatePayerFormsInput = async (type: string, inputName: string, value?: string | { eu?: boolean }) => {
    let shouldRecalculatePrices = false
    const { userData } = this.payerForms

    if (inputName === 'country' && this.payerForms.userData[type]['country'].code !== value?.['code']) {
      shouldRecalculatePrices = true
    }

    if (inputName === 'vatNo' && this.payerForms.userData[type]['vatNo'] !== value) {
      shouldRecalculatePrices = true
    }

    this.payerForms = {
      ...this.payerForms,
      userData: {
        ...userData,
        [type]: {
          ...userData[type],
          [inputName]: value,
        },
      },
    }

    if (inputName === 'country') {
      await this.checkDigitalMethodsAvailability()

      if (typeof value !== 'string' && !value?.eu) {
        this.updatePayerFormsInput(type, 'vatNo', '')
      }
    }

    // TODO: shitty way; setup some reactions on fields changing
    if (shouldRecalculatePrices) {
      this.debouncedRecalculatePrices()
    }
  }

  handleCancellationTerm = async () => {
    const { formType } = this.payerForms
    const { cancellationTermId, b2bCancellationTermId } = this.product
    const {
      item: { username },
    } = this.root.sellerStore
    const { item, fetchItem } = this.root.cancellationTermsStore
    const { includeType } = this.root.themeStore.ppTemplate.buyerInformation

    const hasB2BCancellationTerm = b2bCancellationTermId && b2bCancellationTermId !== item.id
    if (hasB2BCancellationTerm && (formType === 'business' || includeType.private === 'off')) {
      await fetchItem(b2bCancellationTermId, { username })
    } else if (cancellationTermId && cancellationTermId !== item.id) {
      await fetchItem(cancellationTermId, { username })
    }
  }

  @action updatePayerFormsValid = async (
    type: string,
    inputName: string,
    value: string | boolean,
    shouldRecalculate?: boolean
  ) => {
    const { validState = {} } = this.payerForms

    this.payerForms = {
      ...this.payerForms,
      validState: {
        ...validState,
        [type]: {
          ...validState[type],
          [inputName]: value,
        },
      },
    }

    if (shouldRecalculate) {
      await this.recalculatePrices()
    }
  }

  @action openForm = () => {
    this.payerForms = {
      ...this.payerForms,
      skipPrefill: true,
    }
  }

  @computed get isPayerFormsFullyValid() {
    return this.isValidFor('payer') && (!this.payerForms.showGift || this.isValidFor('owner'))
  }

  @computed get isPayerFormPrefilled() {
    return this.isPayerFormsFullyValid && !this.payerForms.skipPrefill
  }

  isValidFor = (type) => {
    const { userData = {}, validState = {} } = this.payerForms

    let confType = type
    switch (type) {
      case 'payer':
        confType = this.payerFromType
        break
      case 'owner':
        confType = 'gift'
        break

      default:
        break
    }

    const config = convertFieldsObject(this.root.themeStore.ppTemplate.buyerInformation[confType] || {})

    const valids = validState[type] || {}
    const data = userData[type] || {}
    // get only visible fields

    const fieldsToValidate = Object.keys(config).filter(
      (field) => config[field] && config[field].visible && config[field].visible === 'on'
    )

    for (let i = 0; i < fieldsToValidate.length; ++i) {
      const field = fieldsToValidate[i]
      if (
        (this.root.themeStore.ppTemplate.buyerInformation.emailConfirmation?.visible === 'on' &&
          data.emailConfirmation !== data.email &&
          confType !== 'gift') ||
        !valids.state ||
        (field === 'fullName' && !(valids.firstName && valids.lastName)) ||
        (field === 'vatNo' &&
          !valids.vatNo &&
          data.country &&
          (data.country.eu || data.country.code === SWITZERLAND_COUNTRY_CODE) &&
          config.country &&
          config.country.visible &&
          config.country.visible === 'on') ||
        (!['vatNo', 'fullName'].includes(field) && !valids[field])
      ) {
        return false
      }
    }

    return true
  }

  ////////////////////
  // END USER FORMS //
  ////////////////////

  ///////////////
  // ATTENDEES //
  ///////////////
  handleSelectCopyChange = (key, value) => {
    let data
    if (value === 'payer') {
      data = toJS(this.payerForms.userData.payer)
      delete data.vatNo
      delete data.company
    } else if (value === 'default') {
      data = {
        salutation: '',
        firstName: '',
        lastName: '',
        email: '',
        city: '',
        street: '',
        streetNumber: '',
        zip: '',
        country: {
          label: 'Germany',
          code: 'DE',
          eu: true,
        },
        phone: '',
      }
    } else {
      data = toJS(this.store.attendees.list[value])
      delete data.ticketDateId
    }
    const { attendees } = this.store
    const { list } = attendees
    const item = list[key]

    this.setStore({
      attendees: {
        ...attendees,
        list: {
          ...list,
          [key]: {
            ...item,
            ...data,
          },
        },
      },
    })
  }

  @action updateAttendees = (key, inputName, value) => {
    const { attendees } = this.store
    const { list } = attendees
    const item = list[key]

    const inputValue =
      inputName === 'country'
        ? {
            ...item[inputName],
            ...value,
          }
        : value

    this.setStore({
      attendees: {
        ...attendees,
        list: {
          ...list,
          [key]: {
            ...item,
            [inputName]: inputValue,
          },
        },
      },
    })
  }

  updateAttendeesValid = (key, inputName, value) => {
    const { attendees } = this.store
    const { valids } = attendees

    this.setStore({
      attendees: {
        ...attendees,
        valids: {
          ...valids,
          [key]: {
            ...valids[key],
            [inputName]: value,
          },
        },
      },
    })
  }

  @action handleAttendeesChangeChk = () => {
    this.store.attendees.active = !this.store.attendees.active
    if (!this.store.attendees.active) {
      this.store.attendees.attendeeEmail = false
    }
    this.prepareAttendees()
  }

  @action handleAttendeesSendEmailChk = () => {
    this.store.attendees.attendeeEmail = !this.store.attendees.attendeeEmail
    if (this.store.attendees.attendeeEmail) {
      this.store.attendees.active = true
    }
    this.prepareAttendees()
  }

  prepareAttendees = () => {
    const { attendees, event, upsell } = this.store

    if (attendees.active) {
      const initValidState = this.getInitValidStateForFields('attendee')
      const list = {}
      const valids = {}

      const hasAnyUpsells = upsell && upsell.length && upsell.some((upsellItem) => upsellItem.id)

      if (Object.keys(event?.tickets || {})?.length > 0) {
        const { tickets } = event
        Object.keys(tickets).forEach((ticketId) => {
          const { ticketDates } = tickets[ticketId]
          Object.keys(ticketDates).forEach((ticketDateId) => {
            for (let i = 0; i < ticketDates[ticketDateId].count; i += 1) {
              const key = snakeCaseToCamelCase(`${ticketDateId}_${i}`)
              valids[key] = toJS(attendees.valids[key]) || initValidState
              list[key] = toJS(attendees.list[key]) || {
                country: {
                  code: 'DE',
                },
                number: i + 1,
                ticketDateId,
              }
            }
          })
        })
      }

      if (hasAnyUpsells) {
        upsell.forEach((upsellItem) => {
          const { ticketDateId, ticketsCount } = upsellItem

          if (!ticketsCount) {
            return null
          }

          for (let i = 0; i < ticketsCount; i += 1) {
            const key = snakeCaseToCamelCase(`${ticketDateId}_${i}`)
            valids[key] = toJS(attendees.valids[key]) || initValidState
            list[key] = toJS(attendees.list[key]) || {
              country: {
                code: 'DE',
              },
              number: i + 1,
              ticketDateId: Number(ticketDateId),
            }
          }
        })
      }

      this.setStore({
        attendees: {
          ...attendees,
          list,
          valids: {
            ...valids,
          },
        },
      })
    }
  }

  @computed get optInAreValid() {
    const { optIn: { optInQuestions = [] } = {} } = this.product
    if (optInQuestions.length) {
      const mandatoryQuestions = optInQuestions.filter((item) => item.mandatory)

      return (
        !mandatoryQuestions.length ||
        mandatoryQuestions
          .map(({ id, fieldType }) => {
            const optIn = this.store.optInsData[id] || []
            const isOpenQuestion = fieldType === CUSTOM_TEMPLATE_TYPES.openQuestion
            const isSelectBox = fieldType === CUSTOM_TEMPLATE_TYPES.selectBox

            return isOpenQuestion || isSelectBox ? optIn.length > 0 : optIn
          })
          .every((elem) => elem === true)
      )
    }
    return true
  }

  @computed get attendeesAreValid() {
    const { active, valids } = this.store.attendees

    if (active) {
      const config = convertFieldsObject(this.root.themeStore.ppTemplate.buyerInformation.attendee)
      const keys = Object.keys(valids)
      // get only visible fields
      const fieldsToValidate = Object.keys(config || {}).filter((field) => {
        const { visible } = config[field]

        return visible && visible === 'on'
      })

      for (let i = 0; i < fieldsToValidate.length; ++i) {
        const field = fieldsToValidate[i]
        for (let j = 0; j < keys.length; ++j) {
          const key = keys[j]
          const { firstName, lastName, state, [field]: formField } = valids[key]
          const isFullName = field === 'fullName'

          if (isFullName ? !(firstName && lastName) : !formField || !state) {
            return false
          }
        }
      }
    }
    return true
  }
  ///////////////////
  // END ATTENDEES //
  ///////////////////

  /////////////
  // SUMMARY //
  /////////////

  handleSuccessCouponCheck = async ({ code, data }) => {
    const { applyForm, couponsProducts = [] } = data || {}
    const { form, id } = this.product
    const { event, product } = this.store

    const handleCouponApply = async () => {
      const store = {
        coupon: {
          code,
          data,
          applied: true,
          form: false,
        },
      }

      this.setStore(store)
      await this.recalculatePrices()
      notify('success', I18n.t('react.shop.payment.coupon.msg.applied'))
    }

    if ([COUPON_PRODUCTS.all, COUPON_PRODUCTS.multiple].includes(applyForm)) {
      await handleCouponApply()
    } else {
      const { couponProducts, couponTickets } = couponsProducts.reduce(
        (result, item) => {
          const { couponProducts, couponTickets } = result

          const { pricingPlanId, productId, ticketId } = item

          return {
            couponProducts: {
              ...couponProducts,
              [productId]: pricingPlanId,
            },
            couponTickets: {
              ...couponTickets,
              [ticketId]: pricingPlanId,
            },
          }
        },
        {
          couponProducts: {},
          couponTickets: {},
        }
      )

      if (form === PRODUCT_TYPE_IDS.eventTickets) {
        const eventTicketsEntries = Object.entries(event.tickets)

        const { correctTicketChosen, noPricingPlanLimits, ticketsPricingPlansMatch } = eventTicketsEntries.reduce(
          (result, [id, { pricingPlanId }]) => {
            const { correctTicketChosen, noPricingPlanLimits, ticketsPricingPlansMatch } = result

            return {
              correctTicketChosen: correctTicketChosen || Object.prototype.hasOwnProperty.call(couponTickets, id),
              noPricingPlanLimits: noPricingPlanLimits || couponTickets[id] === null,
              ticketsPricingPlansMatch: ticketsPricingPlansMatch || couponTickets[id] === pricingPlanId,
            }
          },
          {
            correctTicketChosen: false,
            noPricingPlanLimits: false,
            ticketsPricingPlansMatch: false,
          }
        )

        if (correctTicketChosen) {
          if (ticketsPricingPlansMatch || noPricingPlanLimits) {
            handleCouponApply()
          } else {
            notify('warning', I18n.t('react.shop.payment.coupon.msg.invalid_pricing_plan'))
          }
        } else {
          notify('warning', I18n.t('react.shop.payment.coupon.msg.invalid_ticket'))
        }
      } else {
        const productPricingPlanMatch = couponProducts[id] === product.planId
        const noPricingPlanLimits = couponProducts[id] === null

        if (productPricingPlanMatch || noPricingPlanLimits) {
          handleCouponApply()
        } else {
          notify('warning', I18n.t('react.shop.payment.coupon.msg.invalid_pricing_plan'))
        }
      }
    }
  }

  submitCoupon = async (code) => {
    if (code.length > 0) {
      const { slug } = this.product

      const resp = await this.childApi.checkCoupon(this.root.sellerStore.item.username, slug, code)
      const { data, success, error } = resp

      if (success) {
        this.handleSuccessCouponCheck({
          code,
          data,
        })
      } else {
        !error && notify('warning', I18n.t('react.shop.payment.coupon.msg.fail'))
        return error || I18n.t('react.shop.payment.coupon.msg.fail')
      }
    }
  }

  removeCoupon = async () => {
    const store = {
      coupon: {
        form: true,
        applied: false,
        code: '',
        data: {
          value: 0,
        },
      },
    }

    this.setStore(store)
    await this.recalculatePrices()
  }

  @action setStore = (store) => {
    this.store = {
      ...this.store,
      ...store,
    }
  }

  @action setPayerForms = (payerForms) => {
    this.payerForms = {
      ...this.payerForms,
      ...payerForms,
    }
  }

  @action updateVatData = (vatData) => (this.vatData = vatData)

  checkVatId = async () => {
    const {
      userData: {
        payer: { vatNo, company, country },
      },
    } = this.payerForms
    this.setBuyBtnDisabling(true)
    if (vatNo && isValidVatNo(vatNo)) {
      const resp = await checkCompanyByVatId(vatNo)
      const isCountryValid = GREECE_CODES.includes(resp.data?.countryCode)
        ? GREECE_CODES.includes(country.code)
        : country.code === resp.data?.countryCode

      this.updateVatData({
        ...resp.data,
        dirty: true,
        valid: resp.data.valid,
        validCountry: isCountryValid,
      })
    } else if (country.code === SWITZERLAND_COUNTRY_CODE) {
      this.updateVatData({
        dirty: true,
        valid: true,
        validCountry: true,
      })
    } else {
      this.updateVatData({
        dirty: true,
        valid: false,
        validCountry: false,
      })
    }

    this.updatePayerFormsInput('company', company)
    this.setBuyBtnDisabling(false)
  }

  getPlanData(planId: string | number, type: string, relId?: string) {
    const { pricingPlans, token, tickets } = this.invoice || {}
    if (token) {
      if (type === 'tickets') {
        return this.getTicketPricingPlans(tickets, relId)[0] || {}
      }
      return pricingPlans[0] || {}
    }

    let plans = []
    switch (type) {
      case 'product':
        plans = this.product.pricingPlans
        break
      case 'tickets':
        plans = (this.product.tickets.find(({ id }) => String(id) === String(relId)) || {}).pricingPlans
        break
      case 'upsells': {
        plans = [
          ...this.selectedUpsell.reduce(
            (result, item) => [...result, ...(item?.ticket?.pricingPlans || []), ...item?.pricingPlans],
            []
          ),
        ]
        break
      }
      default:
        plans = []
        break
    }

    return (plans || []).find(({ id }) => id === Number(planId)) || {}
  }

  getActivePlanParams = (store, upsell, product) => {
    const isBump = get(this.root.productSettingsStore, 'item.prefs.upsellType', '') === UPSELLS_TYPES.bump
    const isMainProductRequired = isBump && get(this.root.productSettingsStore, 'item.prefs.mainProductRequired', false)
    const isMainProductRequiredOrSelected = isMainProductRequired || this.isMainProductSelected

    if (isMainProductRequiredOrSelected && product.planId) {
      return this.getPlanData(product.planId, 'product')
    }

    /** We need select fist item sorted by position & it should be not free */
    const sortedUpsell = [...upsell].sort(this.sortUpsellByPosition) || []
    const sortedAndNonFreeUpsell =
      sortedUpsell.filter((upsellItem) => !upsellItem.free || upsellItem.pricingPlans?.length) || []
    const finalItem = sortedAndNonFreeUpsell.length > 0 ? sortedAndNonFreeUpsell[0] : sortedUpsell[0]

    return this.getPlanData(finalItem.planId, 'upsells', finalItem.upsellId)
  }

  setActivePlan = () => {
    const {
      event: { tickets },
      product,
      upsell,
    } = this.store
    const hasAnyUpsells =
      upsell?.length &&
      upsell.some(
        (upsellItem) => upsellItem.id && (upsellItem.pricingPlans?.length || upsellItem.ticket?.pricingPlans?.length)
      )
    const isBump = get(this.root.productSettingsStore, 'item.prefs.upsellType', '') === UPSELLS_TYPES.bump
    const isUpsellActive = get(this.root.productSettingsStore, 'item.prefs.upsell', false)
    const isMainProductRequired = get(this.root.productSettingsStore, 'item.prefs.mainProductRequired', false)

    let activePlan = {}

    const ticketsKeys = Object.keys(tickets)
    const isTicketsPricingPlanId = ticketsKeys?.map((key) => tickets[key]?.pricingPlanId).some(Boolean)

    if (this.product.form === PRODUCT_TYPE_IDS.eventTickets && isTicketsPricingPlanId) {
      ticketsKeys?.forEach((key) => {
        const { pricingPlanId } = tickets[key]

        const activeTicketPlan = this.getPlanData(pricingPlanId, 'tickets', key)

        if (pricingPlanId) activePlan = activeTicketPlan
      })
    } else if (isUpsellActive && hasAnyUpsells) {
      activePlan = this.getActivePlanParams(this.store, upsell, product)
    } else if (isBump && isUpsellActive && !isMainProductRequired && !hasAnyUpsells && !this.isMainProductSelected) {
      activePlan = {
        enabledPayMethods: [],
        currencyKey: 'eur',
      }
    } else {
      activePlan = this.getPlanData(product.planId, 'product')
    }

    this.setStore({ activePlan })
  }

  @computed get sortedEnabledMethods() {
    const { activePlan } = this.store
    const { enabledPayMethods = [], preferredPayMethods, otherPayMethods } = activePlan

    const sellerAllowedPaymentMethods =
      this.root.sellerStore.item.allowedPaymentMethods?.[this.store?.activePlan?.currencyId] || []
    const sortedWithPreferredMethods = intersection(DEFAULT_PAYMETHODS_ORDER, otherPayMethods)
    const sortedSellerMethods = intersection(DEFAULT_PAYMETHODS_ORDER, sellerAllowedPaymentMethods)

    return preferredPayMethods?.length
      ? intersection([...preferredPayMethods, ...sortedWithPreferredMethods], sellerAllowedPaymentMethods)
      : intersection(sortedSellerMethods, enabledPayMethods)
  }

  resetPaymentMethod = () => {
    const { paymethods } = this.store

    const form = this.sortedEnabledMethods[0]

    this.setStore({
      paymethods: {
        ...paymethods,
        form,
      },
    })
  }

  managePaymentForm = () => {
    const { paymethods, activePlan } = this.store
    const { enabledPayMethods = [] } = activePlan

    if (enabledPayMethods.indexOf(paymethods.form) < 0) {
      this.resetPaymentMethod()
    }
  }

  @action applyBuildedOrderData = (data) => {
    this.buildedOrder = data
    const { orderRates = [] } = data
    const [firstRate, secondRate] = orderRates

    const isFirstPaymentFree = firstRate && Number(firstRate.amount) == 0
    const isSecondPaymentFree = secondRate ? Number(secondRate.amount) === 0 : true
    const isFree = data.paymentForm === 'free' && isFirstPaymentFree && isSecondPaymentFree

    this.setStore({
      free: isFree,
      firstPaymentIsFree: isFirstPaymentFree,
      withNextPayment: secondRate && Number(secondRate.amount) > 0,
    })
    this.buildedOrderLoading = false
  }

  @action buildOrder = async (username, boPms) => {
    this.buildedOrderLoading = true
    const resp = await this.childApi.buildOrder(username, boPms)

    if (resp?.data) {
      runInAction(() => (this.buildedOrderPms = boPms))
      await this.applyBuildedOrderData(resp.data)
    }
  }

  @action recalculatePrices = async () => {
    const { event, upsell } = this.store
    // should be fired when changes in prices
    // product/upsell/tickets selections
    // count/coupon changes

    this.setActivePlan()
    this.managePaymentForm()
    const hasAnyUpsells = upsell && upsell.length && upsell.some((upsellItem) => upsellItem.id)
    const isBump = get(this.root.productSettingsStore, 'item.prefs.upsellType', '') === UPSELLS_TYPES.bump
    const isUpsellActive = get(this.root.productSettingsStore, 'item.prefs.upsell', false)
    const isMainProductRequired = get(this.root.productSettingsStore, 'item.prefs.mainProductRequired', false)

    if (
      this.product.form === PRODUCT_TYPE_IDS.eventTickets &&
      !hasAnyUpsells &&
      Object.values(event.tickets).length === 0
    ) {
      this.buildedOrder = {}
      this.buildedOrderLoading = false
    } else if (isBump && isUpsellActive && !isMainProductRequired && !hasAnyUpsells && !this.isMainProductSelected) {
      this.buildedOrder = {}
      this.buildedOrderLoading = false
    } else if (!this.invoice?.token) {
      const { username } = this.root.sellerStore.item
      const boPms = this.paramsForSubmit
      const shouldBuild = !areObjectsEqual(boPms, this.buildedOrderPms)

      if (shouldBuild || isEmpty(this.buildedOrder)) {
        await this.buildOrder(username, boPms)
        this.buildedOrderLoading = false

        const { is_preview: isPreview } = getSearchParams()
        this.kibanaApi.buildTriggered(this.root.sellerStore.item.id, !!isPreview)
      } else {
        await this.applyBuildedOrderData(this.buildedOrder)
      }
    } else {
      this.buildedOrderLoading = false
      await this.checkDigitalMethodsAvailability()
    }
    this.checkDigitalMethodsAvailability()
  }

  debouncedRecalculatePrices = debounceEvent(this.recalculatePrices, 300)
  /////////////////
  // END SUMMARY //
  /////////////////

  ////////////
  // SUBMIT //
  ////////////

  @observable stripeCardValid = false
  @action setStripeCardValidity = (isValid: boolean) => (this.stripeCardValid = isValid)

  @observable zipMatchCountry = true
  @action setZipMatchCountry = (value) => (this.zipMatchCountry = value)

  validateZip = () => {
    const { zip, country } = convertFieldsObject(
      this.root.themeStore.ppTemplate.buyerInformation[this.payerFromType] || {}
    )
    const {
      zip: zipCode,
      country: { code: countryCode },
    } = this.payerForms.userData.payer || {}

    if (zip?.required === 'on' && country?.required === 'on' && postcodeValidatorExistsForCountry(countryCode)) {
      const codeValid = postcodeValidator(zipCode, countryCode)

      if (!codeValid) this.openForm()

      return codeValid
    }

    return true
  }

  handleSubmit = () => {
    const { event, upsell, twoStepActiveStep } = this.store
    const { token: invoiceToken } = this.invoice || {}

    const hasAnyUpsells = upsell && upsell.length && upsell.some((upsellItem) => upsellItem.id)

    if (
      this.product.form === PRODUCT_TYPE_IDS.eventTickets &&
      !hasAnyUpsells &&
      Object.values(event.tickets).length === 0
    ) {
      notify('error', I18n.t('react.shop.payment.no_tickets_no_submit'))
    } else {
      this.isPurchaseLoading = true
      /* Revalidate the payer form */
      this.triggerValidateOnSubmit(false)
      this.triggerValidateOnSubmit(true)

      if (!invoiceToken) {
        this.setZipMatchCountry(this.validateZip())
      }

      return new Promise((resolve) => {
        setTimeout(() => {
          if (this.isValidForSubmit && this.zipMatchCountry) {
            resolve(this.submitForm())
          } else {
            if (twoStepActiveStep === TWO_STEP_PAYMENT_STEPS.step2) {
              /* In case of Two-Step Checkout - change step to #1 and disable preffil */
              this.setPayerForms({ skipPrefill: true })
              this.triggerStepChange(TWO_STEP_PAYMENT_STEPS.step1)

              /* Revalidate the payer form */
              this.triggerValidateOnSubmit(false)
              this.triggerValidateOnSubmit(true)
            }

            this.hideLoading()
            setTimeout(this.highlightInvalidFields, 500)
          }
        }, 300)
      })
    }
  }

  triggerValidateOnSubmit = (validateOnSubmit: boolean) => this.setStore({ validateOnSubmit })
  triggerStepChange = (step: number) => this.setStore({ twoStepActiveStep: step })

  @action hideLoading = () => (this.isPurchaseLoading = false)

  @action highlightInvalidFields = () => {
    const payerForm = document.querySelector('.payer-forms')
    if (payerForm) {
      payerForm.scrollIntoView()
    }

    const field = document.querySelector(
      'input.is-invalid, select.is-invalid, .payment-phone.elo-input--error input'
    ) as HTMLElement | null

    if (field !== null) {
      field.focus()
    }

    this.triggerValidateOnSubmit(false)
  }

  validateForm = () => {
    this.triggerValidateOnSubmit(false)
    this.triggerValidateOnSubmit(true)
  }

  handleRedirect = (redirectLink: string) => {
    const { embedOrigin, props } = this.store
    if (props?.embedType) {
      embedRedirectionMessage(redirectLink, embedOrigin || '*')
    } else {
      window.location.href = redirectLink
    }
  }

  @action submitForm = async () => {
    const { paymethods, activePlan, free } = this.store
    const {
      cardProvider,
      sofortProvider,
      username,
      stripeSofortSepa,
      elopageConnectSofortSepa,
      id: sellerId,
      paypalRestV2,
      paypalProvider,
    } = this.root.sellerStore.item
    const { prefs } = activePlan || {}

    const isDigitalPayment = paymethods.form === PAYMENT_FORMS.applePay || paymethods.form === PAYMENT_FORMS.googlePay
    const showDigitalPaymentModal = isDigitalPayment && this.stripePaymentRequest && !prefs?.customStartDay
    if (showDigitalPaymentModal) {
      this.stripePaymentRequest.show()
    }
    const isCard = paymethods.form === PAYMENT_FORMS.card
    const isMangoPay = cardProvider === PAYMENT_PROVIDERS.mangoPay

    const paramsForSubmit = {
      ...this.paramsForSubmit,
    }

    if (this.fraudSessionIdentifier) {
      const paypalFraudData = {
        paypalFraudToken: this.fraudSessionIdentifier,
      }

      paramsForSubmit.transfer = {
        ...paramsForSubmit.transfer,
        ...paypalFraudData,
      }
    }

    if (isCard && isMangoPay) {
      const mp3dsBrowserMetaData = getMP3dsBrowserMetaData()

      paramsForSubmit.transfer = {
        ...paramsForSubmit.transfer,
        ...mp3dsBrowserMetaData,
      }
    }

    if (paymethods.form === PAYMENT_FORMS.klarna && this.stripeElements) {
      const { error } = await this.stripeElements.submit()
      if (error) {
        notify('error', error)
        this.hideLoading()
        return
      }
    }

    const orderSessionId = createFingerPrint()

    this.kibanaApi.createOrder({ orderSessionId, sellerId, orderPaymentForm: paramsForSubmit.transfer.form })

    const { zip, country, city } = convertFieldsObject(
      this.root.themeStore.ppTemplate.buyerInformation[this.payerFromType] || {}
    )
    const {
      zip: zipCode,
      city: cityName,
      country: { label: countryName },
      vatNo,
    } = this.payerForms.userData.payer || {}

    this.root.trackingUserEventsStore.track({
      pageType: 'shop_checkout',
      eventType: 'buy_button_click',
      withExperiment: true,
      payload: {
        payerFromType: this.payerFromType,
        totalPrice: this.totalPrice(),
        paymentType: paymethods.form,
        vatNo,
      },
    })

    if (zip?.required === 'on' && country?.required === 'on' && city?.required === 'on') {
      this.geoapifyApi
        .validateAddress(cityName, zipCode, countryName)
        .then(({ data, status }) => {
          const { confidence, confidence_city_level, match_type } = data?.features[0]?.properties?.rank || {}

          this.kibanaApi.reportAddressValidation(
            cityName,
            zipCode,
            countryName,
            {
              confidence,
              confidenceCityLevel: confidence_city_level,
              matchType: match_type,
            },
            {
              statusCode: status,
              error: !data?.features?.length ? 'No response data' : '',
            }
          )
        })
        .catch(({ response }) => {
          const { error, message, statusCode } = response.data

          this.kibanaApi.reportAddressValidation(
            cityName,
            zipCode,
            countryName,
            {},
            {
              statusCode,
              error,
              message,
            }
          )
        })
    } else {
      this.kibanaApi.reportAddressValidation(cityName, zipCode, countryName, { notValidated: true })
    }

    if (paramsForSubmit.transfer.form === PAYMENT_FORMS.sepa && this.stripeElements) {
      const { error: submitError } = await this.stripeElements.submit()
      if (submitError) {
        handleSepaError(submitError)
        this.hideLoading()
        return
      }

      // Create the ConfirmationToken using the details collected by the Payment Element
      const { error, confirmationToken } = await this.stripeClient.createConfirmationToken({
        elements: this.stripeElements,
        params: {
          payment_method_data: {
            billing_details: {
              name: this.payerFullName,
              email: this.payerEmail,
              phone: this.payerPhone,
              address: {
                city: this.payerCity,
                country: this.payerCountry,
                postal_code: this.payerZip,
                state: this.payerState,
                line1: this.payerStreet,
                line2: this.payerStreetNumber,
              },
            },
          },
        },
      })

      if (error) {
        handleSepaError(error)
        this.hideLoading()
        return
      }

      const token = { confirmationToken: confirmationToken.id }

      paramsForSubmit.transfer = {
        ...paramsForSubmit.transfer,
        ...token,
      }
    }

    const resp = await this.childApi.createOrder(username, { ...paramsForSubmit, orderSessionId })
    const { redirectLink, success, clientSecret, error = '' } = resp || { redirectLink: '', clientSecret: '' }

    if (resp && success) {
      /* Handles P24 case */
      const isP24 = paymethods.form === PAYMENT_FORMS.p24
      const isIdeal = paymethods.form === PAYMENT_FORMS.ideal
      const isKlarna = paymethods.form === PAYMENT_FORMS.klarna
      const isCard = paymethods.form === PAYMENT_FORMS.card
      const isStripeSofort =
        paymethods.form === PAYMENT_FORMS.sofort &&
        (sofortProvider === PAYMENT_PROVIDERS.stripe || sofortProvider === PAYMENT_PROVIDERS.elopageConnect)

      this.root.trackingUserEventsStore.track({
        pageType: 'shop_checkout',
        eventType: 'buy_button_success',
        withExperiment: true,
      })

      if (
        paymethods.form === PAYMENT_FORMS.paypal &&
        paypalProvider === TRANSFER_PROVIDERS_BASE.paypalRest &&
        paypalRestV2 &&
        !free
      ) {
        return resp
      }

      if (
        (isP24 || isCard || isStripeSofort || isDigitalPayment || isIdeal || isKlarna) &&
        (this.stripeClient || this.stripeDigitalMethod) &&
        clientSecret
      ) {
        const fullName = this.payerFullName
        const email = this.payerEmail
        const countryCode = get(this.paramsForSubmit, 'payer.userProfileAttributes.countryCode', '')

        if (isP24) {
          handleP24Transaction(this.stripeClient, this.stripeP24, redirectLink, clientSecret, {
            fullName,
            email,
            tosShowAndAccepted: false,
          }).then((result) => {
            const { paymentIntent } = result
            if (paymentIntent) {
              this.handleRedirect(redirectLink)
            }
          })
        }

        if (paymethods.form === PAYMENT_FORMS.klarna) {
          handleKlarnaTransaction(this.stripeClient, this.stripeElements, clientSecret, redirectLink).then(
            ({ error }) => {
              if (error) {
                notify('error', error?.message || error)
                this.hideLoading()
              }
            }
          )
        }

        if (isIdeal) {
          handleIdealTransaction(this.stripeClient, this.stripeIdeal, redirectLink, clientSecret, {
            fullName,
            email,
          }).then((result) => {
            const { paymentIntent } = result

            if (paymentIntent) {
              this.handleRedirect(redirectLink)
            }
          })
        }

        if (isStripeSofort) {
          const billingDetails =
            prefs?.sofortSepa && (stripeSofortSepa || elopageConnectSofortSepa)
              ? {
                  billing_details: {
                    name: fullName,
                    email,
                  },
                }
              : {}
          handleSofortTransaction(this.stripeClient, redirectLink, clientSecret, {
            countryCode,
            billingDetails,
          })
        }

        if (isCard) {
          this.hideLoading()
          this.stripeAuthentificationLoadingToggle(true)
          handleStripeCardTransaction(this.stripeClient, { stripeCard: this.stripeCard }, clientSecret, {
            fullName,
            email,
            tosShowAndAccepted: false,
          }).then((result) => {
            handleTransactionResponse(
              result,
              () => this.handleRedirect(redirectLink),
              () => this.stripeAuthentificationLoadingToggle(false),
              this.stripeClient,
              clientSecret
            )
          })
        }

        if (isDigitalPayment) {
          handleStripeDigitalTransaction(
            this.stripeDigitalMethod || this.stripeClient,
            this.stripePaymentRequest,
            clientSecret,
            () => this.handleRedirect(redirectLink),
            this.hideLoading()
          )
        }
      } else {
        this.handleRedirect(redirectLink)
      }
    } else {
      this.hideLoading()

      this.root.trackingUserEventsStore.track({
        pageType: 'shop_checkout',
        eventType: 'buy_button_error',
        withExperiment: true,
      })

      if (
        paymethods.form === PAYMENT_FORMS.card &&
        (cardProvider === PAYMENT_PROVIDERS.stripe || cardProvider === PAYMENT_PROVIDERS.elopageConnect)
      ) {
        this.shouldResetIntent = true
      }

      if (showDigitalPaymentModal) {
        this.stripePaymentRequest.on('cancel', () => notify('error', error))
      }
    }
  }

  @action handleIntentReset = () => {
    this.shouldResetIntent = false
  }

  @computed get isValidForSubmit() {
    const { free, paymethods, terms } = this.store
    const { token: invoiceToken } = this.invoice || {}

    return (
      (this.isPayerFormsFullyValid || invoiceToken) &&
      (free || paymethods.valid) &&
      (free || !(this.product.cancellationTerm || {}).checkboxVisible || terms) &&
      (this.attendeesAreValid || invoiceToken) &&
      (this.optInAreValid || invoiceToken)
    )
  }

  @computed get paymentAllowed() {
    const { paymethods, free } = this.store
    const { form } = paymethods
    const { cardProvider } = this.root.sellerStore.item

    if (
      form === PAYMENT_FORMS.card &&
      (cardProvider === PAYMENT_PROVIDERS.stripe || cardProvider === PAYMENT_PROVIDERS.elopageConnect) &&
      !free
    ) {
      return this.stripeCardValid
    }

    return true
  }

  @computed get payerEmail() {
    return get(this.paramsForSubmit, 'payer.userAttributes.email', '')
  }

  @computed get payerFullName() {
    return `${get(this.paramsForSubmit, 'payer.userProfileAttributes.firstName', '')} ${get(
      this.paramsForSubmit,
      'payer.userProfileAttributes.lastName',
      ''
    )}`
  }

  @computed get payerCountry() {
    return get(this.paramsForSubmit, 'payer.userProfileAttributes.countryCode', '')
  }

  @computed get payerPhone() {
    return get(this.paramsForSubmit, 'payer.userProfileAttributes.phone', '')
  }

  @computed get payerStreet() {
    return get(this.paramsForSubmit, 'payer.userProfileAttributes.street', '')
  }

  @computed get payerStreetNumber() {
    return get(this.paramsForSubmit, 'payer.userProfileAttributes.streetNumber', '')
  }

  @computed get payerCity() {
    return get(this.paramsForSubmit, 'payer.userProfileAttributes.city', '')
  }

  @computed get payerZip() {
    return get(this.paramsForSubmit, 'payer.userProfileAttributes.zip', '')
  }

  @computed get payerState() {
    return get(this.paramsForSubmit, 'payer.userProfileAttributes.state', '')
  }

  @action
  getAttendeesFor = (ticketDateId) => {
    const { attendees } = this.store
    return attendees.active
      ? Object.values(attendees.list)
          .filter((at) => at.ticketDateId === ticketDateId)
          .map((ta) => ({
            ticketDateId,
            salutation: ta.salutation,
            firstName: ta.firstName,
            lastName: ta.lastName,
            email: ta.email,
            countryCode: ta.country.code,
            state: ta.state,
            zip: ta.zip,
            street: ta.street,
            streetNumber: ta.streetNumber,
            city: ta.city,
            phone: ta.phone,
          }))
      : []
  }

  @computed get prepareOptInsForSubmit() {
    const { optIn: { optInQuestions = [] } = {} } = this.product

    if (optInQuestions.length) {
      return optInQuestions.map(({ id, fieldType }) => {
        const optIn = this.store.optInsData[id]
        const isOpenQuestion = fieldType === CUSTOM_TEMPLATE_TYPES.openQuestion
        const isSelectBox = fieldType === CUSTOM_TEMPLATE_TYPES.selectBox

        return {
          optInQuestionId: id,
          answer: isOpenQuestion || isSelectBox ? optIn : undefined,
          checked: !isOpenQuestion && !isSelectBox ? !!optIn : undefined,
        }
      })
    }

    return []
  }

  @computed get pricingPlansToBeGrouped() {
    const isGroupingEnabled = !!get(this.root.productSettingsStore, 'item.prefs.bumpsGrouping', false)
    const isBump = get(this.root.productSettingsStore, 'item.prefs.upsellType', '') === UPSELLS_TYPES.bump
    if (isGroupingEnabled && isBump) {
      const mainProductPlans = this.getProductPlansToShow()
      const bumpsPlans =
        this.prepareSellablesForSubmit.map((sellable) => {
          if (sellable?.productUpsellId) {
            const {
              prefs,
              pricingPlans: productUpsellPricingPlans = [],
              upsell,
              ticket,
            } = this.root.productUpsellsStore.list.find((item) => item.id === sellable.productUpsellId) || []
            const productPlans =
              upsell.form === PRODUCT_TYPE_IDS.eventTickets ? ticket?.pricingPlans : upsell?.pricingPlans
            return prefs.ownPlans ? productUpsellPricingPlans : productPlans
          }
          return mainProductPlans
        }) || []
      return bumpsPlans
    }
    return []
  }

  @computed get showGroupedPricingPlans() {
    const isBump = get(this.root.productSettingsStore, 'item.prefs.upsellType', '') === UPSELLS_TYPES.bump
    return isBump && !!isPossibleToGroupPlanLists(this.pricingPlansToBeGrouped) && !!this.groupedPricingPlans.length
  }

  @computed get groupedPricingPlans() {
    return groupPlanLists(this.pricingPlansToBeGrouped) || []
  }

  @action handleGroupingPlanSelect = (planIndex) => {
    const selectedPlanId = this.groupedPricingPlans[planIndex]?.id || ''
    this.setSelectedGroupedPricingPlan(selectedPlanId)
    if (this.showGroupedPricingPlans) {
      this.prepareSellablesForSubmit.forEach(async (sellable) => {
        if (sellable.productUpsellId) {
          const { list = [] } = this.root.productUpsellsStore
          const productUpsell = list.find((item) => item.id === sellable.productUpsellId)
          const { prefs, pricingPlans: upsellPricingPlans = [], upsell, ticket } = productUpsell || {}
          const productPlans =
            upsell.form === PRODUCT_TYPE_IDS.eventTickets ? ticket?.pricingPlans : upsell?.pricingPlans
          const upsellSelectedPlan = prefs.ownPlans ? upsellPricingPlans?.[planIndex] : productPlans?.[planIndex]
          const upsellIndex =
            this.getSelectedUpsellIndex(this.store.upsell, sellable.productUpsellId, 'productUpsellId') || 0
          await this.updateUpsellData(
            {
              ...this.store.upsell[upsellIndex],
              planId: upsellSelectedPlan?.id,
            },
            upsellIndex,
            false,
            true
          )
        } else {
          const mainProductSelectedPlanId = this.getProductPlansToShow()?.[planIndex]?.id
          await this.handleProductSelectPlan(mainProductSelectedPlanId, true)
        }
      })

      this.recalculatePrices()
    }
  }

  resetSelectedGroupredPricingPlan = () => {
    const isBump = get(this.root.productSettingsStore, 'item.prefs.upsellType', '') === UPSELLS_TYPES.bump
    if (isBump) {
      if (this.showGroupedPricingPlans) {
        const pricingPlansContainSelectedPlan = this.groupedPricingPlans.includes(
          (item) => String(item.id) === String(this.selectedGroupedPricingPlan)
        )
        if (!this.selectedGroupedPricingPlan || !pricingPlansContainSelectedPlan) {
          this.handleGroupingPlanSelect(0)
        }
      } else {
        this.setSelectedGroupedPricingPlan('')
      }
    }
  }

  /* eslint-disable no-else-return */
  @computed get prepareSellablesForSubmit() {
    const { product, upsell } = this.store
    const hasAnyUpsells = upsell && upsell.length && upsell.some((upsellItem) => upsellItem.id)

    if (this.invoice?.token) {
      return []
    } else if (hasAnyUpsells) {
      let sellableItemsList: SellableItemList[] = this.selectedUpsell.map((selectedUpsellItem) => {
        const upsellItem =
          upsell.find((upsellItem) => upsellItem.productUpsellId === selectedUpsellItem.productUpsellId) || {}
        const hasAnyAddons = upsellItem.productAddons && upsellItem.productAddons.length
        const isUpsellEvent = selectedUpsellItem.form === PRODUCT_TYPE_IDS.eventTickets

        const getAttributesWithoutAddons = () => {
          if (isUpsellEvent) {
            const { ticketId, ticketDateId, ticketsCount } = selectedUpsellItem

            return [
              {
                productId: selectedUpsellItem.id,
                ticketId,
                ticketDateId,
                ticketsCount,
                ticketAttendeesAttributes: this.getAttendeesFor(ticketDateId),
                sellableType: 'Ticket',
              },
            ]
          }

          return [
            {
              productId: selectedUpsellItem.id,
              ticketsCount: 1,
              ticketAttendeesAttributes: [],
            },
          ]
        }

        const sellableItemsAttributesList = hasAnyAddons
          ? upsellItem.productAddons.map((addon) => ({
              productId: addon.addonId,
              ...(addon.addonType === PRODUCT_TYPE_IDS.eventTickets
                ? {
                    ticketId: addon.ticketId,
                    ticketDateId: addon.ticketDateId,
                    ticketsCount: addon.ticketsCount,
                    ticketAttendeesAttributes: this.getAttendeesFor(addon.ticketDateId),
                    sellableType: 'Ticket',
                  }
                : {}),
            }))
          : getAttributesWithoutAddons()

        return {
          productId: selectedUpsellItem.id,
          sellableId: isUpsellEvent ? selectedUpsellItem.ticketId : selectedUpsellItem.id,
          sellableType: isUpsellEvent ? 'Ticket' : 'Product',
          pricingPlanId: upsellItem.planId,
          priceCount: isUpsellEvent ? selectedUpsellItem?.ticketsCount : 1,
          productUpsellId: selectedUpsellItem?.productUpsellId,
          sellableItemsAttributes: sellableItemsAttributesList,
        }
      })

      if (this.isMainProductSelected) {
        if (this.product.form === PRODUCT_TYPE_IDS.eventTickets) {
          sellableItemsList = sellableItemsList.concat(this.generateTicketsSellable())
        } else {
          sellableItemsList.push({
            productId: this.product.id,
            sellableId: this.product.id,
            sellableType: 'Product',
            pricingPlanId: product.planId,
            priceCount: product.count,
            sellableItemsAttributes: [
              {
                productId: this.product.id,
                ticketsCount: product.count,
                ticketAttendeesAttributes: [],
              },
            ],
          })
        }
      }

      return sellableItemsList
    } else if (this.product.form === PRODUCT_TYPE_IDS.eventTickets) {
      return this.generateTicketsSellable()
    } else {
      return [
        {
          productId: this.product.id,
          sellableId: this.product.id,
          sellableType: 'Product',
          pricingPlanId: product.planId,
          priceCount: product.count,
          sellableItemsAttributes: [
            {
              productId: this.product.id,
              ticketsCount: product.count,
              ticketAttendeesAttributes: [],
            },
          ],
        },
      ]
    }
  }
  /* eslint-enable no-else-return */

  generateTicketsSellable = () => {
    const { event } = this.store

    return Object.keys(event.tickets).map((ticketId) => {
      const ticket = event.tickets[ticketId]

      return {
        productId: this.product.id,
        sellableId: ticketId,
        sellableType: 'Ticket',
        pricingPlanId: ticket.pricingPlanId,
        priceCount: Object.values(ticket.ticketDates).reduce((sum, td) => sum + td.count, 0),
        sellableItemsAttributes: Object.keys(ticket.ticketDates).map((ticketDateId) => ({
          productId: this.product.id,
          ticketId,
          ticketDateId,
          ticketsCount: ticket.ticketDates[ticketDateId].count,
          ticketAttendeesAttributes: this.getAttendeesFor(ticketDateId),
        })),
      }
    })
  }

  sortUpsellByPosition = (a, b) => {
    const keyA = a.position
    const keyB = b.position

    if (keyA < keyB) {
      return -1
    }

    if (keyA > keyB) {
      return 1
    }

    return 0
  }

  getProductParams = (store, hasAnyUpsells, type = 'id') => {
    const isBump = get(this.root.productSettingsStore, 'item.prefs.upsellType', '') === UPSELLS_TYPES.bump
    const isBundle = get(this.root.productSettingsStore, 'item.prefs.upsellType', '') === UPSELLS_TYPES.bundle
    const isMainProductSettings = get(this.root.productSettingsStore, 'item.prefs.mainProductSettings', false)

    /** We need select fist item sorted by position */
    const sortedUpsell = [...this.selectedUpsell].sort(this.sortUpsellByPosition)

    if (hasAnyUpsells) {
      if (isBundle) {
        return sortedUpsell[0][type]
      }

      if (isBump) {
        if (isMainProductSettings || this.isMainProductSelected) {
          return this.product[type]
        }
        return sortedUpsell[0][type]
      }
    }

    return this.product[type]
  }

  @computed get paramsForSubmit() {
    const { formType, giftComment, showGift, userData } = this.payerForms
    const {
      item: { cardProvider },
    } = this.root.sellerStore
    const store = toJS(this.store) // deserialize data
    const { upsell } = store
    const { ...payerData } = userData.payer
    const ownerData = showGift ? userData.owner : ({} as Payer)
    const affiliateToken = LocalStorageService.getItem('affiliateToken') || this.store.affiliateToken

    const isSessionStorageAvailable = isWindowEnv() && window.navigator.cookieEnabled && window.sessionStorage
    const campaignIdFromShop = isSessionStorageAvailable ? window.sessionStorage.getItem('campaignId') : ''
    const campaignIdFromProduct = store.props?.campaignId || ''
    const campaignId = campaignIdFromProduct ? campaignIdFromProduct : campaignIdFromShop

    const creditCard = cardProvider === PAYMENT_PROVIDERS.lemonWay ? { num: CARD_NUMBER } : store.paymethods.creditCard

    const hasAnyUpsells = upsell && upsell.length && upsell.some((upsellItem) => upsellItem.id)
    const mainProductId = this.getProductParams(store, hasAnyUpsells, 'id')
    const mainProductSlug = this.getProductParams(store, hasAnyUpsells, 'slug')
    const config = convertFieldsObject(this.root.themeStore.ppTemplate.buyerInformation[this.payerFromType] || {})

    const fieldsToValidate = Object.keys(config).filter(
      (field) => config[field] && config[field].visible && config[field].visible === 'on'
    )
    const isIncludedOnPage = (fieldName) => !!fieldsToValidate.includes(fieldName)

    // TODO: SSR - campaignId and affiliateToken available on client and it provocates additional request to '/v1/shop/vakhula/orders/build' on mount client
    // @TODO finish with data
    return {
      affiliateToken: affiliateToken == 'null' || affiliateToken === null ? '' : affiliateToken,
      giftComment,
      productId: mainProductId,
      productSlug: mainProductSlug,
      pageProductId: this.product.id,
      addId1: store.props?.addId1,
      addId2: store.props?.addId2,
      campaignId,
      invoice: this.invoice?.token,
      coupon: store.coupon?.applied ? store.coupon.code : null,
      sellables: this.prepareSellablesForSubmit,
      optInAnswers: this.prepareOptInsForSubmit,
      vatData: this.payerFormTypeForVat === 'business' && payerData.vatNo ? this.vatData : {},
      funnelId: store.props?.funnelId,
      transfer: {
        form: store.free ? 'free' : store.paymethods.form,
        iban: store.paymethods.iban,
        creditCard,
      },
      payer: {
        formType,
        formTypeForVat: this.payerFormTypeForVat,
        attendeeEmail: this.store.attendees.attendeeEmail,
        userProfileAttributes: {
          countryCode: payerData.country.code,
          state: payerData.state || '',
          phone: isIncludedOnPage('phone') ? payerData.phone : '',
          company: isIncludedOnPage('company') ? payerData.company : '',
          city: isIncludedOnPage('city') ? payerData.city : '',
          street: isIncludedOnPage('street') ? payerData.street : '',
          streetNumber: isIncludedOnPage('streetNumber') ? payerData.streetNumber : '',
          zip: isIncludedOnPage('zip') ? payerData.zip : '',
          vatNo: this.payerFormTypeForVat === 'business' ? payerData.vatNo : null,
          salutation: isIncludedOnPage('salutation') ? payerData.salutation : '',
          firstName: (payerData.firstName || '').trim(),
          lastName: (payerData.lastName || '').trim(),
          additionalAddress: isIncludedOnPage('additionalAddress') ? payerData.additionalAddress : '',
        },
        userAttributes: {
          timeZoneName: CURRENT_TIMEZONE,
          email: payerData.email,
          emailConfirmation: payerData.emailConfirmation,
        },
        trackingUuid: getCookies(userSessionCookieKey),
      },
      owner: showGift
        ? {
            userProfileAttributes: {
              countryCode: ownerData.country?.code,
              state: ownerData.state,
              phone: ownerData.phone,
              company: ownerData.company,
              city: ownerData.city,
              street: ownerData.street,
              streetNumber: ownerData.streetNumber,
              zip: ownerData.zip,
              vatNo: ownerData.vatNo,
              salutation: ownerData.salutation,
              firstName: ownerData.firstName,
              lastName: ownerData.lastName,
              additionalAddress: ownerData.additionalAddress,
            },
            userAttributes: {
              timeZoneName: CURRENT_TIMEZONE,
              email: ownerData.email,
            },
          }
        : null,
      cancelTerms: store.terms,
      trackingLog: {
        customerToken: getCookies('customer_token'),
      },
    }
  }

  @computed get currency() {
    return this.root.currenciesStore.getKey(this.store.activePlan?.currencyId) || DEFAULT_CURRENCY
  }

  totalPrice(orderRateIndex = 0) {
    const { orderRates = [] } = this.buildedOrder || {}
    const { orderRatePrices = [] } = orderRates[orderRateIndex] || {}

    const total = orderRatePrices.reduce(
      (result, { data }) => {
        const {
          cfgs: { mustPayVat },
          discount,
          fees,
          rate,
        } = data || {}
        const priceKey = mustPayVat ? VAT_TYPE.gross : VAT_TYPE.net

        const getSum = (currentValue, value) =>
          value ? Number((currentValue + value[priceKey] * value.count).toFixed(2)) : currentValue

        const rateSum = getSum(result.rateSum, rate)
        const feesSum = getSum(result.feesSum, fees)
        const discountSum = getSum(result.discountSum, discount)

        return {
          rateSum,
          feesSum,
          discountSum,
        }
      },
      {
        rateSum: 0,
        feesSum: 0,
        discountSum: 0,
      }
    )

    return total.rateSum + total.feesSum + total.discountSum
  }

  @computed get orderRatesTotal() {
    const { orderRates = [] } = this.buildedOrder || {}
    let sum = 0
    for (let i = 0; i < orderRates.length; i++) {
      sum += this.totalPrice(i)
    }
    return sum
  }

  getOrderRateHigherThan(value: number) {
    const { orderRates = [] } = this.buildedOrder || {}
    let hasHigherThan = this.totalPrice(0)
    for (let i = 1; i < orderRates.length; i++) {
      if (this.totalPrice(i) >= value) {
        hasHigherThan = this.totalPrice(i)
        break
      }
    }
    return hasHigherThan
  }

  @computed get isDigitalPayment() {
    const { paymethods: { form } = {} } = this.store
    return form === PAYMENT_FORMS.applePay || form === PAYMENT_FORMS.googlePay
  }
  ////////////////
  // END SUBMIT //
  ////////////////

  //////////////////////////////
  // STRIPE PAYMENTS HANDLERS //
  //////////////////////////////
  checkDigitalMethodsAvailability = async (requestData?: {
    country: string
    currency: string
    total: {
      label: string
      amount: number
    }
  }) => {
    const stripeClient = this.stripeDigitalMethod || this.stripeClient
    let resp

    const currencyId = this.root.currenciesStore.getKey(this.store.activePlan?.currencyId) || DEFAULT_CURRENCY

    if (stripeClient) {
      const paymentRequestData = {
        country: this.getPayerCountryCode,
        currency: currencyId,
        total: {
          label: this.product.label || '',
          /** price is being multiplied by 100 because it should be represented in cents */
          amount: convertPriceForStripe(this.totalPrice()), // should be a positive int number
        },
        ...(requestData || {}),
        requestPayerName: false,
        requestPayerEmail: false,
      }
      try {
        const paymentRequest = stripeClient.paymentRequest(paymentRequestData)
        this.setStripePaymentRequest(paymentRequest)

        paymentRequest.on('cancel', this.hideLoading)

        resp = await paymentRequest.canMakePayment()

        this.setStripeDigitalPaymentMethods(resp)

        if (this.isDigitalPayment && !(resp.applePay || resp.googlePay)) {
          this.resetPaymentMethod()
        }
      } catch (error) {
        // console.error(error)

        if (this.isDigitalPayment) {
          this.resetPaymentMethod()
        }
        this.setStripeDigitalPaymentMethods({})
      }
    }

    return resp
  }

  //////////////////////////////////
  // END STRIPE PAYMENTS HANDLERS //
  //////////////////////////////////

  listenEmbedMessages = () => {
    if (isWindowEnv() && !this.isListeningEmbedMessage) {
      const embedType = getSearchParams().embed_type

      if (!embedType) {
        return
      }

      listenEmbedMessage(this.setEmbedOrigin)
      this.isListeningEmbedMessage = true
    }
  }

  getSepaStripeElementPayload = (): StripeElementsParams => {
    const { sepaProvider, elopageConnectAccountId } = this.root.sellerStore.item

    return {
      mode: 'setup',
      paymentMethodCreation: 'manual',
      paymentMethodTypes: ['sepa_debit'],
      currency: this.currency,
      setupFutureUsage: 'off_session',
      ...(sepaProvider === PAYMENT_PROVIDERS.elopageConnect ? { onBehalfOf: elopageConnectAccountId } : {}),
    }
  }

  getKlarnaStripeElementPayload = (): StripeElementsParams => {
    const { klarnaProvider, elopageConnectAccountId } = this.root.sellerStore.item

    return {
      mode: 'payment',
      paymentMethodTypes: [PAYMENT_FORMS.klarna],
      currency: this.currency,
      amount: convertPriceForStripe(this.totalPrice()),
      ...(klarnaProvider === PAYMENT_PROVIDERS.elopageConnect ? { onBehalfOf: elopageConnectAccountId } : {}),
    }
  }

  getKlarnaFormPayload = (): KlarnaFormPayload => ({
    email: this.payerEmail,
    name: this.payerFullName,
    phone: this.payerPhone,
    address: {
      country: this.payerCountry,
    },
  })

  @computed get pricingPlansForUserTracking() {
    const pricingPlans = this.getProductPlansToShow()
    const selectedPricingPlanId = this.store.product.planId
    const selectedPricingPlanIndex = pricingPlans.findIndex((plan) => plan.id === selectedPricingPlanId)
    const selectedPricingPlan = pricingPlans[selectedPricingPlanIndex]

    return {
      pricingPlans: pricingPlans.map((plan) => ({
        id: plan.id,
        type: plan.form,
        price: plan.prefs.price || plan.prefs.firstAmount || 0,
      })),
      selectedPricingPlanId,
      selectedPricingPlanPosition: selectedPricingPlanIndex + 1,
      selectedPricingPlanType: selectedPricingPlan.form,
      selectedPricingPlanPrice: selectedPricingPlan.prefs.price || selectedPricingPlan.prefs.firstAmount || 0,
      pricingPlansCount: pricingPlans.length,
    }
  }

  @override
  hydrate(key, data) {
    if (key === 'buildedOrder') {
      this.buildedOrder = data
    } else if (key === 'buildedOrderLoading') {
      this.buildedOrderLoading = data
    } else if (key === 'buyBtnDisabled') {
      this.buyBtnDisabled = data
    } else if (key === 'invoice') {
      this.invoice = data
    } else if (key === 'isPurchaseLoading') {
      this.isPurchaseLoading = data
    } else if (key === 'loading') {
      this.loading = data
    } else if (key === 'payerForms') {
      this.payerForms = data
    } else if (key === 'product') {
      this.product = data
    } else if (key === 'selectedGroupedPricingPlan') {
      this.selectedGroupedPricingPlan = data
    } else if (key === 'store') {
      this.store = data
    } else if (key === 'stripeCardValid') {
      this.stripeCardValid = data
    } else if (key === 'stripeDigitalPaymentMethods') {
      this.stripeDigitalPaymentMethods = data
    } else if (key === 'vatData') {
      this.vatData = data
    } else if (key === 'selectedUpsell') {
      this.selectedUpsell = data
    } else if (key === 'buildedOrderPms') {
      this.buildedOrderPms = data
    } else if (key === 'shouldResetIntent') {
      this.shouldResetIntent = data
    }

    this.listenEmbedMessages()
  }
}
