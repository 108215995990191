import React, { Component } from 'react'
import withStyles, { ThemeProvider } from 'react-jss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { HEADER, SOCIALS, SOCIAL_ICONS, SHOP_SOCIAL_ICONS } from '@elo-kit/constants/contentPage.constants'

import { CUSTOM_CLASS_NAME_OPTION, HEADER_CLASS_NAMES } from '@elo-kit/constants/customCss.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'

import { THEME_TYPE, ELOPAGE_CABINETS } from '@elo-kit/constants/general.constants'
import { createId } from '@elo-kit/utils/general.utils'
import { internalURL } from '@elo-kit/utils/contentBlock.utils'
import { unmountDomElement } from '@elo-kit/utils/dom.utils'
import { getThemeProps } from '@elo-kit/utils/block.utils'
import { profile } from 'utils/profileHelper.utils'
import { get } from 'utils/lodash.utils'

import headerStyles from 'shared/components/content-page/preview/blocks/header/Header.styles'
import { BurgerMenuIcon } from '@elo-kit/components/icons/BurgerMenuIcon'
import { useI18n } from '@elo-kit/components/i18n/i18n'

import HeaderLangSwitcher from '../../components/header-lang-switcher'
import HeaderSharingOptions from '../../components/header-sharing-options'

/**
 * Header Container
 */
export const HeaderContainer = withStyles(headerStyles)((props) => {
  const {
    classes = {},
    logo,
    children,
    previewMode,
    shopLink,
    customClassName,
    username,
    seller,
    view,
    themeType,
    locales,
    block: { stickyHeader } = {},
    pageBuilderPreview,
  } = props
  const isShopView = view === ELOPAGE_CABINETS.shop
  const isCabinetView = view === ELOPAGE_CABINETS.cabinet
  const headerClasses = classNames(HEADER_CLASS_NAMES.containerClassName, customClassName, classes.headerContainer, {
    [classes.headerContainerPreview]: previewMode,
    [classes.headerContainerSticky]: (!isCabinetView || pageBuilderPreview) && stickyHeader,
  })

  const isShopTheme = isShopView || themeType === THEME_TYPE.shop

  const logoPlaceholder = isShopTheme ? (
    <>
      {seller?.avatarUrl && (
        <img src={seller?.avatarUrl} alt='avatar' onError={(event) => unmountDomElement(event.target)} />
      )}
      <span>{username || seller?.username || ''}</span>
    </>
  ) : (
    locales.logo
  )

  const linkPlaceholderClasses = isShopTheme ? classes.logoUsername : classes.logoPreview

  return (
    <div className={headerClasses}>
      <div
        className={classNames(
          HEADER_CLASS_NAMES.logoContainerClassName,
          previewMode ? classes.headerPreview : classes.header
        )}
      >
        <a
          className={classNames(
            HEADER_CLASS_NAMES.logoLinkContainerClassName,
            logo ? classes.logo : linkPlaceholderClasses
          )}
          href={!previewMode ? shopLink : undefined}
          {...(previewMode && {
            'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.header.logo,
            'data-highlighter-selector': '',
          })}
        >
          {logo ? <img src={logo} alt={locales.logo} /> : logoPlaceholder}
        </a>
        {children}
      </div>
    </div>
  )
})

/**
 * Header Socials component
 */
export const HeaderSocials = withStyles(headerStyles)(({ classes = {}, url, icon, previewMode }) => (
  <a
    className={classNames(HEADER_CLASS_NAMES.socialItemWrapperContainerClassName, classes.socials)}
    href={!previewMode ? url : undefined}
    target='_blank'
    {...(previewMode && {
      'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.header.websiteAndSocials,
      'data-highlighter-selector': '',
    })}
  >
    <i className={icon} />
  </a>
))

/**
 * Header Menu Item
 */
export const HeaderMenuItem = withStyles(headerStyles)(({
  classes = {},
  url,
  name,
  submenu,
  baseURL,
  themePages,
  previewMode,
  username,
  isLoggedIn,
}) => {
  const isSignInUrl = url?.includes('sign_in')
  const loginUrl = isLoggedIn && username && isSignInUrl ? `/payer/s/${username}` : url

  return (
    <>
      {!submenu ? (
        <a
          href={!previewMode ? loginUrl : undefined}
          className={classNames(HEADER_CLASS_NAMES.menuWrapperContainerClassName, classes.menuItem)}
        >
          {name}
        </a>
      ) : (
        <div className={classNames(HEADER_CLASS_NAMES.subMenuWrapperContainerClassName, classes.menuWithSubmenu)}>
          <button type='button' className={classNames(classes.menuItem)}>
            {name}
          </button>
          <div className={classNames(classes.submenuContainer, 'submenus')}>
            {submenu.map((item) => (
              <a className={classes.submenuItem} href={internalURL(baseURL, item, themePages)} key={item.id}>
                {item.name}
              </a>
            ))}
          </div>
        </div>
      )}
    </>
  )
})

/**
 * My Account component
 */
export const HeaderMyAccount = withStyles(headerStyles)((props) => {
  const { classes = {}, seller, additionalClass, username, locales, previewMode, content, isLoggedIn } = props

  const myAccountClasses = classNames(
    HEADER_CLASS_NAMES.myAccountContainerClassName,
    classes.myAccount,
    additionalClass
  )

  const iconClasses = classNames('fas fa-user', classes.myAccountIcon)
  const isPayerCabinet = profile.profileType === ELOPAGE_CABINETS.payer
  const payerUsername = isPayerCabinet && seller?.username

  const accountUrl = (username || payerUsername) && !isLoggedIn ? `/s/${username || payerUsername}/sign_in` : '/payer'
  const productLink = `/payer/s/${username || payerUsername}`
  const cancellableOrdersLink = '/payer/orders?cancellable=true'
  const cancelLink = isLoggedIn ? cancellableOrdersLink : `/s/${username || payerUsername}/sign_in`
  const showCancelSubscriptionLink = seller?.powerSeller || get(content, 'showCancelSubscription', true)

  return (
    <div
      className={myAccountClasses}
      {...(previewMode && {
        'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.header.myAccount,
        'data-highlighter-selector': '',
      })}
    >
      <i className={iconClasses} />
      <i className='fas fa-sort-down' />
      <div className={classNames(classes.myAccountContainer, 'submenus')}>
        <a href={!previewMode ? accountUrl : undefined}>{locales.myAccount}</a>
        {isPayerCabinet && <a href={productLink}>{locales.myProducts}</a>}
        {showCancelSubscriptionLink && (
          <a
            href={!previewMode ? cancelLink : undefined}
            onClick={() => {
              if (!isLoggedIn && !previewMode) {
                sessionStorage.setItem('referer_link', cancellableOrdersLink)
              }
            }}
          >
            {locales.cancelSubscriptions}
          </a>
        )}
      </div>
    </div>
  )
})

/**
 * Mobile Header
 */
export const MobileHeader = withStyles(headerStyles)((props) => {
  const {
    classes,
    toggleMobileMenu,
    mobileMenuActive,
    menuColor,
    username,
    previewMode,
    showLangSwitcher,
    showMyAccount,
    dataTestId,
    locales,
    seller,
    additionalClass,
    LANGUAGES,
    currentLocale,
    setToKey,
    snakeCaseToCamelCase,
    getCookies,
    setCookies,
    availableLanguages,
  } = props

  const propsToPass = {
    toggleMobileMenu,
    mobileMenuActive,
    menuColor,
    username,
    previewMode,
    showLangSwitcher,
    showMyAccount,
    dataTestId,
    locales,
    seller,
    additionalClass,
    LANGUAGES,
    currentLocale,
    setToKey,
    snakeCaseToCamelCase,
    getCookies,
    setCookies,
    availableLanguages,
  }

  return (
    <div className={classNames(HEADER_CLASS_NAMES.mobileContainerClassName, classes.headerMobileRight)}>
      <div className={classes.profileInfo}>
        {showLangSwitcher && <HeaderLangSwitcher {...propsToPass} previewMode={previewMode} />}
      </div>

      <div data-testid={dataTestId} className={classes.burgerMenu} onClick={toggleMobileMenu}>
        {mobileMenuActive ? <i className='fas fa-times' /> : <BurgerMenuIcon color={menuColor || '#00000021'} />}
      </div>
    </div>
  )
})

/**
 * Desktop Header Component
 */
export const DesktopHeader = withStyles(headerStyles)((props) => {
  const {
    classes = {},
    mobileMenuActive,
    content = {},
    emptyPreview,
    baseURL,
    username,
    previewMode,
    showLangSwitcher,
    showMyAccount,
    themePages,
    view,
    seller,
    locales,
    rootUrl,
    additionalClass,
    LANGUAGES,
    currentLocale,
    setToKey,
    snakeCaseToCamelCase,
    getCookies,
    setCookies,
    availableLanguages,
    blockId,
    isLoggedIn,
    hideHeaderLinks,
  } = props

  const propsToPass = {
    mobileMenuActive,
    content,
    emptyPreview,
    baseURL,
    username,
    previewMode,
    showLangSwitcher,
    showMyAccount,
    themePages,
    view,
    seller,
    locales,
    rootUrl,
    additionalClass,
    LANGUAGES,
    currentLocale,
    setToKey,
    snakeCaseToCamelCase,
    getCookies,
    setCookies,
    availableLanguages,
    blockId,
    isLoggedIn,
  }

  const menuMobileClasses = classNames(classes.menuMobileActive, classes.menu)
  const headerMobileActiveClasses = classNames(
    HEADER_CLASS_NAMES.mobileActiveClassName,
    classes.headerRight,
    classes.headerMobileMenu,
    classes.headerMobileMenuActive
  )
  const headerClasses = classNames(
    HEADER_CLASS_NAMES.desktopActiveClassName,
    classes.headerRight,
    classes.headerMobileMenu
  )
  const socialsContainerMobileClasses = classNames(
    HEADER_CLASS_NAMES.mobileSocialsClassName,
    classes.socialsContainerMobileActive,
    classes.socialsContainer
  )

  const socialsDesktopClasses = classNames(HEADER_CLASS_NAMES.desktopSocialsClassName, classes.socialsContainer)
  const menuList = content[HEADER.menuList] && !hideHeaderLinks ? content[HEADER.menuList] : []
  const showSharingOptions = !!content[HEADER.sharingOptions] && (view === ELOPAGE_CABINETS.shop || previewMode)
  const socialIcons = view === ELOPAGE_CABINETS.shop ? SHOP_SOCIAL_ICONS : SOCIAL_ICONS

  return (
    <div className={mobileMenuActive ? headerMobileActiveClasses : headerClasses}>
      <div
        className={mobileMenuActive ? menuMobileClasses : classes.menu}
        {...(previewMode && {
          'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.header.menuItems,
          'data-highlighter-selector': '.cs-header-menu, .cs-header-sub-menu',
        })}
      >
        {showMyAccount && mobileMenuActive && (
          <HeaderMyAccount {...propsToPass} username={username} additionalClass='mobile-my-account' />
        )}
        {menuList &&
          menuList.map((menuItem) => (
            <HeaderMenuItem
              {...propsToPass}
              key={menuItem.id}
              name={menuItem.name}
              url={internalURL(baseURL, menuItem, themePages)}
              submenu={menuItem.submenu}
              baseURL={baseURL}
              themePages={themePages}
            />
          ))}
      </div>

      {showMyAccount && (
        <HeaderMyAccount
          {...propsToPass}
          username={username}
          additionalClass={mobileMenuActive && classes.myAccountMobileActive}
        />
      )}

      {!mobileMenuActive && showLangSwitcher && <HeaderLangSwitcher {...propsToPass} previewMode={previewMode} />}

      <div className={mobileMenuActive ? socialsContainerMobileClasses : socialsDesktopClasses}>
        {SOCIALS.map((social, i) =>
          !emptyPreview
            ? content[social] && (
                <HeaderSocials
                  key={createId(`socials-${i}`)}
                  url={content[social]}
                  icon={socialIcons[social]}
                  previewMode={previewMode}
                />
              )
            : i < 2 && <HeaderSocials url='/' icon='' key={createId(`socials-${i}`)} previewMode={previewMode} />
        )}
        {showSharingOptions && (
          <HeaderSharingOptions seller={seller} locales={locales} rootUrl={rootUrl} previewMode={previewMode} />
        )}
      </div>
    </div>
  )
})

/**
 * Header block for page builder
 */
export class HeaderBlock extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mobileMenuActive: false,
    }
  }

  toggleMobileMenu = () => {
    const { mobileMenuActive } = this.state
    this.setState(() => ({
      mobileMenuActive: !mobileMenuActive,
    }))
  }

  render() {
    const {
      toJS,
      block,
      block: { content = {}, id: blockId },
      previewMode,
      baseURL,
      username,
      shopLink,
      domainName,
      view,
      seller,
      themeType,
      themePages,
      availableLanguages,
      LANGUAGES,
      currentLocale,
      setToKey,
      snakeCaseToCamelCase,
      getCookies,
      setCookies,
      rootUrl,
      dataTestId,
      pageBuilderPreview,
      locales = {
        logo: this.props.I18n.t('react.shared.logo'),
        myAccount: this.props.I18n.t('react.shared.my_account'),
        myProducts: this.props.I18n.t('react.shared.my_products'),
        cancelSubscriptions: this.props.I18n.t('react.shared.cancel_subscriptions'),
      },
      isLoggedIn,
      hideHeaderLinks,
    } = this.props

    const { mobileMenuActive } = this.state
    const emptyPreview = content && Object.keys(toJS(content)).length < 2
    const isShop = view === ELOPAGE_CABINETS.shop
    const isShopTheme = themeType === THEME_TYPE.shop
    const showLangSwitcher = (isShop || isShopTheme) && content[HEADER.languageSwitcher]
    const myAccountSwitcher = content[HEADER.myAccountSwitcher] === false ? content[HEADER.myAccountSwitcher] : true
    const showMyAccount = isShop || isShopTheme ? myAccountSwitcher : true
    const defaultProps = {
      dataTestId,
      mobileMenuActive,
      username,
      previewMode,
      showLangSwitcher,
      showSharingOption: content[HEADER.showSharingOption],
      showMyAccount,
      seller,
      themeType,
      view,
      themePages,
      availableLanguages,
      locales,
      LANGUAGES,
      currentLocale,
      setToKey,
      snakeCaseToCamelCase,
      getCookies,
      setCookies,
      rootUrl,
      blockId,
      isLoggedIn,
      hideHeaderLinks,
    }

    return (
      <ThemeProvider theme={getThemeProps(block)}>
        <HeaderContainer
          block={block.content}
          customClassName={content[CUSTOM_CLASS_NAME_OPTION]}
          logo={getThemeProps(block).backgroundImage}
          {...defaultProps}
          {...{
            previewMode,
            username,
            domainName,
            shopLink,
            pageBuilderPreview,
          }}
        >
          <MobileHeader
            {...defaultProps}
            toggleMobileMenu={this.toggleMobileMenu}
            menuColor={content[HEADER.menuColor]}
          />

          <DesktopHeader
            {...defaultProps}
            content={content}
            emptyPreview={emptyPreview}
            baseURL={baseURL || shopLink}
          />
        </HeaderContainer>
      </ThemeProvider>
    )
  }
}

HeaderBlock.propTypes = {
  /** ID for testing */
  dataTestId: PropTypes.string,
  /** View from contentPageStore */
  view: PropTypes.string,
  /** Theme Type from contentPageStore */
  themeType: PropTypes.string,
  /** Seller meta from contentPageStore */
  seller: PropTypes.object,
  /** Theme Pages from contentPageStore */
  themePages: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /** Shop username */
  username: PropTypes.string,
  /** Shop Link */
  shopLink: PropTypes.string,
  /** Domain Name */
  domainName: PropTypes.string,
  /** Base URL */
  baseURL: PropTypes.string,
  /** Page builder block object */
  block: PropTypes.object,
  /** Preview mode flag */
  previewMode: PropTypes.bool,
  /** Map of locales */
  locales: PropTypes.object,
  /** toJS func from mobX */
  toJS: PropTypes.func,
  /** Languages map */
  LANGUAGES: PropTypes.object,
  /** currentLocale from I18n Object */
  currentLocale: PropTypes.func,
  /** setToKey from Hashovka Object */
  setToKey: PropTypes.func,
  /** Snake case to camel case helper */
  snakeCaseToCamelCase: PropTypes.func,
  /** Function to get cookies */
  getCookies: PropTypes.func,
  /** Function to set cookies */
  setCookies: PropTypes.func,
  /** Available Languages from contentPageStore */
  availableLanguages: PropTypes.array,
  /** React JSS styles */
  classes: PropTypes.object,
  /** Root URL */
  rootUrl: PropTypes.string,
}

HeaderBlock.defaultProps = {
  dataTestId: '',
  availableLanguages: [],
  getCookies: () => {},
  setCookies: () => {},
  currentLocale: () => {},
  setToKey: () => {},
  snakeCaseToCamelCase: (val) => val,
  classes: {},
  LANGUAGES: {},
  themePages: [],
  toJS: (val) => val,
  block: {},
  previewMode: false,
}

export const Header = (props) => {
  const I18n = useI18n()

  return <HeaderBlock I18n={I18n} {...props} />
}

export default Header
