import React, { Fragment, useEffect, useState, useRef } from 'react'

import EloModal from 'ui/EloModal'
import { LoadingMask } from '@elo-kit/components/loading-mask/LoadingMask'

import { notify } from 'libs/common/notify'

import {
  injectStripeScript,
  createStripeClient,
  createStripeElements,
  createStripeP24,
  createStripeIdeal,
  isStripeInjected,
  handleP24Transaction,
  handleIdealTransaction,
  handleSofortTransaction,
  handleStripeCardTransaction,
  createStripeKlarna,
  handleKlarnaTransaction,
  createStripeCard,
} from '@elo-kit/utils/stripe.utils'
import { handleStripeDigitalTransaction } from 'utils/stripe.utils'
import { snakeCaseToCamelCase } from '@elo-kit/utils/nameStyle.utils'
import { getPubKey } from 'utils/paymentSettingShared.utils'

import { KLARNA_ROOT_ID, PAYMENT_FORMS, STRIPE_ELEMENT_ROOT_ID } from 'constants/paymentSettingShared.constants'
import { StripeClient, StripeElement } from 'shop/types/stripe'
import { useShopStores } from 'shop/hooks/use-store'

import AmEx from 'images/payment_methods/AmEx.svg'
import Card from './assets/paymethods/StripeCard.svg'

import './_autopay-handler-modal.scss'

interface AutopayHandlerModalProps {
  isOpen: boolean
  cancel?: boolean
  toggle: () => void
  paymentForm: string
  stripePaymentReques?: StripeElement
  stripePaymentRequest?: any
  checkDigitalMethodsAvailability?: () => void
  setStripeClient?: (client: StripeClient) => void
  orderProvider?: string
  providers: Record<string, string>
  paymentInfo?: {
    redirectLink?: string
    clientSecret?: string
    payerFullName?: string
    payerCountryCode?: string
    payerEmail?: string
  }
  getBillingDetails?: () => {
    billing_details?: {
      name: string
      email: string
    }
  }
  submit?: (client: StripeClient, element: StripeElement) => void
  title: string
  message: string
  stripePubKey: string
  elopageConnectPubKey: string
}

export const AutopayHandlerModal: React.FC<AutopayHandlerModalProps> = ({
  isOpen = false,
  cancel = true,
  toggle,
  paymentForm,
  stripePaymentRequest,
  checkDigitalMethodsAvailability,
  setStripeClient,
  orderProvider,
  providers,
  paymentInfo: { redirectLink, clientSecret, payerFullName, payerCountryCode, payerEmail } = {},
  getBillingDetails,
  submit,
  title = I18n.t('react.shop.change_pricing_plan.with_payment.title'),
  message = I18n.t('react.shop.change_pricing_plan.with_payment.description'),
  ...props
}) => {
  const { experimentsStore } = useShopStores()
  const showMessage =
    message &&
    paymentForm !== PAYMENT_FORMS.applePay &&
    paymentForm !== PAYMENT_FORMS.googlePay &&
    paymentForm !== PAYMENT_FORMS.klarna

  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false)
  const [stripeElementLoading, setStripeElementLoading] = useState<boolean>(false)
  const [stripeElementError, setStripeElementError] = useState<string>('')
  const [stripeCardLoading, setStripeCardLoading] = useState<boolean>(false)

  const stripeClient = useRef(null)
  const stripeElements = useRef(null)
  const stripeElement = useRef(null)

  const stripeCard = useRef(null)
  const stripeCardError = useRef(null)

  const newPaymentMethodUIExperiment = experimentsStore.useExperiment('show_new_payment_methods_ui')
  const isNewPaymentMethodsUI = newPaymentMethodUIExperiment.get('isNewPaymentMethodUI', false)

  useEffect(() => {
    switch (paymentForm) {
      case PAYMENT_FORMS.sofort:
      case PAYMENT_FORMS.card:
      case PAYMENT_FORMS.applePay:
      case PAYMENT_FORMS.googlePay:
      case PAYMENT_FORMS.p24:
      case PAYMENT_FORMS.ideal:
      case PAYMENT_FORMS.klarna: {
        setIsSubmitDisabled(true)
        if (!isStripeInjected()) {
          injectStripeScript(handleStripeOnLoad)
        } else {
          handleStripeOnLoad()
        }
        break
      }

      default:
        break
    }
  }, [])

  const handleStripeOnLoad = async () => {
    try {
      setStripeElementLoading(true)
      const isKlarna = paymentForm === PAYMENT_FORMS.klarna
      const provider = orderProvider
        ? `${snakeCaseToCamelCase(orderProvider)}PubKey`
        : getPubKey(paymentForm, providers)
      const pubKey = props[provider]

      if (pubKey) {
        stripeClient.current = await createStripeClient(pubKey)

        if (isKlarna) {
          stripeElements.current = createStripeElements(stripeClient.current, { clientSecret })
        } else {
          stripeElements.current = createStripeElements(stripeClient.current)
        }

        setStripeClient && setStripeClient(stripeClient.current)

        if (paymentForm === PAYMENT_FORMS.p24) {
          stripeElement.current = createStripeP24(stripeElements.current, isNewPaymentMethodsUI)
        }

        if (paymentForm === PAYMENT_FORMS.ideal) {
          stripeElement.current = createStripeIdeal(stripeElements.current, isNewPaymentMethodsUI)
        }

        if (isKlarna) {
          stripeElement.current = createStripeKlarna(stripeElements.current, {
            name: payerFullName,
            email: payerEmail,
            phone: 'phone',
            address: {
              country: payerCountryCode,
            },
          })
        }

        if (stripeElement.current) {
          stripeElement.current.mount(isKlarna ? `#${KLARNA_ROOT_ID}` : `#${STRIPE_ELEMENT_ROOT_ID}`)

          stripeElement.current.on('ready', () => {
            setStripeElementLoading(false)
          })

          stripeElement.current.on('change', ({ complete, error }) => {
            if (error) {
              setStripeElementError(error.message)
            } else {
              setStripeElementError('')

              if (complete) {
                setIsSubmitDisabled(false)
              }
            }
          })

          stripeElement.current.on('loaderror', ({ error: { message } }) => {
            if (message) {
              setStripeElementError(message)
            }
          })
        }

        if (paymentForm === PAYMENT_FORMS.card) {
          // Create an instance of the card Element.
          stripeCard.current = createStripeCard(stripeElements.current, isNewPaymentMethodsUI)

          // Add an instance of the card Element into the `card-element` <div>.
          stripeCard.current.mount('#card-element')

          // Handle real-time validation errors from the card Element.
          stripeCardError.current = document.getElementById('card-errors')

          stripeCard.current.on('ready', () => {
            stripeCardError.current.classList.remove('lmask')
          })

          stripeCard.current.addEventListener('change', ({ complete, error }) => {
            if (error) {
              stripeCardError.current.textContent = error.message
              setIsSubmitDisabled(false)
            } else {
              stripeCardError.current.textContent = ''
              if (complete) {
                setIsSubmitDisabled(false)
              }
            }
          })
        }

        if (paymentForm === PAYMENT_FORMS.applePay || paymentForm === PAYMENT_FORMS.googlePay) {
          checkDigitalMethodsAvailability && checkDigitalMethodsAvailability()
          setIsSubmitDisabled(false)
        }
      }
    } catch (e) {}
  }

  const defaultSubmit = async () => {
    const billingDetails = {
      fullName: payerFullName,
      email: payerEmail,
    }

    switch (paymentForm) {
      case PAYMENT_FORMS.card: {
        setIsSubmitDisabled(true)
        setStripeCardLoading(true)
        handleStripeCardTransaction(
          stripeClient.current,
          { stripeCard: stripeCard.current },
          clientSecret,
          billingDetails
        ).then((result) => {
          const { error, paymentIntent } = result
          if (paymentIntent) {
            window.location.href = redirectLink
          } else {
            error.message && notify('error', error.message)
            stripeCard.current.clear()
            setStripeCardLoading(false)
          }
        })
        break
      }

      case PAYMENT_FORMS.sofort: {
        handleSofortTransaction(stripeClient.current, redirectLink, clientSecret, {
          countryCode: payerCountryCode,
          billingDetails: getBillingDetails(),
        })
        break
      }

      case PAYMENT_FORMS.p24: {
        handleP24Transaction(stripeClient.current, stripeClient.current, redirectLink, clientSecret, {
          ...billingDetails,
          tosShowAndAccepted: false,
        })
        break
      }

      case PAYMENT_FORMS.ideal: {
        handleIdealTransaction(stripeClient.current, stripeClient.current, redirectLink, clientSecret, {
          ...billingDetails,
        })
        break
      }

      case PAYMENT_FORMS.klarna: {
        const { error } = await stripeElements.current.submit()
        if (error) {
          notify('error', error)
          break
        }

        handleKlarnaTransaction(stripeClient.current, stripeElements.current, clientSecret, redirectLink).then(
          ({ error }) => {
            if (error) {
              notify('error', error?.message || error)
            }
          }
        )
        break
      }

      case PAYMENT_FORMS.googlePay:
      case PAYMENT_FORMS.applePay: {
        stripePaymentRequest.show()
        handleStripeDigitalTransaction(
          stripeClient.current,
          stripePaymentRequest,
          clientSecret,
          () => (window.location.href = redirectLink),
          undefined // optional parameter
        )
        break
      }
      default:
        break
    }
  }

  const handleSubmit = () => {
    if (submit) {
      if (stripePaymentRequest) {
        submit(stripeClient.current, stripePaymentRequest)
      } else {
        submit(stripeClient.current, stripeElement.current)
      }
    } else {
      defaultSubmit()
    }
  }

  const renderPaymentSystem = () => {
    switch (paymentForm) {
      case PAYMENT_FORMS.card: {
        return (
          <div className='card-widget-container'>
            <div className='card-header'>
              <img className={`${PAYMENT_FORMS.card}-img`} alt={PAYMENT_FORMS.cardAmEx} src={AmEx} />
              <img className={`${PAYMENT_FORMS.card}-img`} alt={PAYMENT_FORMS.card} src={Card} />
            </div>
            <div className='card-widget'>
              <div id='card-element' />
              <div id='card-errors' className='lmask' />
            </div>
          </div>
        )
      }
      case PAYMENT_FORMS.applePay:
      case PAYMENT_FORMS.googlePay: {
        return (
          <div className='confirmation-modal__message'>
            {I18n.t('react.shared.payment_methods.digital_payment_confirmation_required')}
          </div>
        )
      }
      case PAYMENT_FORMS.klarna: {
        return (
          <Fragment>
            <div id={KLARNA_ROOT_ID} />
            {stripeElementLoading ? <div className='lmask' /> : null}
            {stripeElementError ? <div>{stripeElementError}</div> : null}
          </Fragment>
        )
      }

      case PAYMENT_FORMS.ideal:
      case PAYMENT_FORMS.p24:
      default: {
        return (
          <Fragment>
            <div id={STRIPE_ELEMENT_ROOT_ID} />
            {stripeElementLoading ? <div className='lmask' /> : null}
            {stripeElementError ? <div>{stripeElementError}</div> : null}
          </Fragment>
        )
      }
    }
  }

  return (
    <EloModal
      cancel={cancel}
      isOpen={isOpen}
      title={title}
      toggle={toggle}
      submit={handleSubmit}
      submitDisabled={isSubmitDisabled}
      submitButtonChild={I18n.t('react.shared.button.pay')}
      onlySubmit
    >
      <Fragment>
        {showMessage && (
          <Fragment>
            <div className='confirmation-modal__message'>{message}</div>
            <br />
          </Fragment>
        )}
        {renderPaymentSystem()}
        {stripeCardLoading && <LoadingMask />}
      </Fragment>
    </EloModal>
  )
}
