import {
  FACEBOOK_PIXEL_OPTION_KEY,
  GOOGLE_ANALYTICS_OPTION_KEY,
  GTM_OPTION_KEY,
  TRACIFY_OPTION_KEY,
} from 'constants/options.constants'
import { TRACKING_EVENT_FORMS, TRACKING_CODE_TYPES } from 'constants/trackingCodes.constants'
import { isProduction } from 'utils/env.utils'

export const isAppForCodeActivated = (form, isAppActive = () => {}) => {
  switch (form) {
    case TRACKING_CODE_TYPES.googleAnalytics:
    case TRACKING_CODE_TYPES.googleAnalytics4:
      return isAppActive(GOOGLE_ANALYTICS_OPTION_KEY)
    case TRACKING_CODE_TYPES.googleTagManager:
      return isAppActive(GTM_OPTION_KEY)
    case TRACKING_CODE_TYPES.facebookPixel:
      return isAppActive(FACEBOOK_PIXEL_OPTION_KEY)
    case TRACKING_CODE_TYPES.tracify:
      return isAppActive(TRACIFY_OPTION_KEY)
    default:
      return true
  }
}

export const getDefaultCodes = (form) => {
  const data = [
    {
      key: Math.random(),
      form: TRACKING_EVENT_FORMS.shop,
      name: I18n.t('react.cabinet.tracking_codes.names.view_shop'),
    },
    {
      key: Math.random(),
      form: TRACKING_EVENT_FORMS.shopSubPage,
      name: I18n.t('react.cabinet.tracking_codes.names.view_shop_sub_page'),
    },
    {
      key: Math.random(),
      form: TRACKING_EVENT_FORMS.product,
      name: I18n.t('react.cabinet.tracking_codes.names.view_products'),
    },
    {
      key: Math.random(),
      form: TRACKING_EVENT_FORMS.payment,
      name: I18n.t('react.cabinet.tracking_codes.names.view_payment_page'),
    },
    {
      key: Math.random(),
      form: TRACKING_EVENT_FORMS.success,
      name: I18n.t('react.cabinet.tracking_codes.names.standard_purchase'),
    },
  ]

  const defaultCodes = {
    [TRACKING_CODE_TYPES.facebookPixel]: [
      "fbq('track', 'PageView');",
      "fbq('track', 'PageView');",
      `fbq('track', 'PageView');
fbq('track', 'ViewContent', {
  content_ids: [%{product_id}],
  content_name: %{product_name},
  content_type: 'product',
  currency: %{currency},
  value: %{product_price}
});`,
      `fbq('track', 'PageView');
  fbq('track', 'AddToCart', {
  content_ids: [%{product_id}],
  content_name: %{product_name},
  content_type: 'product',
  upsell_ids: %{upsell_ids},
  upsell_names: %{upsell_names},
  currency: %{currency},
  value: '0.01'
});`,
      `fbq('track', 'PageView');
fbq('track', 'Purchase', {
  content_ids: %{product_ids},
  content_names: %{product_names},
  content_type: 'product',
  currency: %{currency},
  value: %{revenue},
  transaction_id: %{transaction_id},
  payment_type: %{order_form},
  funnel_id: %{funnel_id},
  funnel_name: %{funnel_name},
  funnel_page_id: %{funnel_page_id},
  funnel_page_name: %{funnel_page_name},
  upsell_id: %{upsell_id},
  upsell_name: %{upsell_name}
})`,
    ],
    [TRACKING_CODE_TYPES.googleTagManager]: [
      "dataLayer.push({'event': 'pageView'});",
      "dataLayer.push({'event': 'pageView'});",
      "dataLayer.push({'event': 'pageView'});\ndataLayer.push({'event': 'viewContent', 'productId': %{product_id}, 'productName': %{product_name}});",
      `dataLayer.push({'event': 'pageView'});\n
       dataLayer.push({
        'event': 'addToCart',
        'productId': %{product_id},
        'productName': %{product_name},
        'upsellIds': %{upsell_ids},
        'upsellNames': %{upsell_names},
        'currency' : %{currency},
      });`,
      `dataLayer.push({'event': 'pageView'});\n
       dataLayer.push({
        'event': 'purchase',
        'productIds': %{product_ids},
        'revenue': %{revenue},
        transactionId: %{transaction_id},
        orderId: %{order_id},
        orderForm: %{order_form},
        periodType: %{period_type},
        funnelId: %{funnel_id},
        funnelName: %{funnel_name},
        funnelPageId: %{funnel_page_id},
        funnelPageName: %{funnel_page_name},
        upsellId: %{upsell_id},
        upsellName: %{upsell_name},
        currency: %{currency}
      });`,
    ],
    [TRACKING_CODE_TYPES.googleAnalytics]: [
      `ga('send', 'event', {
  eventCategory: 'Shop - elopage',
  eventAction: 'viewShop',
  nonInteraction: true,
});`,
      `ga('send', 'event', {
  eventCategory: 'Shop sub page - elopage',
  eventAction: 'viewShopSubPage',
  nonInteraction: true,
});`,
      `ga('send', 'event', {
  eventCategory: 'Shop - elopage',
  eventAction: 'viewProduct',
  eventLabel: %{product_id} + ' - ' + %{product_name},
  nonInteraction: true,
});`,
      `ga('send', 'event', {
  eventCategory: 'Shop - elopage',
  eventAction: 'addToCart',
  eventLabel: %{product_id} + ' - ' + %{product_name},
  nonInteraction: true,
});`,
      `ga('send', 'event', {
  eventCategory: 'Shop - elopage',
  eventAction: 'purchase',
  eventLabel: %{product_ids} + ' - ' + %{product_names},
  eventValue: %{revenue},
  nonInteraction: true,
});`,
    ],
    [TRACKING_CODE_TYPES.googleAnalytics4]: [
      `gtag('event', 'viewShop', {
        'event_category' : 'Shop - elopage',
        'non_interaction' : 'true',
      });`,
      `gtag('event', 'viewShopSubPage', {
        'event_category' : 'Shop sub page - elopage',
        'non_interaction' : 'true',
      });`,
      `gtag('event', 'viewProduct', {
        'event_category' : 'Shop - elopage',
        'event_label' : %{product_id} + ' - ' + %{product_name},
        'non_interaction' : 'true',
      });`,
      `gtag('event', 'addToCart', {
        'event_category' : 'Shop - elopage',
        'event_label' : %{product_id} + ' - ' + %{product_name},
        'currency' : %{currency},
        'non_interaction' : 'true',
      });`,
      `gtag('event', 'purchase', {
        'event_category' : 'Shop - elopage',
        'event_label' : %{product_ids} + ' - ' + %{product_names},
        'value' : %{revenue},
        'non_interaction' : 'true',
        'transaction_id' : %{transaction_id},
        'order_id' : %{order_id},
        'currency' : %{currency},
      });`,
    ],
  }

  data.forEach((item, index) => (item.code = defaultCodes[form][index]))

  const funnelDefaultData = {
    key: Math.random(),
    form: TRACKING_EVENT_FORMS.funnel,
    name: I18n.t('react.cabinet.tracking_codes.names.view_upsell'),
  }

  if (form === TRACKING_CODE_TYPES.facebookPixel) {
    funnelDefaultData.code = `fbq('track', 'ViewContent', {
      content_ids: %{product_ids},
      content_names: %{product_names},
      content_type: 'product',
      funnel_id: %{funnel_id},
      funnel_name: %{funnel_name},
      funnel_page_id: %{funnel_page_id},
      funnel_page_name: %{funnel_page_name}
    });`
    data.push(funnelDefaultData)
  }

  if (form === TRACKING_CODE_TYPES.googleAnalytics) {
    funnelDefaultData.code = `ga('send', 'event', {
      eventCategory: 'Shop - elopage',
      eventAction: 'upsell' + ' - ' + %{funnel_id} + ' - ' + %{funnel_name},
      nonInteraction: true,
    });`
    data.push(funnelDefaultData)
  }

  if (form === TRACKING_CODE_TYPES.googleAnalytics4) {
    funnelDefaultData.code = `gtag('event', "upsell - %{funnel_id} - %{funnel_name}", {
      'event_category' : 'Shop - elopage',
      'non_interaction' : 'true',
    });`
    data.push(funnelDefaultData)
  }

  return data
}

export const setGoogleConsentMode = (grantedCategories, isDefault) => {
  const googleContentTypes = isProduction()
    ? {
        ad_personalization: 1,
        ad_storage: 1,
        ad_user_data: 1,
        analytics_storage: 2,
        functionality_storage: 3,
        personalization_storage: 3,
        security_storage: 4,
      }
    : {
        ad_personalization: 5,
        ad_storage: 5,
        ad_user_data: 5,
        analytics_storage: 2,
        functionality_storage: 1,
        personalization_storage: 1,
        security_storage: 3,
      }

  window.dataLayer = window.dataLayer || []

  function gtag() {
    window.dataLayer.push(arguments)
  }

  gtag('consent', isDefault ? 'default' : 'update', {
    ad_storage: grantedCategories[googleContentTypes['ad_storage']] ? 'granted' : 'denied',
    ad_user_data: grantedCategories[googleContentTypes['ad_user_data']] ? 'granted' : 'denied',
    ad_personalization: grantedCategories[googleContentTypes['ad_personalization']] ? 'granted' : 'denied',
    analytics_storage: grantedCategories[googleContentTypes['analytics_storage']] ? 'granted' : 'denied',
    functionality_storage: grantedCategories[googleContentTypes['functionality_storage']] ? 'granted' : 'denied',
    personalization_storage: grantedCategories[googleContentTypes['personalization_storage']] ? 'granted' : 'denied',
    security_storage: grantedCategories[googleContentTypes['security_storage']] ? 'granted' : 'denied',
    wait_for_update: 2000,
  })

  gtag('set', 'ads_data_redaction', true)
  gtag('set', 'url_passthrough', true)
}
