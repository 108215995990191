export const APPLE_STORE_URL = 'https://www.apps.apple.com'
export const GOOGLE_PLAY_URL = 'https://www.play.google.com/store/apps'

export const MAX_APP_NAME_LENGTH = 30

export const MOBILE_APP_FILES = {
  googleCertificate: 'google_certificate',
  iosPushNotification: 'ios_push_notification',
  androidPushNotification: 'android_push_notification',
  apnsKey: 'apns_key',
  apiKey: 'api_key',
  appleManagementKeyPub: 'apple_management_key_pub',
  appleManagementKey: 'apple_management_key',
  androidKeystoreCertificate: 'android_keystore_certificate',
  firebaseKey: 'firebase_key',
}

export const IAP_PRICING = {
  free: 'free',
  paid: 'paid',
}

export const NOTIFICATION_PREVIEW_KEYS = {
  ios: 'ios',
  android: 'android',
}

export const NOTIFICATION_PREVIEW_TABS = [
  {
    key: NOTIFICATION_PREVIEW_KEYS.ios,
    label: I18n.t('react.shared.mobile_app.notifications.ios_preview'),
  },
  {
    key: NOTIFICATION_PREVIEW_KEYS.android,
    label: I18n.t('react.shared.mobile_app.notifications.android_preview'),
  },
]

export const MOBILE_APP_NOTIFICATION_REDIRECTION_KEYS = {
  regular: 'regular',
  lesson: 'lesson',
}

export const MOBILE_APP_NOTIFICATION_TITLE_LENGTH = 40
export const MOBILE_APP_NOTIFICATION_CONTENT_LENGTH = 178

export const EXPAND_PRODUCTS_IN_APP_TAB = ['pricing_plans', 'covers', 'tickets']

export const DEVICE_TYPE = {
  phone: 'Phone',
  tablet: 'Tablet',
}

export const DEVICE_OS = {
  iOS: 'IOS',
  android: 'Android',
}

const IOS_PHONE_DEVICES = [
  {
    value: 'iphone-se',
    label: 'IPhone SE',
    resolution: '750×1334',
    deviceType: DEVICE_TYPE.phone,
    deviceOS: DEVICE_OS.iOS,
  },
  {
    value: 'iphone-12-mini',
    label: 'IPhone 12 mini',
    resolution: '1080x2340',
    deviceType: DEVICE_TYPE.phone,
    deviceOS: DEVICE_OS.iOS,
  },
  {
    value: 'iphone-13-mini',
    label: 'IPhone 13 mini',
    resolution: '1080x2340',
    deviceType: DEVICE_TYPE.phone,
    deviceOS: DEVICE_OS.iOS,
  },
  {
    value: 'iphone-12',
    label: 'IPhone 12',
    resolution: '1170x2532',
    deviceType: DEVICE_TYPE.phone,
    deviceOS: DEVICE_OS.iOS,
  },
  {
    value: 'iphone-13',
    label: 'IPhone 13',
    resolution: '1170x2532',
    deviceType: DEVICE_TYPE.phone,
    deviceOS: DEVICE_OS.iOS,
  },
  {
    value: 'iphone-12-pro-max',
    label: 'IPhone 12 Pro Max',
    resolution: '1284x2778',
    deviceType: DEVICE_TYPE.phone,
    deviceOS: DEVICE_OS.iOS,
  },
  {
    value: 'iphone-13-pro-max',
    label: 'IPhone 13 Pro Max',
    resolution: '1284x2778',
    deviceType: DEVICE_TYPE.phone,
    deviceOS: DEVICE_OS.iOS,
  },
]

const IOS_TABLET_DEVICES = [
  {
    value: 'ipad',
    label: 'IPad',
    resolution: '1536x2048',
    deviceType: DEVICE_TYPE.tablet,
    deviceOS: DEVICE_OS.iOS,
  },
  {
    value: 'ipad-air-4',
    label: 'IPad Air 4',
    resolution: '1640x2360',
    deviceType: DEVICE_TYPE.tablet,
    deviceOS: DEVICE_OS.iOS,
  },
  {
    value: 'ipad-pad-pro-11inches',
    label: 'IPad Pro',
    resolution: '1668x2388',
    deviceType: DEVICE_TYPE.tablet,
    deviceOS: DEVICE_OS.iOS,
  },
]

const ANDROID_PHONE_DEVICES = [
  {
    value: 'samsung-galaxy-s20',
    label: 'Samsung Galaxy S20',
    resolution: '1440x3200',
    deviceType: DEVICE_TYPE.phone,
    deviceOS: DEVICE_OS.android,
  },
  {
    value: 'oneplus-8-pro',
    label: 'OnePlus 8 Pro',
    resolution: '1440x3168',
    deviceType: DEVICE_TYPE.phone,
    deviceOS: DEVICE_OS.android,
  },
  {
    value: 'sony-xperia-1-ii',
    label: 'Sony Xperia 1 II',
    resolution: '1644x3840',
    deviceType: DEVICE_TYPE.phone,
    deviceOS: DEVICE_OS.android,
  },
  {
    value: 'samsung-galaxy-note20-ultra',
    label: 'Samsung Galaxy Note 20 Ultra',
    resolution: '1440x3088',
    deviceType: DEVICE_TYPE.phone,
    deviceOS: DEVICE_OS.android,
  },
  {
    value: 'samsung-galaxy-a50',
    label: 'Samsung Galaxy A50',
    resolution: '1080x2340',
    deviceType: DEVICE_TYPE.phone,
    deviceOS: DEVICE_OS.android,
  },
  {
    value: 'samsung-galaxy-note20',
    label: 'Samsung Galaxy Note 20',
    resolution: '1080x2400',
    deviceType: DEVICE_TYPE.phone,
    deviceOS: DEVICE_OS.android,
  },
]
const ANDROID_TABLET_DEVICES = [
  {
    value: 'small',
    label: I18n.t('react.cabinet.app_themes.small_android_tablet'),
    resolution: '1200x1920',
    deviceType: DEVICE_TYPE.tablet,
    deviceOS: DEVICE_OS.android,
  },
  {
    value: 'medium',
    label: I18n.t('react.cabinet.app_themes.medium_android_tablet'),
    resolution: '1600x2500',
    deviceType: DEVICE_TYPE.tablet,
    deviceOS: DEVICE_OS.android,
  },
  {
    value: 'large',
    label: I18n.t('react.cabinet.app_themes.large_android_tablet'),
    resolution: '2560x3760',
    deviceType: DEVICE_TYPE.tablet,
    deviceOS: DEVICE_OS.android,
  },
]

export const DEVICES_TABS = [DEVICE_OS.iOS, DEVICE_OS.android]

export const DEVICES = {
  [DEVICE_OS.iOS]: {
    [DEVICE_TYPE.phone]: IOS_PHONE_DEVICES,
    [DEVICE_TYPE.tablet]: IOS_TABLET_DEVICES,
  },
  [DEVICE_OS.android]: {
    [DEVICE_TYPE.phone]: ANDROID_PHONE_DEVICES,
    [DEVICE_TYPE.tablet]: ANDROID_TABLET_DEVICES,
  },
}

export const DEFAULT_DEVICE = IOS_PHONE_DEVICES[4]
export const DEFAULT_DEVICE_TABLET = IOS_TABLET_DEVICES[0]

export const MOBILE_APP_ONBOARDING_COURSE_URL =
  'https://elopage.com/s/elopage-academy/elopage-deine-app-onboarding-guide-de-neu'

export const MOBILE_APP_SETTING_TYPE = {
  appSetting: 'app_settings',
  introScreenSetting: 'intro_screen_settings',
}
