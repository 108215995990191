import React from 'react'

import { FILE_TYPES } from 'constants/files.constants'
import { stripHTMLFromText } from 'utils/froala.utils'
import { getSellerLink } from 'utils/helpersShared.utils'
import {
  getMetaTags,
  getDefaultMetaTagsConfig,
  getThemePageMetaTags,
  getMetaVideoLink,
  DefaultMetaTagsData,
  ThemePageMetaData,
} from 'utils/metaTags.utils'

import { useShopStores } from 'shop/hooks/use-store'

interface Props {
  contentPage?: any
  isPaymentPage?: boolean
}

export const getShopMetaTags = (props?: Props) => {
  const { contentPage, isPaymentPage } = props || {}
  const { sellerStore, productsStore, ordersStore, paymentStore, shopThemeStore } = useShopStores()
  const seller = sellerStore.item

  const showDescription = shopThemeStore.shopTheme.prefs.showDescription
  const descriptionValue = shopThemeStore.shopTheme.prefs.descriptionValue
  const description =
    showDescription && descriptionValue ? stripHTMLFromText(descriptionValue) : I18n.t('react.shared.seo.description')
  let customTags = null

  let metaTagsConfig: DefaultMetaTagsData | ThemePageMetaData = {
    site: seller?.username,
    title: seller?.username,
    description,
    url: getSellerLink(seller),
    image: seller.avatarUrl,
    locale: I18n.locale,
  }

  if (contentPage && contentPage.prefs?.metaTagsEnabled) {
    metaTagsConfig.title = contentPage.prefs.metaTitle
    metaTagsConfig.image = contentPage.prefs.metaImage
    metaTagsConfig.description = contentPage.prefs.metaDescription
    metaTagsConfig = getThemePageMetaTags(metaTagsConfig)
  }

  if (productsStore.product?.id || paymentStore?.product?.id) {
    const { product: productItem } = productsStore
    const { product: paymentProduct } = paymentStore
    const product = (paymentStore?.product?.id ? paymentProduct : productItem) || ({} as any) // TODO: ts revrite with paymentStore

    const image = product.covers?.length ? product.covers[0].cover : seller.avatarUrl
    const description = product.metaDescription || product.shortDescription || product.description
    const videoCovers = product.covers?.filter((cover) => cover.type === FILE_TYPES.video)
    const videoLink = videoCovers && videoCovers[0] ? getMetaVideoLink(videoCovers[0].url) : ''
    const videoTags = videoLink?.length
      ? {
          video: {
            url: videoLink.replace('https', 'http'),
            secure_url: videoLink.includes('https'),
            type: 'application/x-shockwave-flash',
            width: 1290,
            height: 720,
          },
        }
      : undefined

    metaTagsConfig = {
      site: seller.username,
      title: product.metaTitle || `${product.name} - ${seller.username}`,
      description: stripHTMLFromText(description) || I18n.t('react.shared.seo.description'),
      keywords: product.metaTags || `${product.name}, ${seller.username}, ${seller.fullName}`,
      url: `${getSellerLink(seller, product.slug)}${isPaymentPage ? '/payment' : ''}`,
      image,
      locale: I18n.locale,
    }
    customTags = videoTags
  }

  if (!!ordersStore.item?.products?.length) {
    const productsNames = ordersStore.item.products.reduce((namesList, product) => `${namesList}, ${product.name}`, '')
    metaTagsConfig.title = I18n.t('react.shared.seo.shop.thank_you.title', { products: productsNames })
  }

  const isWhitelabel = sellerStore.isAppActive('elopage-badge')
  const metaTags = getMetaTags(getDefaultMetaTagsConfig(metaTagsConfig, customTags), '', isWhitelabel)
  const noFollow =
    shopThemeStore.shopTheme.prefs.noFollow || productsStore.product?.private || !!ordersStore.item?.products?.length
  const faviconUrl =
    shopThemeStore.shopTheme.prefs.showFavicon && shopThemeStore.shopTheme.prefs.faviconUrl
      ? shopThemeStore.shopTheme.prefs.faviconUrl
      : '/favicon.ico'
  return (
    <>
      <link key='favicon' rel='icon' href={faviconUrl} id='pageFavicon' />
      <link rel='apple-touch-icon' href={faviconUrl}></link>
      {metaTags}
      {noFollow ? <meta name='googlebot' content='noindex' /> : null}
      {noFollow ? <meta property='og:robots' content='noindex' /> : null}
      {noFollow ? <meta name='robots' content='noindex, nofollow' /> : null}
      <meta name='theme-color' content='000004' />
    </>
  )
}
