import { isObject } from './validators.utils'

export const formatSelectFieldOptions = (list = []) =>
  list.map((item) => ({
    value: item,
    label: item.name,
  }))

export const formatSelectCustomOptions = (
  list = [],
  valueParam = 'id',
  paramsToShow = ['id', 'name'],
  customFunc = () => {}
) =>
  list.map((item) => {
    const selectOption = {
      value: isObject(valueParam) ? item : item[valueParam],
      label: '',
    }

    const extractFromDeepObject = (param, innerValue = {}) => {
      if (isObject(param)) {
        Object.entries(param).forEach(([key, value]) => {
          if (!isObject(value)) {
            value.forEach((deepKey) => {
              const deepObject = innerValue[key]
              deepObject && (selectOption.label = `${selectOption.label} ${deepObject[deepKey] || ''}`)
            })
          } else extractFromDeepObject(value, innerValue[key])
        })
      } else {
        if (param === 'custom') {
          selectOption.label = `${selectOption.label} ${customFunc(item) || ''}`
        } else {
          selectOption.label = `${selectOption.label} ${item[param] || ''}`
        }
      }
    }

    paramsToShow.forEach((param) => {
      if (isObject(param)) {
        extractFromDeepObject(param, item)
      } else {
        if (param === 'custom') {
          selectOption.label = `${selectOption.label} ${customFunc(item) || ''}`
        } else {
          selectOption.label = `${selectOption.label} ${item[param] || ''}`
        }
      }
    })

    selectOption.label = selectOption.label.trim()
    return selectOption
  })

export const formatIdNameSelectOptions = (list, id, name, node) => {
  if (list && list.length) {
    return list.map((item) => ({
      value: node ? item[node][id] : item[id],
      label: node ? `${item[node][id]}  ${item[node][name]}` : `${item[id]}  ${item[name]}`,
    }))
  }

  return []
}
