export const GOOGLE_ANALYTICS_OPTION_KEY = 'google_analytics'
export const GTM_OPTION_KEY = 'google_tag_manager'
export const FACEBOOK_PIXEL_OPTION_KEY = 'facebook_pixel'
export const COURSE_CUSTOM_CSS_OPTION_KEY = 'course_custom_css'
export const LESSON_QUIZ_OPTION_KEY = 'lesson_quiz'
export const LESSON_COMMENTS_OPTION_KEY = 'lesson_comments'
export const PRODUCT_PASSWORD_OPTION_KEY = 'password'
export const MULTI_CURRENCY_OPTION_KEY = 'multicurrency'
export const VIRTUAL_IBAN_OPTION_KEY = 'virtual_iban'
export const CUSTOMIZATION_OPTION_KEY = 'customization'
export const PRODUCT_SHARING_OPTION_KEY = 'product_sharing'
export const VIMEO_VIDEOS_OPTION_KEY = 'vimeo_videos'
export const CUSTOM_SMTP_OPTION_KEY = 'custom_smtp'
export const DOUBLE_OPT_INS_OPTION_KEY = 'double_opt_ins'
export const PDF_STAMP_OPTION_KEY = 'pdf_stamp'
export const MINIMUM_TERMS_OPTION_KEY = 'minimum_terms'
export const DOMAINS_OPTION_KEY = 'domains'
export const TEAM_MEMEBER_OPTION_KEY = 'team_members'
export const MOBILE_APP_OPTION_KEY = 'mobile_app'
export const COUPONS_OPTION_KEY = 'coupons'
export const ELOPAGE_VIDEOS_OPTION_KEY = 'elopage_videos'
export const AUTO_INVOICE_OPTION_KEY = 'auto_invoice'
export const FUNNELS_OPTION_KEY = 'funnels'
export const LESSON_DRIP_IN_KEY = 'lesson_drip_in'
export const LESSON_PREVIEW_KEY = 'lesson_preview'
export const LESSON_COMMENTS_KEY = 'lesson_comments'
export const COURSE_REFRESH_KEY = 'course_refresh'
export const SHARING_OPTION_KEY = 'sharing'
export const ACCESS_SESSION_LIMIT = 'access_session_limit'
export const LIVE_SHOPPING_KEY = 'live_shopping'
export const COURSE_LESSONS_LIMIT_KEY = 'course_lessons_limit'
export const ELOPAGE_BADGE_KEY = 'elopage-badge'
export const COMMUNITY_KEY = 'community_feature'
export const INVOICE_SHORT_DESCRIPTION_KEY = 'invoice_short_description'
export const HUBSPOT_OPTION_KEY = 'hubspot'
export const PHYSICAL_PRODUCT_KEY = 'physical_products'
export const TRACIFY_OPTION_KEY = 'tracify'
export const SALES_TEAM_OPTION_KEY = 'sales_team'
export const DUNNING_KEY = 'dunning'
export const METABASE_ANALYTICS_3_OPTION_KEYS = {
  business: 'metabase_analytics_business',
  conversion: 'metabase_analytics_conversion',
  funnels: 'metabase_analytics_funnels',
  bumps: 'metabase_analytics_bumps',
  customers: 'metabase_analytics_customers',
  product: 'metabase_analytics_product',
  marketing: 'metabase_analytics_marketing',
  sales: 'metabase_analytics_sales',
  affiliate: 'metabase_analytics_affiliates',
  sales_seller: 'metabase_analytics_sales_seller',
  best_sellers: 'metabase_analysis_best_sellers',
  top_customers: 'metabase_analysis_top_customers',
  campaigns: 'metabase_analysis_campaigns',
  subscriptions: 'metabase_analysis_subscriptions',
}
export const RESELLER_JOURNEY_KEY = 'reseller_journey'
export const CONVERSION_TOOLS_KEY = 'conversion_tools'
export const SERVICE_HUB_KEY = 'service_hub'
export const REDIRECT_TO_EXTRA_PRODUCT = 'redirect_to_extra_product'
export const TEMPLATE_HUB_KEY = 'template_hub'
export const AFFILIATE_MARKETPLACE_KEY = 'affiliate_marketplace'
export const NEW_PRICING_PLAN_CARD_KEY = 'new_pricing_plan_card'

export const BOOK_OPTION_VIEWS = {
  banner: 'banner',
  modal: 'modal',
}

export const KLARNA_KEY = 'klarna'

export const SELLER_IDS_WITH_DASHBOARDS = {
  dirkKreuterId: '68236',
  markusHofmannId: '51508',
}
