export const AUDIO_TYPES = {
  default: 'default',
  custom: 'custom',
}

export const AUDIO_PLAYER_OPTIONS = [0.5, 0.75, 1, 1.25, 1.5, 2]
export const AUDIO_PLAYER_Z_INDEX = 21
export const SUB_MENU_CONTAINER_Z_INDEX = 3 // for overlapping video container
export const HEADER_CONTAINER_Z_INDEX = 3 // for sticky header

export const CATEGORIES_VIEW_TYPE = {
  tabs: 'tabs',
  dropdown: 'dropdown',
}

export const LINK_TYPE = {
  custom: 'custom',
  themePage: 'theme_page',
}

export const BLOCK_MENU_HIGHLIGHT_IDS = {
  audioPlayer: {
    audioView: 'audio_view',
    additionalText: 'additional_text',
    color: 'color',
    image: 'image',
    mainText: 'mainText',
  },
  banner: {
    mainText: 'main_text',
    additionalText: 'additional_text',
    customCta: 'custom_cta',
  },
  button: {
    buttonFunctionality: 'buttonFunctionality',
  },
  file: {
    filesList: 'files_list',
    title: 'title',
    buttonStyle: 'button_style',
  },
  filePdf: {
    downloadButtonPdf: 'download_button_pdf',
    pdfToolbar: 'pdf_toolbar',
    pdfProgressBar: 'pdf_progress_bar',
    blockButtonStyle: 'block_button_style',
  },
  footer: {
    menuItems: 'menu_items',
    submenuStyle: 'submenu_style',
    websiteAndSocials: 'website_and_socials',
  },
  funnel: {
    customCta: 'custom_cta',
    button: 'button',
  },
  header: {
    logo: 'logo',
    menuItems: 'menu_items',
    myAccount: 'my_account',
    sharingOptions: 'sharing_options',
    languageSwitcher: 'language_switcher',
    websiteAndSocials: 'website_and_socials',
  },
  picture: {
    image: 'image',
  },
  product: {
    paymentButton: 'payment_button',
    backButton: 'back_button',
    productTitle: 'product_title',
    oldPrice: 'old_price',
    productPrice: 'product_price',
    currency: 'currency',
    paymentInformation: 'payment_information',
    coursePlan: 'course_plan',
    availableTickets: 'available_tickets',
    overviewAboutFiles: 'overview_about_files',
  },
  popularProducts: {
    productButton: 'product_button',
    priceBlock: 'price_block',
    selectedProducts: 'selected_products',
    productBackground: 'product_background',
    productsTitles: 'products_titles',
    blockSlider: 'block_slider',
    oldPrice: 'old_price',
    currency: 'currency',
    description: 'description',
    search: 'search',
  },
  sellerProducts: {
    categories: 'categories',
    blockOldPrice: 'block_old_price',
    blockPrice: 'block_price',
    blockCurrency: 'block_currency',
    productsView: 'products_view',
    productsTitles: 'products_titles',
    blockProductDescription: 'block_product_description',
    productBackground: 'product_background',
    buyNowButton: 'buy_now_button',
    search: 'search',
  },
  membershipProducts: {
    productsView: 'products_view',
    productTitle: 'product_title',
    productBackground: 'product_background',
    progressbar: 'progressbar',
    description: 'description',
    search: 'search',
    categories: 'categories',
  },
  testimonials: {
    entries: 'entries',
    usernames: 'usernames',
    testimonialsText: 'testimonials_text',
    blockSlider: 'block_slider',
  },
  text: {
    text: 'text',
  },
  conversionTools: {
    countdownIcon: 'icon',
    countdownNumbers: 'numbers',
    countdownText: 'text',
    modalCta: 'custom_cta',
    modalBodyBackground: 'modal_body_background',
    modalMainText: 'main_text',
    modalAdditionalText: 'additional_text',
    progressBarStyles: 'progressbar_styles',
    progressBarDescription: 'progressbar_description',
  },
  contactForm: {
    title: 'contact-form-title',
    description: 'contact-form-description',
    labels: 'contact-form-labels',
    placeholders: 'contact-form-placeholders',
    button: 'contact-form-button',
  },
  affiliate: {
    text: 'text',
    button: 'button',
  },
  thankYouPage: {
    background: 'background',
    header: 'header',
    mainButton: 'main-button',
    secondaryButton: 'secondary-button',
    title: 'title',
    labels: 'labels',
    details: 'details',
    price: 'price',
  },
}
