import type { KibanaApi } from 'shop/api/kibana.api'
import { isProduction } from 'utils/env.utils'
import { isKeySellerAccount } from './key-seller-accounts.utils'

interface WebVitalsMetric {
  id: string
  name: 'TTFB' | 'FCP' | 'LCP' | 'FID' | 'CLS'
  entries: unknown
  navigationType: 'navigate' | 'reload' | 'back_forward' | 'prerender'
  rating: 'good' | 'needs-improvement' | 'poor'
  value: number // in milliseconds
  delta: number // in milliseconds
}

export interface WebVitalsReport extends Partial<WebVitalsMetric> {
  perfType: 'web_vitals'
  path: string
  sellerId: number
}

class WebVitalsReporter {
  private readonly threshold = 0.1 // 10%

  isNeedToReport(sellerId: number, metric: WebVitalsMetric): boolean {
    return isProduction() && this.isNavigateEvent(metric) && isKeySellerAccount(sellerId)
  }

  isInTheThreshold(): boolean {
    // Please note that this is a statistical approach. It does not guarantee that exactly 1% of users will trigger the callback, but over a large number of users, it should average out to about 1%.
    return Math.random() < this.threshold
  }

  isNavigateEvent(metric: WebVitalsMetric): boolean {
    return metric.navigationType === 'navigate'
  }

  getReportBody(metric: WebVitalsMetric, sellerId: number): WebVitalsReport {
    const { name, value, rating, navigationType } = metric
    return {
      perfType: 'web_vitals',
      path: window.location.pathname,
      sellerId,
      name,
      value,
      rating,
      navigationType,
    }
  }

  report({ api, metric, sellerId }: { metric: WebVitalsMetric; sellerId: number; api: KibanaApi }) {
    if (!this.isNeedToReport(sellerId, metric)) {
      return
    }
    api.reportWebVitals(this.getReportBody(metric, sellerId))
  }
}

const webVitalsReporter = new WebVitalsReporter()

export { webVitalsReporter }
