import React from 'react'

import { LOCALES } from '@elo-kit/constants/locales.constants'
import { COUNTRY_CODES } from 'constants/countries.constants'
import { isWindowEnv } from 'utils/env.utils'
import { stripHTMLFromText } from 'utils/froala.utils'
import { getSellerLink } from 'utils/helpers.utils'
import { createId } from '@elo-kit/utils/general.utils'

export interface DefaultMetaTagsData {
  site?: string
  title?: string
  description?: string
  keywords?: string
  url?: string
  image?: string
  locale?: string
  videoCovers?: boolean
  domain?: string
}

interface CustomMetaTagsData {
  video?: {
    url?: string
    secure_url?: boolean
    type?: string
    width?: number
    height?: number
  }
  site_name?: string
}

export const getDefaultMetaTagsConfig = (data: DefaultMetaTagsData, customTags?: CustomMetaTagsData) => {
  const {
    url,
    site = I18n.t('react.shared.seo.site'),
    title = I18n.t('react.shared.seo.title'),
    // TODO: SSR - window.location.origin - unneeded
    image = `${data.domain || !isWindowEnv() ? data.domain : window.location.origin}/public/images/fb_sharing.png`,
    locale = LOCALES.de,
    description = I18n.t('react.shared.seo.description'),
    keywords = I18n.t('react.shared.seo.keywords'),
    videoCovers = false,
  } = data
  const urlLocale = `${url}?locale=${locale}`

  return {
    reverse: true,
    site,
    title,
    description,
    keywords,
    separator: '|',
    publisher: urlLocale,
    fb: {
      app_id: '1512219545743496',
    },
    og: {
      site_name: site,
      url: urlLocale,
      title,
      image,
      description,
      locale: `${locale}_${COUNTRY_CODES[locale]}`,
      type: videoCovers ? 'video' : 'website',
    },
    twitter: {
      card: 'summary_large_image',
      site: '@elopage_pro',
      title,
      description,
      image,
    },
    'dc.title': title,
    'dc.subject': keywords,
    'dc.description': description,
    'dc.identifier': urlLocale,
    ...customTags,
  }
}

export type ThemePageMetaData = {
  title: string
  description: string
  image: string
}

type ThemePageMetaConfig = {
  title?: string
  description?: string
  image?: string
  og: {
    title?: string
    description?: string
    image?: string
  }
  twitter: {
    title?: string
    description?: string
    image?: string
  }
  'dc.title'?: string
  'dc.description'?: string
}

export const getThemePageMetaTags = (data: ThemePageMetaData | DefaultMetaTagsData): ThemePageMetaConfig => {
  const metaData = {
    og: {},
    twitter: {},
  }
  Object.keys(data).forEach((key) => {
    if (data[key]) {
      metaData[key] = data[key]
      metaData.og[key] = data[key]
      metaData.twitter[key] = data[key]

      if (key === 'title') {
        metaData['dc.title'] = data[key]
      }
      if (key === 'description') {
        metaData['dc.description'] = data[key]
      }
    }
  })

  return metaData
}

type MetaTagsMetaData = {
  title?: string
  description?: string
  image?: string
}

export const getMetaTags = (
  metaData: MetaTagsMetaData,
  MetaTagsKey?: string,
  isWhitelabel?: boolean
): React.ReactNode[] => {
  const key = MetaTagsKey || ''
  const metaDataNames = Object.keys(metaData)

  return metaDataNames.map((name, index) => {
    const fullName = key.length ? `${key}:${name}` : name

    if (name === 'title' && !key.length) {
      const title = [metaData[name], isWhitelabel ? null : 'elopage'].filter(Boolean).join(' | ')
      return <title key={createId('title', index)}>{title}</title>
    }

    if (key === 'og' || key === 'fb') {
      return <meta key={createId(fullName, index)} property={fullName} content={`${metaData[name]}`} />
    }

    if (name !== 'og' && name !== 'fb' && name !== 'twitter' && name !== 'video') {
      return <meta key={createId(fullName, index)} name={fullName} content={`${metaData[name]}`} />
    }

    return getMetaTags(metaData[name], fullName)
  })
}

export const getMetaVideoLink = (link: string): string => {
  let videoId = ''
  if (link.includes('youtu')) {
    videoId = link.split('=').pop().split('&').shift().split('#').shift()
    if (link.includes('youtu.be')) {
      videoId = link.split('&').pop().split('/').shift()
    }
    return `https://www.youtube.com/v/${videoId}?version=3&autohide=1&autoplay=1`
  }
  if (link.includes('vimeo')) {
    videoId = link.split('/').pop().split('&').shift()
    return `https://vimeo.com/moogaloop.swf?clip_id=${videoId}&autoplay=1`
  }
}

export const getShopMetaTags = ({ seller, isAppActive, showDescription, descriptionValue }) => {
  const description =
    showDescription && descriptionValue ? stripHTMLFromText(descriptionValue) : I18n.t('react.shared.seo.description')

  const data = {
    site: seller.username,
    title: seller.username,
    description,
    url: getSellerLink(seller),
    image: seller.avatarUrl,
    locale: I18n.locale,
  }

  const isWhitelabel = isAppActive('elopage-badge')

  return getMetaTags(getDefaultMetaTagsConfig(data), '', isWhitelabel)
}
