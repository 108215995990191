export const DEFAULT_RECORD_TYPE = 'TXT'

export const TTL_OPTIONS = [
  {
    value: 300,
    label: I18n.t('react.cabinet.domain_settings.elopage_subdomain.form.minutes_ttl'),
  },
  {
    value: 3600,
    label: I18n.t('react.cabinet.domain_settings.elopage_subdomain.form.hour_ttl'),
  },
  {
    value: 86400,
    label: I18n.t('react.cabinet.domain_settings.elopage_subdomain.form.hours_ttl'),
  },
  {
    value: 604800,
    label: I18n.t('react.cabinet.domain_settings.elopage_subdomain.form.days_ttl'),
  },
]

export const DNS_RECORDS_STATUSES = {
  waiting: 'waiting',
  submitted: 'submitted',
  deleted: 'deleted',
}

export const ELOPAGE_PREFIX = '.myelopage.com'
