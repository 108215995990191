import { action, computed, makeObservable, observable, override, toJS } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { createTrackingCodeApi, TrackingCode, TrackingCodeApi } from 'shop/api/trackingCodes.api'

import { isWindowEnv } from 'utils/env.utils'

import { TRACKING_CODE_TYPES, TRACKING_EVENT_FORMS } from 'constants/trackingCodes.constants'

import { getCookies } from 'libs/common/cookies'

import {
  FACEBOOK_PIXEL_OPTION_KEY,
  GOOGLE_ANALYTICS_OPTION_KEY,
  GTM_OPTION_KEY,
  TRACIFY_OPTION_KEY,
} from 'constants/options.constants'
import { COOKIES_CONSENTS_FEATURE_FLAG, COOKIES_CONSENTS_TYPE } from '@elo-kit/constants/cookiesConsents.constants'
import { areCookiesAllowed, checkKeyInCookiesConsents } from 'utils/cookiesConsents.utils'
import { areObjectsEqual } from 'utils/helpersShared.utils'
import { codeWithVariables, initializeGA, initializeFBQ, generateGA3Tracker } from 'shop/utils/trackingCodes.utils'

export type EventName = 'shop_page' | 'shop_sub_page' | 'product_page' | 'payment_page' | 'success_page' | 'funnel_page'

interface CodeItem {
  pms: {
    username: string
    orderId: number
  }
  list: {
    id: number
    form: string
    code: string
    sellerId: number
  }[]
}

interface ShopSubPageEventData {
  username: string
  consentForm: string
  productId?: number
}

interface ProductPageEventData {
  sellerId: number
  username: string
  consentForm: string
  productId?: number
  productName: string
  productPrice: number
  currency: string
}

interface PaymentPageEventData {
  productId?: number
  sellerId: number
  username: string
  consentForm: string
  productName: string
  upsellIds: number[]
  upsellNames: string[]
  currency: string
}

interface SuccessPageEventData {
  productId?: number
  sellerId: number
  username: string
  consentForm: string
  transactionId: string | number
  orderId: number
  periodType: string
  orderForm: string
  revenue: string | number
  funnelId: number
  funnelName: string
  funnelNodeId: number
  productIds: number[]
  productNames: string[]
  currency: string
}

interface FunnelPageEventData {
  username: string
  consentForm: string
  funnelId: number
  funnelName: string
  funnelPageId: number
  funnelPageName: string
}

type EventData =
  | ShopSubPageEventData
  | ProductPageEventData
  | PaymentPageEventData
  | SuccessPageEventData
  | FunnelPageEventData

export class TrackingCodesStore extends SharedStore<TrackingCode> {
  storeName = 'TrackingCodesStore'
  declare childApi: TrackingCodeApi
  root: ShopRootStore

  @observable codes = {}
  @observable tracifyConfig = {
    csId: '',
    staging: false,
  }
  @observable fires = []
  @observable consentForm = COOKIES_CONSENTS_TYPE.simple
  @observable inited = []
  @observable eventData = {
    eventName: '',
    params: {},
  } as { eventName?: string; params?: EventData }
  @observable tracifyEventShouldBeFired = false
  @observable cookiebotDialogShown = true
  @observable cookiebotInitialized = false
  @observable pageWasTracked = false

  constructor(root: ShopRootStore) {
    super()
    makeObservable(this)
    this.root = root
    this.childApi = createTrackingCodeApi(root.apiClientV2)
  }

  /*
    We have nothing to do with errors caused by user's scripts.
    Let's just ignore them and let user know in console.
  */
  private toSafeUserScript = (script: string) => {
    const errorHandler = () => `console.error('Error in custom user script: ', error)`

    function wrapAsyncScript(script: string) {
      if ((script || '').includes('setTimeout')) {
        return script.replace(
          /setTimeout\((function\(\)\s?\{([\s\S]*?)\}),\s?(\d+)\);/,
          `setTimeout(function() { try { $2 } catch(error) { ${errorHandler()}; } }, $3);`
        )
      }
      return script
    }
    function wrapSyncScript(script: string) {
      return `
        try {
          ${script}
        } catch (error) {
          if (error) {
            ${errorHandler()}
          }
        }
      `
    }

    return wrapSyncScript(wrapAsyncScript(script))
  }

  @computed get trackingCodesLength() {
    return Object.values(this.codes).length
  }

  @action setPageWasTracked = (value) => {
    this.pageWasTracked = value
  }

  validateParams = (params: EventData): EventData =>
    Object.keys(params).reduce((acc, key) => {
      switch (typeof params[key]) {
        case 'string':
          acc = {
            ...acc,
            [key]: params[key].replaceAll("'", ''),
          }
          break
        default: {
          acc = {
            ...acc,
            [key]: params[key],
          }
        }
      }
      return acc
    }, {} as EventData)

  @action setTrackEventData = (eventName?: EventName, params?: EventData) => {
    if (eventName) {
      this.eventData = {
        eventName,
        params: this.validateParams(params),
      }
    } else this.eventData = {}
  }

  @action triggerCookiebotStatus = (initialized) => {
    this.cookiebotInitialized = initialized
  }

  @action triggerCookiebotDialogStatus = (initialized) => {
    this.cookiebotDialogShown = initialized
  }

  @action cookiesAllowedFor = (trackingCode: { form: string; cookiesConsentIds: number[] }) => {
    const {
      isAppActive,
      item: { consentForm: sellerConsentForm, username },
    } = this.root.sellerStore
    const consentForm = this.fires.length ? this.consentForm : sellerConsentForm

    /* In case there is no consent or external service is used - allow all cookies */
    if (consentForm === COOKIES_CONSENTS_TYPE.none || consentForm === COOKIES_CONSENTS_TYPE.external) {
      return true
    }

    const { list: consentsList } = this.root.cookiesConsentsStore
    const { form, cookiesConsentIds = [] } = trackingCode
    const isCustomCodeType = form === TRACKING_CODE_TYPES.custom
    const isCookiesConsentsAppActive = isAppActive(COOKIES_CONSENTS_FEATURE_FLAG)
    const areDefaultCookiesAllowed =
      isCustomCodeType || checkKeyInCookiesConsents(form, consentsList, undefined, username)
    const areCustomCookiesAllowed = isCookiesConsentsAppActive
      ? !cookiesConsentIds.length || areCookiesAllowed(cookiesConsentIds, undefined, username)
      : true

    return areDefaultCookiesAllowed && areCustomCookiesAllowed
  }

  isAppActivated = (code: { form: string }) => {
    const { isAppActive } = this.root.sellerStore

    switch (code?.form) {
      case TRACKING_CODE_TYPES.googleAnalytics:
        return isAppActive(GOOGLE_ANALYTICS_OPTION_KEY)
      case TRACKING_CODE_TYPES.googleTagManager:
        return isAppActive(GTM_OPTION_KEY)
      case TRACKING_CODE_TYPES.facebookPixel:
        return isAppActive(FACEBOOK_PIXEL_OPTION_KEY)
      case TRACKING_CODE_TYPES.tracify:
        return isAppActive(TRACIFY_OPTION_KEY)
      default:
        return true
    }
  }

  allowedToFire = (code) => this.isAppActivated(code) && this.cookiesAllowedFor(code)

  @action async fireEvents() {
    let list = []

    for (let i = 0; i < this.fires.length; i++) {
      const fire = this.fires[i]
      const { username, productId, orderId } = fire.pms
      const key = [username, productId].join('_')

      if (!fire.fired && this.codes[key]) {
        this.fires[i] = {
          ...this.fires[i],
          fired: true,
        }

        list = (this.codes[key] || {}).list || []
        for (const item of list) {
          const isGA4Form = item.form === TRACKING_CODE_TYPES.googleAnalytics4
          // HACK TO USE ONE CONSENT KEY FOR GA3 AND GA4
          const itemToCheck = isGA4Form ? { ...item, form: TRACKING_CODE_TYPES.googleAnalytics } : item
          if (this.allowedToFire(itemToCheck)) {
            const { trackingEvents = [], id, sellerId, form } = item || {}
            for (const event of trackingEvents) {
              if (event.form === fire.name) {
                const toFire = codeWithVariables(event, fire, item)
                const trackingInfo = {
                  name: event.name,
                  form: event.form,
                  code: event.code,
                  customerToken: getCookies('customer_token'),
                }

                await this.root.trackingLogStore.trackingLog({
                  form: 'tracking_event',
                  username,
                  trackingCodeId: id,
                  trackingEventId: event.id,
                  sellerId,
                  trackingCodeForm: form,
                  info: trackingInfo,
                  orderId,
                })

                if (isWindowEnv()) {
                  const userScript = this.toSafeUserScript(toFire)

                  try {
                    eval(userScript)
                  } catch (error) {
                    console.error('Error in custom user script: ', userScript)
                    console.error(error)
                  }
                }
              }
            }
          }
        }

        if (fire.name === TRACKING_EVENT_FORMS.success) {
          await this.root.ordersStore.markPsAsTracked(username, orderId)
        }
      }
    }
  }

  @action trackEvent = async () => {
    const { eventName, params } = this.eventData
    const { list, fetchFullList } = this.root.cookiesConsentsStore

    if (eventName) {
      this.consentForm = params.consentForm
      const fireExist = this.fires.find(({ name, pms }) => name === eventName && areObjectsEqual(pms, params))
      const promises = [this.fetchCodes(params)]

      if (!fireExist) {
        this.fires.push({
          fired: false,
          name: eventName,
          pms: params,
        })
      }

      if (this.consentForm !== COOKIES_CONSENTS_TYPE.none && !(list && list.length)) {
        promises.push(fetchFullList())
      }

      await Promise.all(promises)
      await this.fireEvents()
    }
  }

  @action forceFireTracify = () => {
    this.tracifyEventShouldBeFired = true
  }

  @action cleareTracifyQEvent = () => {
    this.tracifyEventShouldBeFired = false
  }

  @action applyPageViewEvents = (isProductPage: boolean) => {
    const productId = isProductPage ? this.root.productsStore.product.id : ''
    const key = [this.root?.sellerStore?.item.username, productId].join('_')
    const activeCodes = toJS(this.codes[key]) ? [toJS(this.codes[key])] : []

    activeCodes.forEach((codeItem: CodeItem) => {
      ;(codeItem.list || []).forEach((item) => {
        const itemToCheck =
          item.form === TRACKING_CODE_TYPES.googleAnalytics4
            ? { ...item, form: TRACKING_CODE_TYPES.googleAnalytics }
            : item

        if (this.allowedToFire(itemToCheck) && !item['tracifyTracifyCsId']) {
          const { form, code } = item
          switch (form) {
            case TRACKING_CODE_TYPES.googleAnalytics: {
              // @ts-ignore:next-line
              if (window.ga) {
                // @ts-ignore:next-line
                window.ga('set', {
                  page: window.location.pathname + window.location.search,
                  title: document.title,
                })
                // @ts-ignore:next-line
                window.ga(`${generateGA3Tracker(code)}.send`, 'pageview')
              }
              break
            }
            case TRACKING_CODE_TYPES.googleAnalytics4: {
              setTimeout(() => {
                // @ts-ignore:next-line
                if (window.gtag) {
                  // @ts-ignore:next-line
                  window.gtag('event', 'pageview', {
                    send_to: code,
                    page_title: document.title,
                    page_location: window.location.pathname + window.location.search,
                  })
                }
              }, 100)
              break
            }
          }
        }
      })
    })
  }

  @action async applyCodesToPage(isProductPage: boolean) {
    if (!isWindowEnv()) {
      return
    }

    const productId = isProductPage ? this.root.productsStore.product.id : ''
    const key = [this.root?.sellerStore?.item.username, productId].join('_')
    const activeCodes = toJS(this.codes[key]) ? [toJS(this.codes[key])] : []

    for (const codeItem of Object.values(activeCodes as CodeItem[])) {
      for (const item of codeItem.list || []) {
        // HACK TO USE ONE CONSENT KEY FOR GA3 AND GA4
        const itemToCheck =
          item.form === TRACKING_CODE_TYPES.googleAnalytics4
            ? { ...item, form: TRACKING_CODE_TYPES.googleAnalytics }
            : item

        if (this.allowedToFire(itemToCheck) && this.inited.indexOf(item.id) < 0 && !item['tracifyTracifyCsId']) {
          const { id, form, code, sellerId } = item
          const { username, orderId } = codeItem.pms
          const trackingInfo = {
            code,
            customerToken: getCookies('customer_token'),
          }

          this.inited.push(item.id)
          await this.root.trackingLogStore.trackingLog({
            form: 'tracking_code',
            username,
            trackingCodeId: id,
            sellerId,
            trackingCodeForm: form,
            info: trackingInfo,
            orderId,
          })

          switch (form) {
            case TRACKING_CODE_TYPES.facebookPixel: {
              const allFBIvents = codeItem.list.filter((i) => TRACKING_CODE_TYPES.facebookPixel === i.form)
              if (allFBIvents.length > 1) {
                const allFBIventsCodes = allFBIvents.map((i) => i.code)
                await initializeFBQ(allFBIventsCodes)
              } else {
                await initializeFBQ(code)
              }

              break
            }

            case TRACKING_CODE_TYPES.googleTagManager: {
              const TagManager = await import('react-gtm-module')
              TagManager.default.initialize({
                gtmId: code,
              })
              await initializeGA(code, TRACKING_CODE_TYPES.googleAnalytics4)

              break
            }
            case TRACKING_CODE_TYPES.googleAnalytics4:
            case TRACKING_CODE_TYPES.googleAnalytics:
              await initializeGA(code, form)
              window['IS_GA_INITIALIZE'] = true
              break
            default: {
              const scriptRef = document.createElement('script')

              scriptRef.async = true
              scriptRef.innerHTML = this.toSafeUserScript(code)
              document.head.appendChild(scriptRef)
            }
          }
        }
      }
    }
  }

  @action fetchCodes = async (pms: { productId?: number | string; username: string }) => {
    const { productId, username } = pms
    const key = [username, productId].join('_')
    if (!this.codes[key]) {
      const { data } = await this.childApi.fetchCodes(productId, username)
      const codesData = data?.map(async (item) => {
        const trackingEvents = await this.root.trackingEventsStore.fetchEvents(username, item?.id)

        return {
          ...item,
          trackingEvents: trackingEvents,
        }
      })

      this.codes[key] = {
        pms,
        list: await Promise.all(codesData || []),
      }

      const tracifyConfig = await this.root.tracifyStore.fetchTracifyItem(username)

      if (tracifyConfig?.id) {
        this.tracifyConfig = {
          csId: tracifyConfig.tracifyTracifyCsId,
          staging: tracifyConfig.tracifyTracifyIsStaging === 'true',
        }
      }
    }

    await this.applyCodesToPage(!!productId)
  }

  @override
  hydrate(key, data) {
    switch (key) {
      case 'codes':
        this.codes = data
        break
      case 'tracifyConfig':
        this.tracifyConfig = data
        break
    }
  }
}
