import React, { Component } from 'react'
import { PopoverBody, Popover } from 'reactstrap'
import classNames from 'classnames'
import Popper from '@popperjs/core'
import PageVisibility from 'react-page-visibility'

import { POSITIONS, SIZES } from '@elo-kit/constants/general.constants'

import { EloInfoIcon } from '@elo-ui/components/icons/regular'

import './tooltip-more.scss'

/**
 * Tooltip for more information
 */
interface Props {
  id?: number | string
  placement?: Popper.Placement
  onOpen?: () => void
  onClose?: () => void
  size?: string
  smallHeight?: boolean
  extraSmallHeight?: boolean
  popoverClassName?: string
  iconClassName?: string
  customIcon?: any
  children?: any
  closeTootip?: boolean
}
interface State {
  tooltipOpened: boolean
  onBody: string
}
const defaultProps = {
  placement: POSITIONS.top,
  size: SIZES.medium,
  smallHeight: false,
  extraSmallHeight: false,
  iconClassName: 'tooltip-more__icon popover-info-icon',
}
export class EloTooltipMore extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      tooltipOpened: false,
      onBody: '',
    }
  }

  static displayName = 'EloTooltipMore'
  static defaultProps = defaultProps

  componentDidMount() {
    window.addEventListener('blur', this.hideTooltip)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.closeTootip !== this.props.closeTootip && this.props.closeTootip) {
      this.hideTooltip()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('blur', this.hideTooltip)
  }

  hideTooltip = () => {
    this.setState({ tooltipOpened: false })
  }

  toggleTooltip = () => {
    const { onOpen, onClose } = this.props
    const { tooltipOpened } = this.state
    const newState = !tooltipOpened

    this.setState(() => ({
      tooltipOpened: newState,
    }))

    if (newState) {
      if (onOpen) {
        onOpen()
      }
    } else if (onClose) {
      onClose()
    }
  }

  handlePageVisibilityChange = (isVisible: boolean) => {
    if (!isVisible) {
      this.hideTooltip()
    }
  }

  render() {
    const {
      id,
      placement,
      size,
      children,
      customIcon,
      iconClassName,
      smallHeight,
      extraSmallHeight,
      popoverClassName,
    } = this.props

    const { tooltipOpened } = this.state

    const onIconMouseLeave = () =>
      setTimeout(() => {
        if (this.state.onBody !== tooltipId) {
          this.toggleTooltip()
        }
      }, 100)

    const tooltipId = `EloToolTip${id}`
    const iconProps = {
      onMouseEnter: this.toggleTooltip,
      onMouseLeave: onIconMouseLeave,
      id: tooltipId,
      className: classNames('tooltip-more__info-icon', iconClassName),
    }

    const popoverClasses = classNames(
      `tooltip-more tooltip-more--${size}`,
      {
        'tooltip-more--small-height': smallHeight,
        'tooltip-more--extra-small-height': extraSmallHeight,
      },
      popoverClassName
    )

    const onBodyMouseLeave = () => {
      this.toggleTooltip()
      this.setState({ onBody: '' })
    }

    const onBodyMouseEnter = () => {
      this.setState({ onBody: tooltipId })
    }

    return (
      <>
        <PageVisibility onChange={this.handlePageVisibilityChange}>
          {customIcon ? (
            customIcon(iconProps)
          ) : (
            <div {...iconProps}>
              <EloInfoIcon />
            </div>
          )}
        </PageVisibility>
        <Popover
          id={tooltipId}
          placement={placement}
          isOpen={tooltipOpened}
          innerClassName={popoverClasses}
          target={tooltipId}
          className={popoverClasses}
        >
          <PopoverBody onMouseEnter={onBodyMouseEnter} onMouseLeave={onBodyMouseLeave}>
            {children}
          </PopoverBody>
        </Popover>
      </>
    )
  }
}

EloTooltipMore.displayName = 'EloTooltipMore'
