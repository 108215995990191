import { BROWSERS, MOBILE_OPERATING_SYSTEMS } from 'constants/general.constants'
import { isWindowEnv } from 'utils/env.utils'
// navigator is a global browser object

const userAgent = isWindowEnv() ? navigator.userAgent : ''

const [includesChrome, includesEdge, includesFirefox, includesSafari] = BROWSERS.map(
  (browser) => (userAgent) => userAgent.includes(browser)
)

export const isEdge = includesEdge(userAgent)
export const isFirefox = includesFirefox(userAgent)
export const isInternetExplorer = userAgent.includes('MSIE') || userAgent.includes('rv:11.0')
export const isPDFBlockAllowed =
  isWindowEnv() && !isInternetExplorer && !document.getElementsByClassName('translated-ltr')[0]

// it is important to check if userAgent includes 'Chrome', because userAgent of Chrome at macOS includes 'Safari',
// so we need to exclude this case by checking 'Chrome' presence
export const isSafari = !includesChrome(userAgent) && includesSafari(userAgent)

export const getMobileOperatingSystem = () => {
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return MOBILE_OPERATING_SYSTEMS.windowsPhone
  }

  if (/android/i.test(userAgent)) {
    return MOBILE_OPERATING_SYSTEMS.android
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return MOBILE_OPERATING_SYSTEMS.iOS
  }

  return MOBILE_OPERATING_SYSTEMS.unknown
}

export const isWindows = userAgent.includes('Windows')
