import {
  activeExperimentNames,
  DynamicConfigName,
  ExperimentName,
  ExperimentUser,
  GateName,
} from 'shop/types/experiments'
import { useDynamicConfig, useExperiment, useFeatureGate } from '@statsig/react-bindings'
import { StatsigClient } from '@statsig/js-client'
import StatsigServerClient from 'statsig-node'

type Experiment = ReturnType<StatsigClient['getExperiment']>

export class ExperimentsStore {
  storeName = 'ExperimentsStore'
  private client: StatsigClient
  private isActive = false

  setClient(client: StatsigClient) {
    this.client = client
    this.isActive = true
  }

  useExperiment(name: ExperimentName) {
    return useExperiment(name)
  }

  useFeatureGate(name: GateName) {
    return useFeatureGate(name)
  }

  useDynamicConfig(name: DynamicConfigName) {
    return useDynamicConfig(name)
  }

  logEvent(eventName: string, value: string, metadata?: Record<string, string>) {
    if (!this.isActive) {
      return
    }
    this.client.logEvent(eventName, value, metadata)
  }

  logEventOnServer = (user: ExperimentUser, eventName: string, value: string, metadata?: Record<string, string>) => {
    if (!this.isActive) {
      return
    }

    StatsigServerClient.logEvent(user, eventName, value, metadata)
  }

  getExperiment = (name: ExperimentName) => {
    if (!this.isActive) {
      return {} as Experiment
    }
    return this.client.getExperiment(name)
  }

  get allActiveExperiments() {
    return activeExperimentNames.reduce((acc, name) => {
      const experiment = this.getExperiment(name)

      if (experiment.__evaluation?.is_user_in_experiment) {
        acc[name] = {
          name: experiment.name,
          value: experiment.value,
        }
      }

      return acc
    }, {})
  }
}
