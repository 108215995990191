import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { AnyCallback } from 'types/helpers'

import './_elo-button.scss'

type buttonTypes = 'button' | 'submit' | 'reset'
interface FloatingProps {
  id?: string | number
  onClick?: AnyCallback
  className?: string
  disabled?: boolean
  type?: buttonTypes
  children?: React.ReactNode
  to?: string
}
const Floating = (props: FloatingProps) => {
  const { children, className, disabled, id, onClick, type = 'button' } = props

  const buttonClasses = classNames('elo-btn elo-btn--floating', className)

  return (
    <button
      className={buttonClasses}
      type={type}
      {...{
        disabled,
        id: String(id),
        onClick,
      }}
    >
      {children}
    </button>
  )
}

interface DefaultProp {
  id?: string | number
  onClick?: AnyCallback
  href?: string
  to?: string
  className?: string
  copy?: boolean
  outlined?: boolean
  grey?: boolean
  orange?: boolean
  overlayButton?: boolean
  white?: boolean
  green?: boolean
  transparent?: boolean
  disabled?: boolean
  download?: boolean
  type?: buttonTypes
  downloadOnClick?: boolean
  long?: boolean
  rectangle?: boolean
  share?: boolean
  children?: React.ReactNode
  pink?: boolean
  style?: React.CSSProperties
  target?: string
  LinkComponent?: any
  onMouseEnter?: AnyCallback
  onMouseLeave?: AnyCallback
  dataTestId?: string
}
const Default = (props: DefaultProp) => {
  const {
    children,
    className,
    copy,
    disabled,
    download,
    downloadOnClick,
    green,
    grey,
    href,
    id,
    long,
    onClick,
    orange,
    outlined,
    overlayButton,
    pink,
    rectangle,
    style,
    share,
    target,
    to,
    type,
    white,
    dataTestId,
    LinkComponent,
    onMouseEnter,
    onMouseLeave,
  } = props

  const buttonClasses = classNames(
    'elo-btn',
    {
      blue: !orange && !green && !outlined && !download && !pink && !copy && !grey,
      grey,
      orange,
      green,
      pink,
      'download outline-blue': download,
      'copy outline-blue': copy,
      'outline-blue': outlined && !orange && !grey && !white && !pink && !green,
      'outline-white': outlined && white,
      'outline-grey': outlined && grey,
      'outline-pink': outlined && pink,
      'outline-green--disabled': outlined && green,
      'elo-btn--disabled': disabled,
      overlay: overlayButton,
      long,
      rectangle,
      share,
    },
    className
  )

  return (
    <>
      {(onClick || type === 'submit') && (
        <button
          className={buttonClasses}
          {...{
            disabled,
            id: String(id),
            onClick,
            style,
            type,
            onMouseEnter,
            onMouseLeave,
            'data-testid': dataTestId ?? type,
            'data-test-id': dataTestId ?? type,
          }}
        >
          {copy && <i className='far fa-copy' />}
          {download && <i className='fas fa-download' />}
          {children}
        </button>
      )}
      {href && (
        <a
          className={buttonClasses}
          download={downloadOnClick}
          {...{
            href,
            id: String(id),
            style,
            target,
          }}
        >
          {copy && <i className='far fa-copy' />}
          {download && <i className='fas fa-download' />}
          {children}
        </a>
      )}
      {to && (
        <LinkComponent
          {...{
            id: String(id),
            style: {
              ...style,
              ...(disabled && { pointerEvents: 'none' }),
            },
            target,
            to,
          }}
        >
          <button
            className={buttonClasses}
            {...{
              disabled,
            }}
          >
            {children}
          </button>
        </LinkComponent>
      )}
    </>
  )
}

/**
 * EloButton - button component
 */
type EloButtonProps = FloatingProps & DefaultProp
export const EloButton = (props: EloButtonProps) => <Default {...props} />

const defaultProps = {
  outlined: false,
  grey: false,
  orange: false,
  overlayButton: false,
  white: false,
  disabled: false,
  download: false,
  copy: false,
  downloadOnClick: false,
  type: 'button',
  LinkComponent: Link,
}

EloButton.displayName = 'EloButton'
EloButton.Floating = Floating
EloButton.defaultProps = defaultProps
