import { pushToDataLayer } from 'utils/GTMDataLayer.utils'
import { stringReplaceAll } from 'utils/string.utils'

import { TRACKING_CODE_TYPES, TRACKING_EVENT_FORMS } from 'constants/trackingCodes.constants'

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    // Create script element and set attributes
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true

    script.onload = () => {
      resolve(script)
    }

    script.onerror = () => {
      reject(script)
    }

    script.src = src
    document.head.appendChild(script)
  })

export const generateGA3Tracker = (code) => stringReplaceAll((code || '').replace('UA-', 'UA'), '-', '')

export const initializeGA = async (code, form) => {
  // https://support.google.com/analytics/answer/7538414?authuser=1
  if (form === TRACKING_CODE_TYPES.googleAnalytics) {
    // Old google analitics.js implementation
    ;(function (i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r
      ;(i[r] =
        i[r] ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          ;(i[r].q = i[r].q || []).push(arguments)
        }),
        // @ts-ignore:next-line
        (i[r].l = 1 * new Date())
      ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
      a.async = 1
      a.src = g
      m.parentNode.insertBefore(a, m)
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga')
    // @ts-ignore:next-line
    window.ga('create', code, 'auto', { name: generateGA3Tracker(code) })
  }

  if (form === TRACKING_CODE_TYPES.googleAnalytics4) {
    // New implementation via gtag.js

    await loadScript(`//www.googletagmanager.com/gtag/js?id=${code}`)

    window.dataLayer = window.dataLayer || []

    // @ts-ignore:next-line
    window.gtag = function gtag() {
      // eslint-disable-next-line prefer-rest-params
      pushToDataLayer(arguments)
    }

    // @ts-ignore:next-line
    window.gtag('js', new Date())
    // @ts-ignore:next-line
    window.gtag('config', code, { anonymize_ip: true, send_page_view: false })
  }
}
export const initializeFBQ = (code) =>
  new Promise((resolve, reject) => {
    function init() {
      let func = null
      if (window.fbq) return
      func = function () {
        func.callMethod
          ? // eslint-disable-next-line
            func.callMethod.apply(func, arguments)
          : // eslint-disable-next-line
            func.queue.push(arguments)
      }
      if (!window._fbq) window._fbq = func
      func.push = func
      func.loaded = !0
      func.version = '2.0'
      func.queue = []
      window.fbq = func
      const tag = document.createElement('script')
      tag.async = !0
      tag.onload = () => {
        if (Array.isArray(code)) {
          code.map((id) => {
            window.fbq('init', id)
          })
        } else {
          window.fbq('init', code)
        }

        window.fbq('track', 'PageView')
        resolve(tag)
      }
      tag.onerror = () => {
        delete window.fbq
        reject(tag)
      }
      tag.src = 'https://connect.facebook.net/en_US/fbevents.js'
      const script = document.getElementsByTagName('script')[0]
      script.parentNode.insertBefore(tag, script)
    }

    // @ts-ignore
    init(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
  })

export const codeWithVariables = (event, fire, trackingCode) => {
  const { code } = event || {}
  const { name, pms } = fire || {}
  const { code: codeId, form } = trackingCode || {}
  const {
    currency = '',
    funnelId = '',
    funnelName = '',
    funnelPageId = '',
    funnelPageName = '',
    orderForm = '',
    orderId = '',
    periodType = '',
    productId = '',
    productIds = [],
    productName = '',
    productNames = [],
    productPrice = '',
    revenue = '',
    transactionId = '',
    upsellId = '',
    upsellIds = [],
    upsellName = '',
    upsellNames = [],
  } = pms || {}
  let newCode = code

  if (form === TRACKING_CODE_TYPES.googleAnalytics) {
    newCode = newCode.replace('ga', 'window.ga').replace('send', `${generateGA3Tracker(codeId)}.send`)
  }

  if (form === TRACKING_CODE_TYPES.googleAnalytics4) {
    newCode = code.replace(
      `});`,
      `
        'send_to': '${codeId}',
        });
      `
    )
  }

  switch (name) {
    case TRACKING_EVENT_FORMS.shop:
    case TRACKING_EVENT_FORMS.shopSubPage:
      return newCode
    case TRACKING_EVENT_FORMS.product:
      return newCode
        .replace(/%{product_id}/g, `'${productId}'`)
        .replace(/%{product_name}/g, `'${productName.replaceAll("'", '')}'`)
        .replace(/%{product_price}/g, `'${productPrice}'`)
        .replace(/%{currency}/g, `'${currency}'`)
    case TRACKING_EVENT_FORMS.payment:
      return newCode
        .replace(/%{product_id}/g, `'${productId}'`)
        .replace(/%{product_name}/g, `'${productName.replaceAll("'", '')}'`)
        .replace(/%{upsell_ids}/g, `[${upsellIds.map((i) => `'${i}'`)}]`)
        .replace(/%{upsell_names}/g, `[${upsellNames.map((i) => `'${i.replaceAll("'", '')}'`)}]`)
        .replace(/%{currency}/g, `'${currency}'`)
    case TRACKING_EVENT_FORMS.funnel:
      return newCode
        .replace(/%{funnel_id}/g, `'${funnelId}'`)
        .replace(/%{funnel_name}/g, `'${funnelName?.replaceAll("'", '')}'`)
        .replace(/%{funnel_page_id}/g, `'${funnelPageId}'`)
        .replace(/%{funnel_page_name}/g, `'${funnelPageName?.replaceAll("'", '')}'`)
        .replace(/%{product_ids}/g, `[${productIds.map((i) => `'${i}'`)}]`)
        .replace(/%{product_names}/g, `[${productNames.map((i) => `'${i.replaceAll("'", '')}'`)}]`)
    case 'success_page':
      return newCode
        .replace(/%{transaction_id}/g, `'${transactionId}'`)
        .replace(/%{order_id}/g, `'${orderId}'`)
        .replace(/%{payment_session_id}/g, `'${orderId}'`) // outdated - remove in 3 months, change data in DB
        .replace(/%{period_type}/g, `'${periodType}'`)
        .replace(/%{order_form}/g, `'${orderForm}'`)
        .replace(/%{payment_session_form}/g, `'${orderForm}'`) // outdated - remove in 3 months, change data in DB
        .replace(/%{revenue}/g, revenue || 0)
        .replace(/%{product_ids}/g, `[${productIds.map((i) => `'${i}'`)}]`)
        .replace(/%{product_names}/g, `[${productNames.map((i) => `'${i.replaceAll("'", '')}'`)}]`)
        .replace(/%{upsell_id}/g, `'${upsellId || ''}'`)
        .replace(/%{upsell_name}/g, `'${(upsellName || '').replaceAll("'", '')}'`)
        .replace(/%{funnel_id}/g, `'${funnelId || ''}'`)
        .replace(/%{funnel_name}/g, `'${(funnelName || '').replaceAll("'", '')}'`)
        .replace(/%{funnel_page_id}/g, `'${funnelPageId || ''}'`)
        .replace(/%{funnel_page_name}/g, `'${(funnelPageName || '').replaceAll("'", '')}'`)
        .replace(/%{currency}/g, `'${currency}'`)
    default:
      return false
  }
}
