import { useEffect, useState } from 'react'
import { setCookie } from 'cookies-next'

import { isEqual } from 'utils/lodash.utils'
import { cookiesKey } from 'libs/common/cookies'

import {
  COOKIES_CONSENTS,
  COOKIES_CONSENTS_LOCATION,
  COOKIES_CONSENTS_TYPE,
} from '@elo-kit/constants/cookiesConsents.constants'

import { isWindowEnv } from 'utils/env.utils'
import { getElopageConfig } from 'utils/elopageConfig.utils'
import { in10000Days } from '@elo-kit/utils/dateTime.utils'
import { setGoogleConsentMode } from 'utils/trackingCodes.utils'

export const CONSENT_WIDGET_LOCAL_STORAGE_KEY = 'CONSENT_WIDGET_LOCAL_STORAGE_KEY'

const inIframe = (() => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
})()

export default (props) => {
  const {
    preview,
    username = '',
    location = COOKIES_CONSENTS_LOCATION.shop,
    onSubmit = () => {},
    form,
    consentsList,
    categoriesList,
    getCookies,
    removeCookies,
    getAllCookies,
    addItemToLocalStorage,
    getItemFromLocalStorage,
  } = props

  const isShopLocation = location === COOKIES_CONSENTS_LOCATION.shop
  const cookieKey = isShopLocation ? `${COOKIES_CONSENTS.shop}_${username.toLowerCase()}` : COOKIES_CONSENTS.app
  const cookiePath = '/'
  const initCookies = getCookies(cookieKey)
  const initCookieCategories = getCookies(`${cookieKey}_categories`)

  const allCookiesAccepted = (cookies) => {
    const cookiesList = (cookies || getCookies(cookieKey) || '').split(',')
    return isEqual(
      cookiesList,
      consentsList.map(({ id }) => id)
    )
  }

  const [accepted, setAccepted] = useState(!!initCookies)
  const [showModal, setShowModal] = useState(!initCookies && form === COOKIES_CONSENTS_TYPE.rich)
  const [allSelected, setAllSelected] = useState(allCookiesAccepted(initCookies))
  const [selected, setSelected] = useState({})
  const [selectedCategories, setSelectedCategories] = useState(JSON.parse(initCookieCategories || '{}'))
  const [consentsByCategories, setConsentsByCategories] = useState({})
  const [dataInited, setDataInited] = useState(false)

  const getCookieValue = () => {
    const consentsIds = Object.keys(selected)
    let value = ''
    if (allSelected) {
      value = consentsList.map(({ id }) => id).join(',')
    } else {
      consentsIds.forEach((id) => {
        if (selected[id]) {
          value = `${value ? `${value},` : ''}${id}`
        }
      })
    }

    return value
  }

  const getSelectedCookies = () => {
    const cookies = getCookies(cookieKey)

    if (cookies) {
      return allCookiesAccepted(cookies) ? consentsList.map((i) => i && String(i.id)) : cookies.split(',')
    }

    return consentsList.filter((i) => i && i.required).map((i) => String(i.id))
  }

  const setSelectedFromCookies = () => {
    const selectedCookiesList = getSelectedCookies()
    const checkedItems = {}

    consentsList.forEach((consent) => {
      checkedItems[consent.id] = selectedCookiesList.includes(String(consent.id))
    })

    setGoogleConsentMode(selectedCategories, false)
    setSelected(checkedItems)
  }

  const removeNotAllowedCookies = () => {
    let keysToRemove = []
    const cookiesToRemove = []
    const selectedCookies = getSelectedCookies()
    consentsList
      .filter((consent) => !selectedCookies.find((id) => String(id) === String(consent.id)))
      .forEach((consent) => {
        if (consent.regex) {
          keysToRemove = [...keysToRemove, ...consent.regex.split('|')]
        }
      })

    const cookiesKeysList = Object.keys(getAllCookies() || {})
    cookiesKeysList.forEach((cookieKeyItem) => {
      const isKeyPartlyMatched = () =>
        keysToRemove.some((keyToRemove) => {
          const regexp = new RegExp(`${keyToRemove}|^${keyToRemove}[^A-Za-z]+`, 'i')
          return regexp.test(cookieKeyItem)
        })
      if (keysToRemove.includes(cookieKey) || isKeyPartlyMatched()) {
        cookiesToRemove.push(cookieKeyItem)
      }
    })

    cookiesToRemove.forEach((cookieKeyItem) => removeCookies(cookieKeyItem))
    keysToRemove.forEach((item) => removeCookies(item))
  }

  const acceptCookies = () => {
    const hostname = isWindowEnv() ? window.location.hostname : getElopageConfig('hostname')
    const newCookiesValue = getCookieValue()
    const iframeOptions =
      inIframe && location === COOKIES_CONSENTS_LOCATION.shop ? { sameSite: 'none', secure: true } : {}

    setCookie(cookiesKey(cookieKey), newCookiesValue, {
      expires: in10000Days(),
      path: cookiePath,
      domain: hostname,
      ...iframeOptions,
    })

    const consentLocalStorageKey = `${CONSENT_WIDGET_LOCAL_STORAGE_KEY}_${username}`.toUpperCase()
    const consentsIDsList = consentsList && consentsList.map((consentItem) => String(consentItem.id))

    addItemToLocalStorage(consentLocalStorageKey, consentsIDsList)

    setAccepted(true)
    setShowModal(false)
    setSelectedFromCookies()
    removeNotAllowedCookies()
    onSubmit()
  }

  const handleCategoriesCheckboxes = () => {
    const newCategoriesCheckValues = {}
    const isAllSelected = isEqual(
      getCookieValue(),
      consentsList.map(({ id }) => id)
    )

    categoriesList.forEach((category) => {
      const isEnabled = (item) => selected[item.id]
      if (selectedCategories[category.id] && isAllSelected) setAllSelected(false)
      if (isAllSelected) {
        newCategoriesCheckValues[category.id] = true
      } else if (consentsByCategories[category.id] && consentsByCategories[category.id].length > 0) {
        newCategoriesCheckValues[category.id] = consentsByCategories[category.id].some(isEnabled)
      } else {
        newCategoriesCheckValues[category.id] = selectedCategories[category.id]
      }
    })

    setSelectedCategories(newCategoriesCheckValues)
    const hostname = isWindowEnv() ? window.location.hostname : getElopageConfig('hostname')
    const iframeOptions =
      inIframe && location === COOKIES_CONSENTS_LOCATION.shop ? { sameSite: 'none', secure: true } : {}

    setCookie(cookiesKey(`${cookieKey}_categories`), newCategoriesCheckValues, {
      expires: in10000Days(),
      path: cookiePath,
      domain: hostname,
      ...iframeOptions,
    })

    setGoogleConsentMode(newCategoriesCheckValues)
  }

  const initCookiesFetching = () => {
    const { consentsList: consentsListCopy, categoriesList: categoriesListCopy } = props

    if (consentsListCopy && categoriesListCopy) {
      const formattedConsentsList = {}
      categoriesListCopy.forEach((category) => {
        const cookiesInCategory =
          consentsListCopy.filter((consent) => consent.cookiesConsentCategoryId === category.id) || []

        if (cookiesInCategory) {
          formattedConsentsList[category.id] = cookiesInCategory || []
        }
      })

      setConsentsByCategories(formattedConsentsList)
    }

    /* if user accepted individual cookies/categories before - show banner/popup like for the first visit */
    const consentLocalStorageKey = `${CONSENT_WIDGET_LOCAL_STORAGE_KEY}_${username}`.toUpperCase()
    const previousConsentList = getItemFromLocalStorage(consentLocalStorageKey) || []
    const consentsIDsList = consentsListCopy && consentsListCopy.map((consentItem) => String(consentItem.id))
    const isAllSelected = isEqual(
      getCookieValue(),
      consentsList.map(({ id }) => id)
    )

    if (
      previousConsentList &&
      previousConsentList.length &&
      !isEqual(consentsIDsList, previousConsentList) &&
      !isAllSelected
    ) {
      setAccepted(false)
    }

    setDataInited(true)
  }

  const handleCheck = (id) => {
    setSelected({
      ...selected,
      [id]: !selected[id],
    })
    if (selected[id] && allSelected) setAllSelected(false)
  }

  const handleCategoryCheck = (id) => {
    const newCategoryCheckValue = !selectedCategories[id]

    setSelectedCategories({
      ...selectedCategories,
      [id]: newCategoryCheckValue,
    })

    const newCategoryConsentsCheckValues = {}
    const subItesm = consentsByCategories[id] || []

    subItesm.forEach((consent) => {
      newCategoryConsentsCheckValues[consent.id] = consent.required || newCategoryCheckValue
    })

    setSelected({
      ...selected,
      ...newCategoryConsentsCheckValues,
    })

    const isSomeConsentUnchecked = Object.values(newCategoryConsentsCheckValues).some((value) => !value)
    if (isSomeConsentUnchecked) {
      setAllSelected(false)
    }
  }

  const toggleModal = () => setShowModal(!showModal)

  const handleSelectAll = () => {
    setAllSelected(true)
    setShowModal(false)
  }

  const handleDeny = () => {
    const requiredConsents = consentsList
      .filter((consent) => consent.required)
      .reduce((acc, cur) => ({ ...acc, [cur.id]: true }), {})

    setSelected(requiredConsents)

    const consentLocalStorageKey = `${CONSENT_WIDGET_LOCAL_STORAGE_KEY}_${username}`.toUpperCase()
    const consentsIDsList = consentsList && consentsList.map((consentItem) => String(consentItem.id))

    addItemToLocalStorage(consentLocalStorageKey, consentsIDsList)
    const hostname = isWindowEnv() ? window.location.hostname : getElopageConfig('hostname')
    const iframeOptions =
      inIframe && location === COOKIES_CONSENTS_LOCATION.shop ? { sameSite: 'none', secure: true } : {}

    const newCookiesValue = Object.keys(requiredConsents).join(',')
    setCookie(cookiesKey(cookieKey), newCookiesValue, {
      expires: in10000Days(),
      path: cookiePath,
      domain: hostname,
      ...iframeOptions,
    })
    setAccepted(true)
    setShowModal(false)
    removeNotAllowedCookies()
  }

  useEffect(() => {
    initCookiesFetching()
    window?.cookieStore?.addEventListener('change', removeNotAllowedCookies)
    setGoogleConsentMode({ 1: false, 2: false, 3: false, 4: false, 5: false }, true)

    return () => {
      window?.cookieStore?.removeEventListener('change', removeNotAllowedCookies)
    }
  }, [])

  useEffect(() => {
    if (!preview && allSelected) {
      acceptCookies()
    }
  }, [allSelected])

  useEffect(() => {
    if (!preview && accepted) {
      setSelectedFromCookies()
    }
  }, [accepted])

  useEffect(() => {
    if (!preview) {
      setSelectedFromCookies()
    }
  }, [consentsList])

  useEffect(() => {
    if (!preview) {
      handleCategoriesCheckboxes()
    }
  }, [selected, consentsByCategories])

  return [
    {
      showModal,
      accepted,
      consents: consentsList,
      selected,
      allSelected,
      consentsByCategories,
      categories: categoriesList,
      selectedCategories,
      dataInited,
    },
    {
      acceptCookies,
      toggleModal,
      handleCheck,
      setAccepted,
      setShowModal,
      handleCategoryCheck,
      handleSelectAll,
      handleDeny,
    },
  ]
}
